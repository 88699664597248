<div style="max-height: 1200px; overflow-y: auto">
	<mat-card>
		<mat-card-header>
			<mat-card-title>{{ 'FLIGHTS.COPY.copy_flight' | translate | capitalize }} </mat-card-title>
			<mat-card-subtitle>{{ 'FLIGHTS.COPY.select_multiple_dates' | translate | capitalize }}</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<div fxLayout="column" fxLayoutGap="10px">
				<div fxLayout="row" fxLayoutGap="20px">
					<div fxLayout="column">
						<div>{{ 'FLIGHTS.period' | translate | capitalize }}</div>
						<div>{{ 'FLIGHTS.balloon' | translate | capitalize }}</div>
						<div>{{ 'FLIGHTS.location' | translate | capitalize }}</div>
						<div>
							{{ 'FLIGHTS.pilot' | translate | capitalize }}
						</div>
						<div>
							{{ 'FLIGHTS.ground_crew' | translate | capitalize }}
						</div>
						<div>
							{{ 'FLIGHTS.tags' | translate | capitalize }}
						</div>
					</div>
					<div fxLayout="column">
						<div>{{ 'FLIGHTS.' + flight.period | translate | capitalize }}</div>
						<div>{{ flight.balloon?.name }}</div>
						<div>{{ flight.location?.name }}</div>
						<div>
							{{ flight.pilot ? flight.pilot?.crew?.name : ('FLIGHTS.no_pilot_in_command_selected' | translate | capitalize) }}
						</div>
						<div>
							<span *ngFor="let groundCrew of flight.groundCrews"> {{ groundCrew.crew.name }}, </span>
							<span *ngIf="flight.groundCrews?.length === 0">
								{{ 'FLIGHTS.no_crew_selected' | translate | capitalize }}
							</span>
						</div>
						<div>
							<span *ngFor="let tag of flight.tags">
								<mat-icon [ngStyle]="tag.backgroundColor ? { color: tag.backgroundColor } : {}">bookmark</mat-icon>{{ tag.tag }},
							</span>
							<span *ngIf="flight.tags?.length === 0">
								{{ 'FLIGHTS.COPY.no_tags' | translate | capitalize }}
							</span>
						</div>
					</div>
				</div>

				<mat-calendar class="mat-elevation-z8" #calendar [dateClass]="dateClass()" (selectedChange)="onDateChange($event)"> </mat-calendar>
				<div *ngIf="selectedDates.length > 0">
					<h3>{{ 'FLIGHTS.COPY.selected_dates' | translate | capitalize }}</h3>
					<div *ngFor="let date of selectedDates">{{ date | formatDate: 'ddd DD-MM-YYYY' }}</div>
				</div>
			</div>
		</mat-card-content>
		<mat-card-actions>
			<button mat-raised-button [disabled]="selectedDates.length === 0" color="primary" (click)="copyFlight()">
				<div *ngIf="isSaving">
					<mat-spinner diameter="35.5" color="warn"></mat-spinner>
				</div>
				<span *ngIf="!isSaving">{{ 'FLIGHTS.COPY.copy' | translate | uppercase }}</span>
			</button>
			<button mat-raised-button (click)="cancel()">{{ 'APPLICATION.cancel' | translate | capitalize }}</button>
		</mat-card-actions>
	</mat-card>
</div>
