import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '..';
import {   EmailTemplateInput, GetAllEmailTemplatesDocument, GetUserDocument, SaveEmailTemplateDocument, SaveLanguageSettingDocument } from '../../graphql/generated/gen-types';
import { TranslateCustomService } from '../../utility/translate-custom.service';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	currentUser;
	constructor(
		private apollo: Apollo,
		private authenticationService: AuthenticationService,
		public http: HttpClient,
		private translateCustom: TranslateCustomService
	) {
		// this.url = pathService.getRestApiPath() + environment.settingsUrl;
		// this.PDFTemplatesUrl = pathService.getRestApiPath() + environment.PDFTemplatesUrl;
		this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}

  url: string;
  PDFTemplatesUrl: string;

  updateLanguage(   systemLanguage: String): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: SaveLanguageSettingDocument,
        variables: {
          systemLanguage,
        },
		refetchQueries: [
			{
				query: GetUserDocument,
			},
		],
      })
      .pipe(map((result: any) => result.data));
  }

  public updateSetting(emailTemplateId: string, emailTemplateInput: EmailTemplateInput): Observable<any> {
    return this.apollo
			.mutate<any>({
				mutation: SaveEmailTemplateDocument,
				variables: {
					emailTemplateId,
					emailTemplateInput,
				},
				refetchQueries: [ { query: GetAllEmailTemplatesDocument } ],
			})
			.pipe(map((result: any) => result.data));
  }

  // getPDFTemplates(): Observable<any> {
  //   return this.http.get(this.PDFTemplatesUrl).pipe(map((res: any) => {
  //     return res.response
  //   }), catchError(error => observableThrowError(error)));
  // }

}
