import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SatPopover } from '@ncstate/sat-popover';
import { Booking, Tag } from 'src/app/core/graphql/generated/gen-types';

@Component({
	selector: 'app-booking-comments',
	templateUrl: './booking-comments.component.html',
	styleUrls: ['./booking-comments.component.scss'],
})
export class BookingCommentsComponent implements OnInit {
	@Input() booking: Booking;

	selectedTags: Tag[];

	formBookingComments: FormGroup;

	constructor(private formBuilder: FormBuilder, public popover: SatPopover) {}

	ngOnInit() {
		this.formBookingComments = this.formBuilder.group({
			id: this.booking.id,
			comments: this.booking.comments,
			save: false,
		});
	}

	cancel(form) {
		form.save = false;
		this.popover.close({ save: form.save, comments: form.comments, tags: this.selectedTags });
	}

	save(form) {
		form.save = true;
		this.popover.close({ save: form.save, comments: form.comments, tags: this.selectedTags });
	}

	setTags(tags: Tag[]): void {
		if (!tags) return;
		this.selectedTags = tags;
	}
}
