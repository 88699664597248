<!-- Select Template -->
<div *ngIf="data?.templates.length > 1" style="margin-bottom: 20px">
	<h2 mat-dialog-title>{{ 'APPLICATION.select_template' | translate | capitalize }}</h2>
	<mat-button-toggle-group name="template" aria-label="Template" [value]="selectedTemplate">
		<mat-button-toggle
			*ngFor="let template of data.templates; let i = index"
			(click)="selectTemplate(i)"
      [value]="template"
			>{{ template }}</mat-button-toggle
		>
	</mat-button-toggle-group>
</div>

<div *ngIf="!readOnly" style="margin-bottom: 20px">
	<h2 mat-dialog-title>
		{{ 'APPLICATION.send_email_to' | translate | capitalize }}
	</h2>
	<mat-button-toggle-group name="emails" aria-label="Emails" [value]="mailMessage">
		<mat-button-toggle
			*ngFor="let email of emails; let i = index"
			(click)="selectMail(i)"
			[disabled]="!selectedTemplate"
      [value]="email"
		>
				{{ email.contactName }} ({{ email.language }})
			</mat-button-toggle
		>
	</mat-button-toggle-group>
</div>

<div *ngIf="readOnly" style="margin-bottom: 20px">
	<mat-card-subtitle *ngIf="mailMessage.result">
		{{ 'APPLICATION.' + mailMessage.result | translate | capitalize }}
		{{ mailMessage.resultAt | formatDate: 'dd DD-MMM-YYYY HH:mm:ss':false }}
	</mat-card-subtitle>
	<mat-card-subtitle *ngIf="!mailMessage.result">
		{{ 'APPLICATION.waiting' | translate | capitalize }}
	</mat-card-subtitle>
</div>

<mat-divider></mat-divider>

<div mat-dialog-content style="margin: 20px 0px;" *ngIf="emails?.length > 0 && selectedTemplate">
	<!-- MAIL Form -->
	<div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="10px">
		<div>
			<button mat-raised-button [disabled]="!fileUploadFinished() || !validFileSize() || !mailDetailForm.valid" (click)="send()" color="accent">
				<mat-icon>send</mat-icon>{{ 'FLIGHTS.send' | translate | capitalize }}
			</button>
		</div>
		<form [formGroup]="mailDetailForm">
			<mat-form-field style="width: 100%; min-width: 200px">
				<textarea
					[readonly]="readOnly"
					matTextareaAutosize
					matInput
					matAutosizeMinRows="1"
					placeholder="{{ 'SETTINGS.emailTo' | translate | capitalize }}"
					formControlName="to"
          required
				></textarea>
			</mat-form-field>
			<mat-form-field style="width: 100%; min-width: 200px">
				<textarea
					[readonly]="readOnly"
					matTextareaAutosize
					matInput
					matAutosizeMinRows="1"
					placeholder="{{ 'SETTINGS.emailSubject' | translate | capitalize }}"
					formControlName="subject"
          required
				></textarea>
			</mat-form-field>
		</form>
		<div *ngIf="!readOnly">
			<div *ngFor="let item of mailAttachments; let i = index">
				<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
					<mat-icon>attach_file</mat-icon>
					<div fxLayout="column">
						{{ item.filename }}
						<mat-progress-bar mode="determinate" [value]="item.progress"></mat-progress-bar>
						<mat-hint *ngIf="item.size">{{ item.size }} kB</mat-hint>
					</div>
					<button *ngIf="!readOnly" mat-icon-button (click)="removeAttachment(item)">
						<mat-icon [color]="item.valid ? 'primary' : 'warn'">clear</mat-icon>
					</button>
					<span *ngIf="!item.valid">{{ 'VALIDATIONS.invalid_file_you_can_only_upload_pdf_of_max_2MB' | translate }}</span>
				</div>
			</div>
			<div *ngIf="!validFileSize()">
				{{ 'VALIDATIONS.the_total_size_of_all_attachments_exceeds_10BM_please_remove_1_or_more_attachments' | translate | capitalize }}
			</div>
			<ngx-file-drop dropZoneClassName="dropzone" contentClassName="dropzone-content" (onFileDrop)="dropped($event)" [directory]="false">
				<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
					<div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center" style="margin: 10px">
						<mat-icon>save_alt</mat-icon>
						<span>{{ 'SETTINGS.drag_and_drop_file_here' | translate | capitalize }}</span>
						<button mat-raised-button color="accent" (click)="openFileSelector()">
							{{ 'SETTINGS.browse_for_file' | translate | capitalize }}
						</button>
					</div>
				</ng-template>
			</ngx-file-drop>
		</div>
		<div *ngIf="readOnly">
			<mat-card-subtitle *ngIf="mailMessage?.attachments?.length > 0">
				{{ 'SETTINGS.emailAttachments' | translate | capitalize }}
			</mat-card-subtitle>
			<div *ngFor="let item of mailMessage?.attachments; let i = index" style="margin-top: 10px">
				<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
					<mat-icon>attach_file</mat-icon>
					<div fxLayout="column">
						{{ item.filename }}
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="!readOnly" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="10px">
			<div style="width: 100%; min-width: 600px">
				<ckeditor [editor]="bodyEditor" [(ngModel)]="editorData" (change)="onChangeBody($event)" [config]="config"> </ckeditor>
			</div>
			<div>
				<button mat-raised-button [disabled]="!fileUploadFinished() || !validFileSize() || !mailDetailForm.valid" (click)="send()" color="accent">
					<mat-icon>send</mat-icon>{{ 'FLIGHTS.send' | translate | capitalize }}
				</button>
			</div>
		</div>
		<div *ngIf="readOnly">
			<mat-card-subtitle>
				{{ 'SETTINGS.emailBody' | translate | capitalize }}
			</mat-card-subtitle>
			<div style="width: 100%; min-width: 600px" [innerHTML]="editorData"></div>
		</div>
	</div>
</div>

<mat-divider></mat-divider>
<div mat-dialog-actions>
	<button mat-raised-button (click)="cancel()">{{ 'APPLICATION.cancel' | translate | capitalize }}</button>
</div>
