<div fxLayout="row" fxLayoutGap="20px">
	<mat-form-field fxFlex="380px" (click)="trigger.openPanel()">
		<mat-label>{{ 'TAGS.tag_name' | translate | capitalize }}</mat-label>
		<mat-chip-list #chipList>
			<mat-chip
				*ngFor="let tag of selectedTags"
				(removed)="removeTagFromSelected(tag)"
				[ngStyle]="tag.backgroundColor ? { 'background-color': tag.backgroundColor, color: tag.fontColor } : {}"
			>
				{{ tag.tag }}
				<button matChipRemove>
					<mat-icon [ngStyle]="{ color: tag.fontColor }">cancel</mat-icon>
				</button>
			</mat-chip>
		</mat-chip-list>
		<div fxLayout="row" fxLayoutAlign="start center">
			<input
				tabindex="-1"
				matInput
				style="width: 100%"
				placeholder="Select tag"
				autocomplete="off"
				#tagInput
				[formControl]="tagControl"
				[matAutocomplete]="auto"
				[matChipInputFor]="chipList"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				(matChipInputTokenEnd)="addFromInput($event)"
				#trigger="matAutocompleteTrigger"
			/>
			<mat-icon
				color="primary"
				style="cursor: pointer"
				matTooltip="{{ 'TAGS.new_tag' | translate | capitalize }}"
				(click)="openCreateTagDialog(); $event.stopPropagation()"
				>add_box</mat-icon
			>
		</div>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectFromAutoComplete($event)">
			<mat-option *ngFor="let tag of filteredTags | async" [value]="tag.tag">
				<mat-icon [ngStyle]="tag.backgroundColor ? { color: tag.backgroundColor } : {}">bookmark</mat-icon>{{ tag.tag }}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</div>
