<!-- // align center -->
<div class="dialog">
<img
	src="assets/images/skeyes.png"
	alt="Skeyes"
	height="60"
	style="display: block; margin-left: auto; margin-right: auto; margin-bottom: 20px"
/>
<mat-accordion multi="true">
	<mat-expansion-panel expanded="true" hideToggle="true">
		<mat-expansion-panel-header>
			<mat-panel-title
				><strong>{{ 'SKEYES.load' | translate | capitalize }}</strong></mat-panel-title
			>
			<mat-panel-description>
				<div >{{ 'SKEYES.disclaimer' | translate | capitalize }}</div>
			</mat-panel-description>
		</mat-expansion-panel-header>
		<div style="margin-top: 20px; width: 420px; font-size: small">{{ 'SKEYES.instructions' | translate | capitalize }}</div>
		<form [formGroup]="skeyesForm" novalidate autocomplete="off" style="margin-top: 20px">
			<div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
				<mat-form-field>
					<input matInput type="text" placeholder="{{ 'SKEYES.username' | translate | capitalize }}" formControlName="username" />
					<mat-error *ngIf="skeyesForm.value.username.errors?.required">
						{{ 'VALIDATIONS.required' | translate | capitalize }}
					</mat-error>
				</mat-form-field>
				<mat-form-field>
					<input matInput type="password" placeholder="{{ 'SKEYES.password' | translate | capitalize }}" formControlName="password" />
					<mat-error *ngIf="skeyesForm.value.password.errors?.required">
						{{ 'VALIDATIONS.required' | translate | capitalize }}
					</mat-error>
				</mat-form-field>
				<button mat-raised-button color="accent" [disabled]="!skeyesForm.valid" (click)="fetchSkeyesForecast()">{{ 'SKEYES.ballooning_bulletin' | translate | capitalize }}</button>
			</div>
		</form>
	</mat-expansion-panel>
	<mat-expansion-panel expanded="true" hideToggle="true" *ngIf="full || loading || error">
		<mat-expansion-panel-header>
			<mat-panel-title
				><strong>{{ 'SKEYES.ballooning_bulletin' | translate | capitalize }}</strong></mat-panel-title
			>
			<mat-panel-description *ngIf="full"
				>{{ 'SKEYES.loaded_by' | translate | capitalize }} {{ forecastUsername }} - {{ forecastDate }}</mat-panel-description
			>
		</mat-expansion-panel-header>
		<!-- loading -->
		<div *ngIf="loading">
			{{ 'SKEYES.loading_can_take_up_to_15sec' | translate | capitalize }}
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</div>
        <!-- wrong slot -->
		<div *ngIf="!loading && (flightSlot != forecastSlot)" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
			<mat-icon color="warn">warning</mat-icon>
			<span>{{ 'SKEYES.slot_warning' | translate | capitalize }}</span>
		</div>
        <!-- error -->
		<div *ngIf="!loading && error" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
			<mat-icon color="warn">error</mat-icon>
			<span>{{ 'SKEYES.error' | translate | capitalize }}</span>
		</div>
        <!-- all good -->
		<div *ngIf="!loading && full && !error" [innerHTML]="full"></div>
	</mat-expansion-panel>
</mat-accordion>
</div>
