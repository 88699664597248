import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import moment from 'moment';
import { FlightPeriodCountersPerPeriod, FlightPeriods } from 'src/app/core/graphql/generated/gen-types';
import { FlightService } from 'src/app/core/services/flight/flight.service';

@Component({
	selector: 'app-flight-filter',
	templateUrl: './flight-filter.component.html',
	styleUrls: ['./flight-filter.component.scss'],
})
export class FlightFilterComponent implements OnInit {
	@Output() filterChange = new EventEmitter<any>();
	@Input() sPeriod: { type: string; period: FlightPeriodCountersPerPeriod } = null;
	@Input() showHistogram: boolean;

	filter: string = 'all';
	filterRangeForm = new FormGroup({
		startDate: new FormControl(),
		endDate: new FormControl(),
	});
	selectedPeriod: { type: string; period: FlightPeriodCountersPerPeriod } = null;
	maxValue: number = 0;
	maxValues: { month: number; week: number; day: number } = { month: 0, week: 0, day: 0 };
	histogramPeriod: string = 'month';
	maxHeight: number = 60; // max height of rectangle
	flightPeriods: FlightPeriods;
	selectedFlightPeriod: FlightPeriodCountersPerPeriod[];

	expanded = false;

	constructor(private flightService: FlightService) {}

	ngOnInit(): void {
		this.flightService.flightPeriods().valueChanges.subscribe((result) => {
			this.flightPeriods = result.data.flightPeriods;
			this.selectedFlightPeriod = this.flightPeriods.month;
			// max of available, confirmed, added, invited of each column and row
			this.maxValue = Math.max(
				...this.selectedFlightPeriod.map((p) => p.EVE.flightsCount),
				...this.selectedFlightPeriod.map((p) => p.MOR.flightsCount)
				// ...this.selectedFlightPeriod.map((p) => p.freeSpots),
				// ...this.selectedFlightPeriod.map((p) => p.passengersCountConfirmed),
				// ...this.selectedFlightPeriod.map((p) => p.passengersCountAdded),
				// ...this.selectedFlightPeriod.map((p) => p.passengersCountInvited)
			);
			this.maxValues.month = Math.max(
				...this.flightPeriods.month.map((p) => p.EVE.flightsCount),
				...this.flightPeriods.month.map((p) => p.MOR.flightsCount)
			);
			this.maxValues.week = Math.max(
				...this.flightPeriods.week.map((p) => p.EVE.flightsCount),
				...this.flightPeriods.week.map((p) => p.MOR.flightsCount)
			);
			this.maxValues.day = Math.max(
				...this.flightPeriods.day.map((p) => p.EVE.flightsCount),
				...this.flightPeriods.day.map((p) => p.MOR.flightsCount)
			);
		});

		// this.flightCounterUnsub = this.flightService.flightCounters().subscribeToMore({
		// 	document: FlightCountersUpdatedDocument,
		// 	updateQuery: (prev, { subscriptionData }) => {
		// 		this.flightCounters = subscriptionData.data.flightCountersUpdated;
		// 	},
		// });
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.sPeriod && changes.sPeriod.currentValue && changes.sPeriod.currentValue != '') {
			this.selectedPeriod = changes.sPeriod?.currentValue;
			this.filter = this.selectedPeriod?.type;
			if (this.flightPeriods) {
				if (this.filter === 'all' || this.filter === 'custom') {
					this.selectedFlightPeriod = this.flightPeriods.month;
					this.histogramPeriod = 'month';
				} else {
					this.selectedFlightPeriod = this.flightPeriods[this.filter];
					this.histogramPeriod = this.filter;
				}
			}
			this.filterRangeForm.patchValue(this.selectedPeriod?.period);
			this.expanded = true;
		} else {
			this.selectedPeriod = null;
			this.filter = 'all';
			if (this.flightPeriods) {
				this.selectedFlightPeriod = this.flightPeriods.month;
				this.histogramPeriod = 'month';
			}
			this.filterRangeForm.patchValue({
				startDate: null,
				endDate: null,
			});
		}
	}

	updateHistogram() {
		this.selectedFlightPeriod = this.flightPeriods[this.filter];
		this.selectPeriod(this.filter, this.selectedFlightPeriod[0]);
	}
	selectPeriod(type: string, period: FlightPeriodCountersPerPeriod) {
		this.selectedPeriod = { type, period };
		if (this.filter === 'all' || this.filter === 'custom') {
			this.filter = 'month';
			this.selectedPeriod.type = 'month';
		}
		this.filterRangeForm.patchValue(this.selectedPeriod.period);
		this.filterChange.emit(this.selectedPeriod);
	}

	clearFilter() {
		this.selectedPeriod = null;
		this.filterChange.emit(null);
	}

	applyFilter() {
		this.filter = 'custom';
		this.selectedPeriod = {
			type: 'custom',
			period: {
				startDate: moment(this.filterRangeForm.get('startDate').value).format('YYYY-MM-DD'),
				endDate: moment(this.filterRangeForm.get('endDate').value).format('YYYY-MM-DD'),
			},
		};
		this.filterChange.emit(this.selectedPeriod);
	}
	translatePeriodLabel(label: string) {
		if (this.filter === 'month' || this.filter === 'all' || this.filter === 'custom') return moment(label).format('MMM');
		else return moment(label).format('DD/M');
	}
}
