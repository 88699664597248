import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { AuthenticationService } from '../authentication/authentication.service';
import { FetchSkeyesForecastDocument, GetSkeyesForecastDocument } from '../../graphql/generated/gen-types';

@Injectable({
	providedIn: 'root',
})
export class MeteoblueService {
	currentUser: any;
	constructor(private apollo: Apollo, private authenticationService: AuthenticationService) {
		this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}

	public getSkeyesForecast(slot: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetSkeyesForecastDocument,
			variables: {
				slot,
			},
		});
	}
	public fetchSkeyesForecast(username: string, password: string, flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: FetchSkeyesForecastDocument,
			variables: {
				username,
				password,
				flightId,
			},
			fetchPolicy: 'no-cache',
		});
	}
}
