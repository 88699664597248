import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { CrewStatus, Flight, FlightCrewInput, FlightStatus, Tag, UserRole } from 'src/app/core/graphql/generated/gen-types';
import { FlightService } from 'src/app/core/services/flight/flight.service';
import { SnackBar } from 'src/app/core/utility/snackBar';
import { ResponsiveService } from 'src/app/core/services/admin/responsive.service';
import {
	DisplayedColumn,
	flightColumnsBooking,
	flightColumnsHistory,
	flightColumnsUpcoming,
} from '../../../features/flight/flight-displayedcolumns';
import { Router } from '@angular/router';
import { EventService } from 'src/app/core/services/event/event.service';
import { DialogEventComponent } from '../../../features/dialog/dialog-event/dialog-event.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TagService } from 'src/app/core/services/tag/tag.service';
import { environment } from 'src/environments/environment';
import { DialogFlightPilotCrewComponent } from 'src/app/features/dialog/dialog-flight-pilot-crew/dialog-flight-pilot-crew.component';

@Component({
	selector: 'app-flight-table-is',
	templateUrl: './flight-table-is.component.html',
	styleUrls: ['./flight-table-is.component.scss'],
})
export class FlightTableIsComponent implements OnInit {
	// Table
	@Input() type: string;
	@Input() filterValue: string;
	@Input() filterRange: { startDate: any; endDate: any };
	@Input() viewState: any;
	@Input() userRole: UserRole;
	@Input() selectedFilterTags: Tag[] = [];
	@Output() addBookingToFlight = new EventEmitter<Flight>();

	tableHeight: number = 0;

	// component guards
	guards = environment.componentGuards.flightList;
	flightCounters; //Amount of total flights
	flights: Flight[] = [];
	datasourceFlights = null;
	displayedColumnsParent: DisplayedColumn[] = flightColumnsUpcoming;

	cursorUpcomingDate: string;

	cursorHistoricalDate: string;

	flightMoreSub: Subscription;

	isLoadingFlights = true;
	allUpcomingFlightsLoaded = false;
	allHistoricalFlightsLoaded = false;

	//Table settings
	flightsLimit = 50;

	isSmall: boolean;
	isXSmall: boolean;
	isMobile: boolean;
	screensize: number;
	amountOfRequests = 1;

	loadingIndexHistorical = 0;
	loadingIndexUpcoming = 0;

	loadTrigger = 80; // percentage of scrollProgress to trigger a new load

	displayedColumns = [];
	loadingViewState = true;
	summaryEnabled: boolean;
	// idOfflightToDelete = '';
	flightSubs: Subscription[] = [];

	flightUpdate: boolean = false;

	constructor(
		private flightService: FlightService,
		private translate: TranslateService,
		private eventService: EventService,
		private snackBar: SnackBar,
		private responsiveService: ResponsiveService,
		private tagService: TagService,
		public dialog: MatDialog,
		private router: Router
	) {
		moment.locale(this.translate.getDefaultLang());

		this.tableHeight = window.innerHeight - 360; // default full screen

		// responsiveService Subscriptions
		this.responsiveService.SmallChanged.subscribe((isSmall) => {
			this.isSmall = isSmall;
		});
		this.responsiveService.XSmallChanged.subscribe((isXSmall) => {
			this.isXSmall = isXSmall;
		});
		this.responsiveService.viewMobileChanged.subscribe((mobileView) => {
			this.isMobile = mobileView;
		});
		this.responsiveService.screenSizeChanged.subscribe((screensize) => {
			this.screensize = screensize;
			this.displayedColumns = this.getDisplayedColumns();
		});
	}

	ngOnInit(): void {
		this.displayedColumnsParent = flightColumnsUpcoming;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.filterValue && changes.filterValue.currentValue !== null) {
			this.filterValue = changes.filterValue.currentValue;
			this.resetTable();
			this.getData();
		}

		if (changes.viewState && changes.viewState.currentValue) {
			//load table
			this.loadingViewState = false;
			if (this.summaryEnabled != this.viewState.flightList.summaryEnabled) {
				this.resetTable();
			}
			this.getData();
			this.summaryEnabled = this.viewState.flightList.summaryEnabled;
		}

		if (changes.selectedFilterTags && changes.selectedFilterTags.currentValue) {
			this.resetTable();
			this.getData();
		}
		if (changes.userRole && changes.userRole.currentValue) {
			this.displayedColumns = this.getDisplayedColumns();
		}
		if (changes.filterRange) {
			if (changes.filterRange.currentValue === '') this.filterRange = null;
			this.resetTable();
			this.getData();
		}
	}

	ngOnDestroy(): void {
		if (this.flightSubs.length > 0) {
			this.flightSubs.forEach((sub) => sub.unsubscribe());
		}
	}

	private getAmountOfRequests(): void {
		const heightOfTable = window.innerHeight - 270;
		const heightOfRows = 49 * this.flightsLimit; //49px is the fixed height of each column in the table
		this.amountOfRequests = Math.ceil(heightOfTable / heightOfRows);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (this.loadingViewState) return;

		const heightOfTable = event.target.innerHeight - 220;
		const heightOfRows = 49 * this.flights.length;
		if (heightOfTable >= heightOfRows) {
			this.getData();
		}
	}

	private getData(): void {
		if (this.loadingViewState) return;
		this.getAmountOfRequests();
		if ((this.type === 'upcoming' || this.type === 'booking') && !this.allUpcomingFlightsLoaded) {
			this.getUpcomingFlightList();
		} else if (this.type === 'historical' && !this.allHistoricalFlightsLoaded) {
			this.getHistoricalFlightList();
		}
	}

	getUpcomingFlightList() {
		this.isLoadingFlights = true;
		this.loadingIndexUpcoming += 1;
		const index = this.loadingIndexUpcoming;
		const filter: string[] = this.selectedFilterTags.map((tag) => this.tagService.addPrefix(tag)).concat(this.filterValue);
		if (this.viewState.flightList.summaryEnabled) {
			const flightSub = this.flightService
				.getFlightsListSummary(this.flightsLimit, this.cursorUpcomingDate, filter, this.filterRange)
				.valueChanges.subscribe(
					(result) => {
						if (index === this.loadingIndexUpcoming || this.flightUpdate) {
							this.flightUpdate = false;
							this.flights.push(...result.data.getFlightsList);
							this.sortFlightList(true);
							this.cursorUpcomingDate = [...this.flights].pop()?.date;
							this.datasourceFlights = new MatTableDataSource(this.enrichFlightList(this.flights));
							if (result.data.getFlightsList.length < this.flightsLimit) {
								this.allUpcomingFlightsLoaded = true;
							}
							this.isLoadingFlights = false;
							if (this.amountOfRequests > 1) {
								this.amountOfRequests -= 1;
								this.getUpcomingFlightList();
							}
						}
					},
					(err) => {
						this.snackBar.openSnackBarError(err.message);
					}
				);
			this.flightSubs.push(flightSub);
			return;
		}
		const flightSub = this.flightService
			.getFlightsList(this.flightsLimit, this.cursorUpcomingDate, filter, this.filterRange)
			.valueChanges.subscribe(
				(result) => {
					if (index === this.loadingIndexUpcoming || this.flightUpdate) {
						this.flightUpdate = false;
						this.flights.push(...result.data.getFlightsList);
						this.sortFlightList(true);
						this.cursorUpcomingDate = [...this.flights].pop()?.date;
						this.datasourceFlights = new MatTableDataSource(this.enrichFlightList(this.flights));

						if (result.data.getFlightsList.length < this.flightsLimit) {
							this.allUpcomingFlightsLoaded = true;
						}
						this.isLoadingFlights = false;
						// Request more flights if table is not filled enough (this can happen when user has a bigger screen).
						if (this.amountOfRequests > 1) {
							this.getUpcomingFlightList();
							this.amountOfRequests -= 1;
						}
					}
				},
				(err) => {
					this.snackBar.openSnackBarError(err.message);
				}
			);
		this.flightSubs.push(flightSub);
	}

	getHistoricalFlightList() {
		this.isLoadingFlights = true;
		this.loadingIndexHistorical += 1;
		const index = this.loadingIndexHistorical;
		const filter: string[] = this.selectedFilterTags.map((tag) => this.tagService.addPrefix(tag)).concat(this.filterValue);
		if (this.viewState.flightList.summaryEnabled) {
			const flightSub = this.flightService
				.getHistoricalFlightsListSummary(this.flightsLimit, this.cursorHistoricalDate, filter, this.filterRange)
				.valueChanges.subscribe(
					(result) => {
						if (index === this.loadingIndexHistorical || this.flightUpdate) {
							this.flightUpdate = false;
							this.flights.push(...result.data.getHistoricalFlightsList);
							this.sortFlightList(false);
							this.cursorHistoricalDate = [...this.flights].pop()?.date;
							this.datasourceFlights = new MatTableDataSource(this.enrichFlightList(this.flights));
							if (result.data.getHistoricalFlightsList.length < this.flightsLimit) {
								this.allHistoricalFlightsLoaded = true;
							}
							this.isLoadingFlights = false;
							if (this.amountOfRequests > 1) {
								this.getHistoricalFlightList();
								this.amountOfRequests -= 1;
							}
						}
					},
					(err) => {
						this.snackBar.openSnackBarError(err.message);
					}
				);
			this.flightSubs.push(flightSub);
			return;
		}
		const flightSub = this.flightService
			.getHistoricalFlightsList(this.flightsLimit, this.cursorHistoricalDate, filter, this.filterRange)
			.valueChanges.subscribe(
				(result) => {
					if (index === this.loadingIndexHistorical || this.flightUpdate) {
						this.flightUpdate = false;
						this.flights.push(...result.data.getHistoricalFlightsList);
						this.sortFlightList(false);
						this.cursorHistoricalDate = [...this.flights].pop()?.date;
						this.datasourceFlights = new MatTableDataSource(this.enrichFlightList(this.flights));
						if (result.data.getHistoricalFlightsList.length < this.flightsLimit) {
							this.allHistoricalFlightsLoaded = true;
						}
						this.isLoadingFlights = false;
						if (this.amountOfRequests > 1) {
							this.getHistoricalFlightList();
							this.amountOfRequests -= 1;
						}
					}
				},
				(err) => {
					this.snackBar.openSnackBarError(err.message);
				}
			);
		this.flightSubs.push(flightSub);
	}

	private sortFlightList(isUpcoming: boolean) {
		if (isUpcoming) {
			this.flights = this.flights.filter((flight) => flight.status !== FlightStatus.Can && flight.status !== FlightStatus.Fin);
		}

		// keep most recent version (reverse) of a flight in case of duplicates (filter)
		this.flights = this.flights.reverse().filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

		// sort by flight date
		this.flights.sort((a, b) => a.id.localeCompare(b.id));
		this.flights.sort(function (a, b) {
			var c = <any>new Date(a.date);
			var d = <any>new Date(b.date);
			if (!isUpcoming) {
				return d - c;
			} else {
				return c - d;
			}
		});
	}

	private enrichFlightList(flights) {
		return flights.map((flight) => {
			// add weekend property
			const weekend = this.weekend(flight.date);

			// add duplicate balloon property
			const duplicateBalloon =
				flights.filter(
					(f) =>
						f.id != flight.id &&
						f.balloon?.name === flight.balloon?.name &&
						moment(f.date).format('YYYY-MM-DD') === moment(flight.date).format('YYYY-MM-DD') &&
						f.period === flight.period
				).length > 0;

			// add duplicate pilot property
			const duplicatePilot = !flight.pilot
				? false
				: flights.filter(
						(f) =>
							f.id != flight.id &&
							f.pilot?.crew?.name === flight.pilot?.crew?.name &&
							moment(f.date).format('YYYY-MM-DD') === moment(flight.date).format('YYYY-MM-DD') &&
							f.period === flight.period
				  ).length > 0;

			// add filter strings
			const findFilterBalloonName = this.findFilterString(flight?.balloon?.name);
			const findFilterLocationName = this.findFilterString(flight.location?.name);
			const findFilterPilotName = this.findFilterString(flight?.pilot?.crew?.name);
			if (!this.viewState.flightList.summaryEnabled) {
				const findFilterBookings = flight.bookingsData?.list.map((booking) => {
					return { found: this.findFilter(booking), contactName: booking.contactName };
				});
				return {
					...flight,
					weekend,
					duplicateBalloon,
					duplicatePilot,
					findFilterBalloonName,
					findFilterLocationName,
					findFilterPilotName,
					findFilterBookings,
				};
			}
			return { ...flight, weekend, duplicateBalloon, duplicatePilot, findFilterBalloonName, findFilterLocationName, findFilterPilotName };
		});
	}

	toggleVisibility(flight): void {
		this.flightUpdate = true;
		this.flightService.toggleVisibility(flight.id).subscribe(
			() => {},
			(err) => this.snackBar.openSnackBarError(err.message)
		);
	}

	findFilter(booking) {
		let found = false;
		if (this.filterValue) {
			booking.passengers.forEach((pax) => {
				found = found || pax.name.toLowerCase().includes(this.filterValue.toLowerCase());
			});
			found = found || booking.contactName.toLowerCase().includes(this.filterValue.toLowerCase());
		}
		return found;
	}

	findFilterString(displayString) {
		let found = false;
		if (this.filterValue && displayString) {
			found = displayString.toLowerCase().includes(this.filterValue.toLowerCase());
		}
		return found;
	}

	weekend(date) {
		const saturday = moment('2020-10-31').weekday();
		const sunday = moment('2020-11-01').weekday();
		const weekday = moment(date).weekday();
		return weekday === saturday || weekday === sunday;
	}

	flightClicked(row): void {
		this.navigateToFlight(row.id);
	}

	getDisplayedColumns() {
		if (this.type === 'upcoming') this.displayedColumnsParent = flightColumnsUpcoming;
		if (this.type === 'booking') {
			this.displayedColumnsParent = flightColumnsBooking;
			this.tableHeight = 600;
		}
		if (this.type === 'historical') this.displayedColumnsParent = flightColumnsHistory;

		let displayedColumns = this.displayedColumnsParent.filter((col) => col.minSize <= this.screensize);

		// apply guards on columns
		if (!this.guards.bookingsColumn.includes(this.userRole)) {
			displayedColumns = displayedColumns.filter((col) => col.name != 'bookings');
		}

		// avoid name and badge name together
		if (displayedColumns.findIndex((c) => c.name === 'nameBadge') > -1) {
			displayedColumns = displayedColumns.filter((c) => c.name != 'name');
		}
		return displayedColumns.map((col) => col.name);
	}

	navigateToFlight(id?): void {
		let url = '/flight';
		if (id) {
			url = url + '/' + id;
		}
		this.router.navigate([url]);
	}

	// only Historical Table
	deleteFlight(flight): void {
		// this.idOfflightToDelete = flight.id;
		this.flightService.delete(flight).subscribe(
			() => {
				// remove from table immediately
				this.flights.splice(
					this.flights.findIndex((f) => f.id === flight.id),
					1
				);
				this.datasourceFlights = new MatTableDataSource(this.flights);
			},
			(err) => this.snackBar.openSnackBarError(err.message)
		);
	}

	removeEvent(id, date): void {
		this.flightUpdate = true;
		this.eventService.delete(id).subscribe(
			() => {
				this.flightService.getFlightsListFromDate(date).valueChanges.subscribe();
			},
			(err) => this.snackBar.openSnackBarError(err.message)
		);
	}

	addEvent(): void {
		this.flightUpdate = true;
		const dialogRef = this.dialog.open(DialogEventComponent, {
			data: true,
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result.date) {
				this.flightService.getFlightsListFromDate(result.date).valueChanges.subscribe();
			}
		});
	}

	scrollHandler(e): void {
		const scrollProgress = Math.floor(((e.srcElement.scrollTop + e.srcElement.clientHeight) / e.srcElement.scrollHeight) * 100);
		if (scrollProgress > this.loadTrigger) {
			this.getData();
		}
	}

	displayDetails(flight: Flight, isBookings: boolean): void {
		if (this.showBlurry(flight)) {
			this.isLoadingFlights = true;
			this.flightService.getUpcomingFlight(flight.id).valueChanges.subscribe(
				(detailedFlight) => {
					let flightsCopy = [...this.flights];
					flightsCopy[flightsCopy.findIndex((flightElement) => flightElement.id === flight.id)] = detailedFlight.data.flight;
					this.flights = flightsCopy;
					this.datasourceFlights = new MatTableDataSource(this.flights);
					this.isLoadingFlights = false;
				},
				(err) => this.snackBar.openSnackBarError(err.message)
			);
		} else if (!isBookings) {
			this.navigateToFlight(flight.id);
		}
	}

	showBlurry(flight: Flight): boolean {
		return this.summaryEnabled;
	}

	private resetTable(): void {
		this.displayedColumns = this.getDisplayedColumns();
		if (this.type === 'upcoming') {
			this.flights = [];
			this.cursorUpcomingDate = '';
			this.allUpcomingFlightsLoaded = false;
			this.datasourceFlights = new MatTableDataSource(this.flights);
		} else if (this.type === 'historical') {
			this.flights = [];
			this.cursorHistoricalDate = '';
			this.allHistoricalFlightsLoaded = false;
			this.datasourceFlights = new MatTableDataSource(this.flights);
		} else if (this.type === 'booking') {
			this.flights = [];
			this.cursorUpcomingDate = '';
			this.allUpcomingFlightsLoaded = false;
			this.datasourceFlights = new MatTableDataSource(this.flights);
		}
	}

	updateComments(flight, event) {
		if (event) {
			this.flightUpdate = true;
			const comments = event.save ? event.comments : flight.comment;
			const tags = event.save ? event.tags : flight.tags;

			this.flightSubs.push(
				this.flightService.updateFlightComments(flight.id, comments).subscribe(
					() => {},
					(err) => this.snackBar.openSnackBarError(err.message)
				)
			);
			if (tags) {
				this.flightSubs.push(
					this.flightService.updateFlightTags(flight.id, tags).subscribe(
						() => {},
						(err) => this.snackBar.openSnackBarError(err.message)
					)
				);
			}
		}
	}

	addToFlight(flight) {
		this.addBookingToFlight.emit(flight);
	}

	handlePassengersClick(event: Event, pass: any) {
		if (this.isXSmall) {
			event.stopPropagation();
			pass.toggle();
		}
	}

	showDialogPilotCrew(flight) {
		const dialogRef = this.dialog.open(DialogFlightPilotCrewComponent, { data: flight, disableClose: true, minWidth: '420px', hasBackdrop: true});
		dialogRef.afterClosed().subscribe((result) => {
			// prepare the input object for the mutation with status CONFIRMED
			const crew: FlightCrewInput[] = [];
			const flightId = result?.id;
			if (flightId && result.pilot) {
				const pilot: FlightCrewInput = { id: result.pilot.id, isPilot: true, status: CrewStatus.Con };
				crew.push(pilot);
			}
			if (flightId && result.crew) {
				const flightId = result.id;
				result.crew.forEach((c) => {
					crew.push({ id: c.crew.id, isPilot: false, status: CrewStatus.Con });
				});
			}
			if (flightId) {
				this.flightService.saveFlightCrew(crew, flightId).subscribe((res) => {});
			}
		});
	}
}
