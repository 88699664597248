<div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
	<div *ngFor="let log of logs" style="display:flex; flex-direction: row; gap: 10px; justify-content: flex-start; align-items:center">
		<button *ngIf="bookingId" mat-icon-button color="warn" (click)="deleteLog(log?.id)"><mat-icon>delete</mat-icon></button>
		<button *ngIf="flightId" mat-icon-button color="warn" (click)="deleteLog(log?.id, log?.booking?.id)"><mat-icon>delete</mat-icon></button>
		<div style="min-width: 140px">{{ log?.date | formatDate: 'YYYY-MM-DD HH:mm' }}<button mat-icon-button color="primary" disabled=true><mat-icon *ngIf="log.merge" >merge</mat-icon></button></div>
		<div *ngIf="!isMobile" style="min-width: 180px">({{ log?.user }})</div>
		<button
			*ngIf="bookingId"
			mat-stroked-button
			(click)="navigateToFlight(log?.flight.id)"
			style="min-width: 120px; word-wrap: break-word"
			fxLayoutAlign="start center"
		>
			<mat-icon color="accent" *ngIf="log.action === 'INVI'">sms</mat-icon>
			<mat-icon color="accent" *ngIf="log.action === 'ADDE'">assignment</mat-icon>
			<mat-icon color="accent" *ngIf="log.action === 'CONF'">assignment_turned_in</mat-icon>
			<mat-icon color="primary" *ngIf="log.action === 'REMO'">delete</mat-icon>
			{{ log?.flight?.balloon?.name }} {{ log?.flight?.hour | formatDate: 'DD MMM YY' }}
		</button>
		<button
			*ngIf="flightId"
			mat-stroked-button
			(click)="navigateToBooking(log?.booking.id)"
			style="min-width: 120px; word-wrap: break-word"
			fxLayoutAlign="start center"
		>
			<mat-icon color="accent" *ngIf="log.action === 'INVI'">sms</mat-icon>
			<mat-icon color="accent" *ngIf="log.action === 'ADDE'">assignment</mat-icon>
			<mat-icon color="accent" *ngIf="log.action === 'CONF'">assignment_turned_in</mat-icon>
			<mat-icon color="primary" *ngIf="log.action === 'REMO'">delete</mat-icon>
			{{ log?.booking?.contactName }}
		</button>
		<div *ngIf="!isMobile" style="min-width: 180px">{{ 'BOOKINGS.LOG.' + log.action | translate | capitalize }}</div>
	</div>
</div>

