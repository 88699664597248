import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Tag, TagType } from 'src/app/core/graphql/generated/gen-types';
import { TagService } from 'src/app/core/services/tag/tag.service';
import { SnackBar } from 'src/app/core/utility/snackBar';
import { TranslateService } from '@ngx-translate/core';
import { SatPopover } from '@ncstate/sat-popover';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TagNewComponent } from '../tag-new/tag-new.component';

@Component({
	selector: 'app-tag-autocomplete',
	templateUrl: './tag-autocomplete.component.html',
	styleUrls: ['./tag-autocomplete.component.scss'],
})
export class TagAutocompleteComponent implements OnInit {
	@Input() tagType: TagType = null;
	@Input() alreadyAssignedTags: Tag[] = [];
	@Output() selectedTagsEvent = new EventEmitter<Tag[]>();
	selectedTags: Tag[] = [];

	@ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

	readonly separatorKeysCodes = [ENTER, COMMA] as const;

	tags: Tag[] = [];
	filteredTags: Observable<Tag[]>;
	tagControl = new FormControl('');
	private dialogRef: MatDialogRef<TagNewComponent, any>;

	constructor(
		private tagService: TagService,
		private snackBar: SnackBar,
		private translate: TranslateService,
		public popover: SatPopover,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.getTags();
	}

	private getTags(): void {
		if (this.tagType) {
			this.tagService.getTagsOfType(this.tagType, false).valueChanges.subscribe(
				(result) => {
					const tags: Tag[] = this.tagService.sortByTagName(result.data.getTagsOfType);
					this.tags = tags;
					if (this.alreadyAssignedTags) this.selectedTags = [...this.alreadyAssignedTags];
					this.filteredTags = this.tagControl.valueChanges.pipe(
						startWith(''),
						map((tagName: string | null) => (tagName ? this._filterTags(tagName) : this.tags.slice().filter((tag) => tag.enabled)))
					);
				},
				(error) => {
					this.snackBar.openSnackBarError(error.message);
				}
			);
		} else {
			this.tagService.getAllTags(false).valueChanges.subscribe(
				(result) => {
					const tags: Tag[] = this.tagService.sortByTagName(result.data.getAllTags);
					this.tags = tags;
					if (this.alreadyAssignedTags) this.selectedTags = [...this.alreadyAssignedTags];
					this.filteredTags = this.tagControl.valueChanges.pipe(
						startWith(''),
						map((tagName: string | null) => (tagName ? this._filterTags(tagName) : this.tags.slice().filter((tag) => tag.enabled)))
					);
				},
				(error) => {
					this.snackBar.openSnackBarError(error.message);
				}
			);
		}
	}

	private _filterTags(tag: string): Tag[] {
		const filterValue = tag.toLowerCase();
		return this.tags.filter((tag) => tag.tag.toLowerCase().includes(filterValue) && tag.enabled);
	}

	private findTagFromNameAndAddToSelected(tagName: string): void {
		const newTag: Tag = this.tags.find((tag) => tag.tag.toLowerCase() === tagName.toLowerCase());
		if (this.selectedTags.find((tag) => tag.id === newTag.id)) {
			this.snackBar.openSnackBar(this.translate.instant('TAGS.tag_already_added'), 'OK', 4000);
		} else {
			this.selectedTags.push(newTag);
			this.passSelectedTags();
		}
		this.tagControl.setValue(null);
		this.tagInput.nativeElement.value = '';
		this.tagInput.nativeElement.blur();
	}

	selectFromAutoComplete(event: MatAutocompleteSelectedEvent): void {
		const selectedTagName: string = event.option.value.toLowerCase();
		this.findTagFromNameAndAddToSelected(selectedTagName);
	}

	addFromInput(event: MatChipInputEvent): void {
		const tagName: string = event.value.toLowerCase();
		this.findTagFromNameAndAddToSelected(tagName);
		event.chipInput!.clear();
	}

	removeTagFromSelected(tagRemoved: Tag): void {
		this.selectedTags = this.selectedTags.filter((tag) => tag.id !== tagRemoved.id);
		this.passSelectedTags();
	}

	passSelectedTags(): void {
		this.selectedTagsEvent.emit(this.selectedTags);
	}

	openCreateTagDialog(): void {
		this.dialogRef = this.dialog.open(TagNewComponent, {
			data: {
				type: this.tagType,
			},
		});
	}
}
