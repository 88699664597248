<h1 mat-dialog-title>{{ 'EVENTS.new_event' | translate | capitalize}}</h1>
<mat-dialog-content class="mat-typography">
    <p>{{ 'EVENTS.information' | translate | capitalize}}</p>
    <form [formGroup]="eventForm" novalidate autocomplete="off" style="margin: 20px">
        <mat-form-field fxLayout="row">
            <input matInput [matDatepicker]="picker_date"
            placeholder="{{ 'EVENTS.date' | translate | capitalize }}" formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="picker_date"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true" #picker_date></mat-datepicker>
            <mat-error *ngIf="eventForm.get('date').hasError('required')">
                {{ 'EVENTS.required' | translate | capitalize }}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <input matInput type="text" placeholder="{{ 'EVENTS.description' | translate | capitalize }}" name="name" formControlName="description">
            <mat-error *ngIf="eventForm.get('description').hasError('required')">
                {{ 'EVENTS.required' | translate | capitalize }}
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-raised-button [disabled]="!eventForm.valid" (click)="save(eventForm)" color="primary">{{ 'EVENTS.save' | translate | capitalize}}</button>
    <button mat-raised-button (click)="cancel()">{{ 'EVENTS.cancel' | translate | capitalize}}</button>
</div>
<div style= "margin-top: 60px">
    <div *ngIf="spinner" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-spinner></mat-spinner>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
        <button mat-raised-button *ngFor="let list of holidays" color="accent"
            (click)="loadHolidays(list)">
            {{ ('EVENTS.load_public_holidays_of_year_from_country' | translate | capitalize).replace('{0}', list.year).replace('{1}',list.country)}}
        </button>
    </div>
</div>
