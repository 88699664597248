<mat-chip-list>
    <div *ngFor="let tag of tags">
        <mat-chip
            *ngIf="upcoming ? tag.upcoming : tag.history"
            [disabled]="disableAll"
            [ngStyle]="tag.backgroundColor ? { 'background-color': tag.backgroundColor, color: tag.fontColor } : {}"
            (click)="!disableAll && selectTag(tag)"
            style="cursor: pointer;"
        >
            <div *ngIf="upcoming === undefined">{{ tag.tag }}</div>
            <div *ngIf="upcoming !== undefined">{{ tag.tag }} ({{ upcoming ? tag.upcoming : tag.history }})</div>
            <mat-icon *ngIf="isSelected(tag)" [ngStyle]="{ 'color': tag.fontColor }">check</mat-icon>
        </mat-chip>
    </div>
</mat-chip-list>