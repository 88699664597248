<form [formGroup]="formBookingPreferredDate" autocomplete="off">
    <mat-form-field>
        <input
            matInput
            [matDatepicker]="picker"
            (focus)="picker.open()"
            placeholder="{{ 'FLIGHTS.date' | translate | capitalize }}"
            formControlName="flightDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi="true" #picker></mat-datepicker>
    </mat-form-field>
    <mat-radio-group fxLayout="row wrap" fxLayoutGap="20px" class="radio-group" formControlName="flightPeriod">
        <mat-radio-button class="radio-button" *ngFor="let period of periods" [value]="period">
            {{ 'FLIGHTS.' + period | translate | capitalize }}
        </mat-radio-button>
    </mat-radio-group>
	<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" style="margin-top: 20px">
		<button mat-raised-button color="primary" (click)="save(formBookingPreferredDate.value)">
			{{ 'APPLICATION.save' | translate | capitalize }}
		</button>
		<button mat-raised-button (click)="cancel(formBookingPreferredDate.value)">{{ 'APPLICATION.cancel' | translate | capitalize }}</button>
	</div>
</form>