// unit conversions
export const UC = {
  M_FT: 3.28084,
  FT_M: 0.3048,
  HPA_KG: 0.1,
  CELCIUS_KELVIN: 273,
  MPS_KT: 1.9438445
};

export const TEMP_GRADIENT = -6.5 * 0.001;
export const MOL_MASS = 0.02897;
export const GAS_CONSTANT = 8.3145;
export const GRAVI = 9.81;
export const WEIGHT_CLOTHES = 4;
export const WEIGHT_LUGGAGE = 3;
export const WEIGHT_PAX = 75;
export const FUEL_RATE_LIFT = 0.1; // How much fuel is consumed per kg lift per hour
export const LIFT_LOSS_RATE_VOLUME = 4 / 1000; // How much lift is lost per °C per volume
export const TEMP_NORMAL = 20;
export const PRESSURE_NORMAL = 1013;

export const BOOKINGS_LIMIT = 500;

export const SMS_CHARS = 140;
