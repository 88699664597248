<!-- GOOD -->
<div *ngIf="balloon?.isCurrent > NOTIFY" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
	<mat-icon style="color: #1faa00">gpp_good</mat-icon>
	<span>{{ balloon?.name }}</span>
</div>

<!-- BAD -->
<div
	*ngIf="balloon?.isCurrent <= 0"
	fxLayout="row"
	fxLayoutAlign="start center"
	fxLayoutGap="5px"
	matTooltip="{{ 'BALLOONS.error_not_current' | translate | capitalize }}"
>
	<mat-icon color="warn">gpp_bad</mat-icon>
	<span>{{ balloon?.name }}</span>
</div>

<!-- UGLY -->
<div
	*ngIf="balloon?.isCurrent <= NOTIFY && balloon?.isCurrent > 0"
	fxLayout="row"
	fxLayoutAlign="start center"
	fxLayoutGap="5px"
	matTooltip="{{ 'BALLOONS.warning_not_current' | translate | capitalize }}"
>
	<mat-icon style="color: #ffca28">gpp_maybe</mat-icon>
	<span>{{ balloon?.name }}</span>
</div>

