import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/core/services/event/event.service';
import * as moment from 'moment';
import { SnackBar } from 'src/app/core/utility/snackBar';
import { EventType } from 'src/app/core/graphql/generated/gen-types';
import { DateAdapter } from '@angular/material/core';

@Component({
	selector: 'app-dialog-event',
	templateUrl: './dialog-event.component.html',
	styleUrls: ['./dialog-event.component.scss'],
})
export class DialogEventComponent implements OnInit {
	eventForm: FormGroup;
	holidays = [];
	language: string;
	spinner = false;

	constructor(
		public dialogRef: MatDialogRef<DialogEventComponent>,
		private fb: FormBuilder,
		private eventService: EventService,
		private translate: TranslateService,
		private snackBar: SnackBar,
		private _dateAdapter: DateAdapter<any>,
		@Inject(MAT_DIALOG_DATA) public data: boolean
	) {
		// set locale for date picker
		let lang = this.translate.currentLang;
		if (lang === 'du') lang = 'nl';
		if (lang) this._dateAdapter.setLocale(lang);
	}

	ngOnInit(): void {
		this.eventForm = this.fb.group({
			date: [new Date(), Validators.required],
			description: ['', Validators.required],
		});

		this.language = this.translate.currentLang;
		switch (this.language) {
			case 'nl': {
				this.holidays.push({ year: moment().format('YYYY'), country: 'België', country_code: 'be' });
				this.holidays.push({ year: moment().format('YYYY'), country: 'Nederland', country_code: 'nl' });
				this.holidays.push({ year: moment().add(1, 'year').format('YYYY'), country: 'België', country_code: 'be' });
				this.holidays.push({ year: moment().add(1, 'year').format('YYYY'), country: 'Nederland', country_code: 'nl' });
				break;
			}
			case 'fr': {
				this.holidays.push({ year: moment().format('YYYY'), country: 'Belgique', country_code: 'be' });
				this.holidays.push({ year: moment().format('YYYY'), country: 'France', country_code: 'fr' });
				this.holidays.push({ year: moment().format('YYYY'), country: 'Luxembourg', country_code: 'lu' });
				this.holidays.push({ year: moment().add(1, 'year').format('YYYY'), country: 'Belgique', country_code: 'be' });
				this.holidays.push({ year: moment().add(1, 'year').format('YYYY'), country: 'France', country_code: 'fr' });
				this.holidays.push({ year: moment().add(1, 'year').format('YYYY'), country: 'Luxembourg', country_code: 'lu' });
				break;
			}
			case 'en': {
				this.holidays.push({ year: moment().format('YYYY'), country: 'United Kingdom', country_code: 'gb' });
				this.holidays.push({ year: moment().add(1, 'year').format('YYYY'), country: 'United Kingdom', country_code: 'gb' });
				break;
			}
		}
	}

	cancel() {
		this.dialogRef.close({ status: 'cancel' });
	}

	save(eventForm) {
		if (eventForm.valid) {
			const newEvent = {
				date: moment(eventForm.get('date').value).format('YYYY-MM-DD'),
				description: eventForm.get('description').value,
				type: EventType.Opr,
			};
			if (typeof this.data === 'boolean' && this.data) {
				this.eventService.saveEventWithNoRefetch(newEvent).subscribe(
					(event) => {
						this.dialogRef.close({ status: 'save', date: newEvent.date });
					},
					(err) => this.snackBar.openSnackBarError(err.message)
				);
			} else {
				this.eventService.save(newEvent).subscribe(
					(event) => {
						this.dialogRef.close({ status: 'save', event });
					},
					(err) => this.snackBar.openSnackBarError(err.message)
				);
			}
		}
	}

	loadHolidays(list) {
		this.spinner = true;
		this.eventService.fetchHolidays(list.year, list.country_code, this.language).subscribe(
			() => {
				this.spinner = false;
				this.dialogRef.close({ status: 'holidays' });
			},
			(error) => {
				console.log(error);
				this.dialogRef.close({ status: 'error', error });
			}
		);
	}
}
