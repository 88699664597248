<div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
	<div *ngFor="let mail of mails" fxLayout="row wrap" [fxLayoutGap]="isMobile ? '10px' : '25px'" fxLayoutAlign="space-around start">
		<div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start start">
			<div *ngIf="mail?.resultAt" fxLayout="column" fxLayoutGap="3px" style="min-width: 140px">
				<div fxLayout="row">{{ 'MAIL.sent' | translate | capitalize }}<mat-icon *ngIf="mail.merge">merge</mat-icon></div>
				<span style="font-size: x-small">{{ mail?.resultAt | formatDate: 'DD MMM YY HH:mm' }}</span>
			</div>
			<span *ngIf="!mail?.resultAt" style="min-width: 140px">{{ 'APPLICATION.waiting' | translate | capitalize }}</span>
			<div fxLayout="column" fxLayoutGap="10px">
				<button
					mat-stroked-button
					style="min-width: 120px; word-wrap: break-word"
					fxLayoutAlign="start center"
					(click)="showMail(mail)"
					[matBadge]="mail.events.length"
					matBadgePosition="after"
					[matBadgeColor]="badgeColor(mail.events)"
					[matBadgeHidden]="mail.events.length === 0"
				>
					<mat-icon *ngIf="mail?.resultAt" color="accent">mail</mat-icon>
					<mat-icon *ngIf="!mail?.resultAt" color="primary">mail</mat-icon>
					{{ mail?.subject }}
				</button>
				<div *ngFor="let event of sortMailEvents(mail.events); let i = index">
					<div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
						<div fxLayout="column" fxLayoutGap="3px">
							<!-- processing -->
							<mat-icon *ngIf="event.event === 'processed'" style="color: #ffca28"> circle </mat-icon>
							<mat-icon *ngIf="event.event === 'deferred'" style="color: #ffca28"> circle </mat-icon>
							<!-- problem -->
							<mat-icon *ngIf="event.event === 'dropped'" color="warn"> circle </mat-icon>
							<mat-icon *ngIf="event.event === 'bounce'" color="warn"> circle </mat-icon>
							<mat-icon *ngIf="event.event === 'blocked'" color="warn"> circle </mat-icon>
							<!-- Delivered -->
							<mat-icon *ngIf="event.event === 'delivered'" style="color: #1faa00"> circle </mat-icon>
							<!-- engagement -->
							<mat-icon *ngIf="event.event === 'open'" style="color: #1faa00"> radio_button_unchecked </mat-icon>
							<mat-icon *ngIf="event.event === 'click'" style="color: #1faa00"> radio_button_unchecked </mat-icon>
							<mat-icon *ngIf="i < mail.events.length - 1" style="color: grey"> south </mat-icon>
						</div>
						<div fxLayout="column" fxLayoutGap="3px">
							{{ event.event }} {{ event.url }}
							<span style="font-size: x-small">{{ event.timestamp | formatDate: 'DD MMM YY HH:mm' }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
