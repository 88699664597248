<mat-progress-bar *ngIf="isLoadingBookings" mode="indeterminate"></mat-progress-bar>
<div class="table-container" [ngStyle]="tableHeight ? { 'height.px': tableHeight } : {}" #scrollContainer (scroll)="scrollHandler($event)">
	<mat-table
		#table
		[dataSource]="datasourceBookings"
		matSort
		[matSortActive]="sortActive"
		[matSortDirection]="sortDirection"
		(matSortChange)="handleSortEvent($event)"
	>
		<!-- Checkbox Column -->
		<ng-container matColumnDef="status" (click)="$event.stopPropagation()">
			<mat-header-cell [style.padding-left.px]="isXSmall ? 3 : {}" fxFlex="120px" *matHeaderCellDef>
				{{ 'BOOKINGS.status' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell [style.padding-left.px]="isXSmall ? 3 : {}" fxFlex="120px" *matCellDef="let booking">
				<mat-icon
					[matTooltip]="booking?.daysExpired ? ('BOOKINGS.days_expired' | translate: { d: booking?.daysExpired }) : ''"
					[color]="booking?.daysExpired ? 'warn' : booking.iconColor"
				>
					{{ booking.icon }}
				</mat-icon>
				<span *ngIf="booking?.passengerCounters?.openAmount !== 0" style="color: red; font-weight: bold">{{ currency }}</span>
				<div fxLayout="column" fxLayoutGap="0px" fxLayoutAlign="start center">
					<mat-icon fxLayout="row" *ngIf="booking?.passengerCounters?.childCount > 0" color="primary">escalator_warning</mat-icon>
					<span fxLayout="row" *ngIf="booking?.passengerCounters?.childCount > 1">
						{{ booking?.passengerCounters?.childCount }}
					</span>
				</div>
				<div fxLayout="column" fxLayoutGap="0px" fxLayoutAlign="start center">
					<mat-icon *ngIf="booking.passengerCounters?.transportCount > 0" color="primary">airport_shuttle</mat-icon>
					<span *ngIf="booking.passengerCounters?.transportCount > 1">{{ booking.passengerCounters?.transportCount }} </span>
				</div>
				<div fxLayout="column" fxLayoutGap="0px" fxLayoutAlign="start center">
					<mat-icon *ngIf="booking.passengerCounters?.disabilityCount > 0" color="primary">local_hospital</mat-icon>
					<span *ngIf="booking.passengerCounters?.disabilityCount > 1">{{ booking.passengerCounters?.disabilityCount }} </span>
				</div>
				<mat-icon *ngIf="booking?.external">import_export</mat-icon>
			</mat-cell>
		</ng-container>
		<!-- bookingDate -->
		<ng-container matColumnDef="bookingDate">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'BOOKINGS.booking_date' | translate | capitalize }} </mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking?.bookingDate | formatDate: 'DD MMM YY' }}
			</mat-cell>
		</ng-container>
		<!-- name -->
		<ng-container matColumnDef="contactName">
			<mat-header-cell style="min-width: 120px" *matHeaderCellDef mat-sort-header
				>{{ 'BOOKINGS.name' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell style="min-width: 120px" *matCellDef="let booking">
				{{ booking.contactName }}
			</mat-cell>
		</ng-container>
		<!-- phone -->
		<ng-container matColumnDef="phone">
			<mat-header-cell fxFlex="120px" *matHeaderCellDef>
				{{ 'ACTORS.phone' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell fxFlex="120px" *matCellDef="let booking">
				{{ booking.contactPhone | intphone }}
			</mat-cell>
		</ng-container>
		<!-- comments -->
		<ng-container matColumnDef="comments">
			<mat-header-cell style="min-width: 180px" *matHeaderCellDef>
				{{ 'BOOKINGS.comments' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				class="hover-cell"
				style="min-width: 180px"
				*matCellDef="let booking"
				[satPopoverAnchor]="p"
				(click)="p.toggle(); $event.stopPropagation()"
			>
				<div fxLayout="column" fxLayoutAlign="space-between start">
					<div *ngIf="booking.tags && booking.tags.length > 0" style="padding: 3px 0">
						<div fxLayout="row">
							<div *ngFor="let tag of booking.tags" (click)="p.toggle(); $event.stopPropagation()">
								<mat-icon [matTooltip]="tag.tag" [ngStyle]="tag.backgroundColor ? { color: tag.backgroundColor } : {}">bookmark</mat-icon>
							</div>
						</div>
					</div>
					<sat-popover #p hasBackdrop xAlign="start" yAlign="start" (closed)="updateComments(booking, $event)">
						<div class="sat">
							<app-booking-comments [booking]="booking"></app-booking-comments>
						</div>
					</sat-popover>
					{{ booking.comments }}
				</div>
				<mat-icon class="hover-icon"><span class="material-symbols-outlined"> left_click </span></mat-icon>
			</mat-cell>
		</ng-container>
		<!-- contactCard -->
		<ng-container matColumnDef="contactCard">
			<mat-header-cell style="min-width: 120px" *matHeaderCellDef>
				{{ 'BOOKINGS.booking' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell style="min-width: 120px" *matCellDef="let booking">
				<div fxLayout="column" fxLayoutAlign="start start">
					<strong>{{ booking.contactName }} </strong>
					<div>{{ booking.contactPhone }}</div>
				</div>
			</mat-cell>
		</ng-container>
		<!-- region -->
		<ng-container matColumnDef="region">
			<mat-header-cell style="min-width: 120px" *matHeaderCellDef>
				{{ 'BOOKINGS.region' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell style="min-width: 120px" *matCellDef="let booking">
				<div *ngIf="booking?.locationType === 'REGI'">
					<app-region-badge [region]="booking?.region"></app-region-badge>
				</div>
				<div *ngIf="booking?.locationType === 'FIXE'" fxLayout="column">
					<app-region-badge [region]="booking?.region"></app-region-badge>
					{{ booking?.location?.name }}
				</div>
				<div *ngIf="booking?.locationType === 'PRIV'" fxLayout="column">
					{{ 'BOOKINGS.' + booking?.locationType | translate | capitalize }}
					{{ booking?.location?.city }}
				</div>
			</mat-cell>
		</ng-container>
		<!-- city -->
		<ng-container matColumnDef="city">
			<mat-header-cell style="min-width: 100px" *matHeaderCellDef>{{ 'FLIGHTS.city' | translate | capitalize }} </mat-header-cell>
			<mat-cell style="min-width: 100px" *matCellDef="let booking">
				{{ booking.city }}
			</mat-cell>
		</ng-container>
		<!-- passengers -->
		<ng-container matColumnDef="passengers">
			<mat-header-cell style="min-width: 80px" *matHeaderCellDef>
				{{ 'BOOKINGS.passengers' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell style="min-width: 80px" *matCellDef="let booking">
				{{ booking?.passengerCounters?.passengerCount + ' (' + booking?.passengerCounters?.totalWeight + ' kg)' }}
			</mat-cell>
		</ng-container>
		<!-- passengersCard -->
		<ng-container matColumnDef="passengersCard">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.passengers' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				<div fxLayout="column">
					<div *ngIf="booking?.inviteCount > 1">
						{{ booking?.passengerCounters?.passengerCount }} ({{ booking?.passengerCounters?.totalWeight }} kg) - {{ booking?.inviteCount }}
						{{ 'FLIGHTS.inviteCount' | translate }}
					</div>
					<div *ngIf="booking?.inviteCount === 1">
						{{ booking?.passengerCounters?.passengerCount }} ({{ booking?.passengerCounters?.totalWeight }} kg) - {{ booking?.inviteCount }}
						{{ 'FLIGHTS.invite_count_1' | translate }}
					</div>
					<div *ngIf="booking?.inviteCount === 0">
						{{ booking?.passengerCounters?.passengerCount }} ({{ booking?.passengerCounters?.totalWeight }} kg)
					</div>
					<div>{{ booking?.city }}</div>
				</div>
			</mat-cell>
		</ng-container>
		<!-- preferredDate -->
		<ng-container matColumnDef="preferredDate">
			<mat-header-cell style="min-width: 135px" *matHeaderCellDef mat-sort-header>
				{{ 'BOOKINGS.availability' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell style="min-width: 135px" *matCellDef="let booking" [satPopoverAnchor]="p">
				<div class="hover-cell" *ngIf="booking.availabilityType === 'DATE'" (click)="p.toggle(); $event.stopPropagation()">
					<div *ngFor="let preferredFlight of booking.preferredFlights">
						<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
							<div>{{ preferredFlight.date | formatDate: 'dd DD MMM YY' }}</div>
							<div *ngIf="preferredFlight.period === 'MOR'">(AM)</div>
						</div>
					</div>
					<mat-icon class="hover-icon"><span class="material-symbols-outlined"> left_click </span></mat-icon>
				</div>
				<sat-popover #p hasBackdrop xAlign="start" yAlign="start" (closed)="updatePreferredFlights(booking, $event)">
					<div class="sat">
						<app-booking-preferred-date [booking]="booking"></app-booking-preferred-date>
					</div>
				</sat-popover>
			</mat-cell>
		</ng-container>
		<!-- flightdate -->
		<ng-container matColumnDef="flightDate">
			<mat-header-cell style="min-width: 135px" *matHeaderCellDef>
				{{ 'BOOKINGS.flightDate' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell style="min-width: 135px" *matCellDef="let booking" (click)="$event.stopPropagation()">
				<button
					*ngIf="booking.flight"
					mat-stroked-button
					(click)="navigateToFlight(booking)"
					[matTooltip]="booking.flight?.passengersCount + '/' + booking.flight.balloon?.capacity"
				>
					<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
						<div>{{ booking.flight?.date | formatDate: 'dd DD MMM YY' }}</div>
						<div *ngIf="booking.flight.period === 'MOR'">(AM)</div>
					</div>
					<mat-progress-bar
						*ngIf="booking.flight.passengersOccupancy > 75 && booking.flight.passengersOccupancy <= 100"
						type="determinate"
						color="accent"
						[value]="booking.flight.passengersOccupancy"
					></mat-progress-bar>
					<mat-progress-bar
						*ngIf="booking.flight.passengersOccupancy <= 75"
						type="determinate"
						color="primary"
						[value]="booking.flight.passengersOccupancy"
					></mat-progress-bar>
					<mat-progress-bar
						*ngIf="booking.flight.passengersOccupancy > 100"
						type="determinate"
						color="warn"
						[value]="booking.flight.passengersOccupancy"
					>
					</mat-progress-bar>
				</button>
			</mat-cell>
		</ng-container>
		<!-- flight period -->
		<ng-container matColumnDef="flightPeriod">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.flightPeriod' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking.flightPeriod }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="flight_balloon">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.flight_balloon' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking.flight?.balloon?.name }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="flight_location">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.flight_location' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking.flight?.location?.name }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="flight_pilot">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.flight_pilot' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking.flight?.pilot?.name }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="totalAmount">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.totalAmount' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				<strong>{{ booking?.passengerCounters?.totalAmount }} </strong>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="paid_amount">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.paid_amount' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				{{ booking?.passengerCounters?.paidAmount }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="openAmount">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.openAmount' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let booking">
				<div *ngIf="booking?.passengerCounters.openAmount !== 0" style="color: red">{{ booking?.passengerCounters?.openAmount }}</div>
				<div *ngIf="booking?.passengerCounters.openAmount === 0">{{ booking?.passengerCounters?.openAmount }}</div>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="up">
			<mat-header-cell *matHeaderCellDef>UP</mat-header-cell>
			<mat-cell *matCellDef="let booking" (click)="$event.stopPropagation()">
				<button *ngIf="booking.bookingCode" mat-icon-button (click)="openUrl(booking.upUrl)" [matTooltip]="booking.bookingCode">
					<mat-icon>launch</mat-icon>
				</button>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="flight_confirmation">
			<mat-header-cell *matHeaderCellDef>
				{{ 'BOOKINGS.flight_confirmation' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				*matCellDef="let booking"
				(click)="$event.stopPropagation()"
				[satPopoverAnchor]="p"
				(click)="booking.status === 'ADDE' ? p.toggle() : null"
			>
				<sat-popover #p hasBackdrop xAlign="start" yAlign="start">
					<div fxLayout="column" fxLayoutGap="5px" class="sat">
						<button mat-stroked-button (click)="confirmBooking.emit(booking)">
							<mat-icon color="accent">check</mat-icon>
						</button>
						<button mat-stroked-button (click)="confirmMailBooking.emit(booking)">
							<mat-icon color="accent">check</mat-icon><mat-icon>mail</mat-icon>
						</button>
						<button mat-stroked-button (click)="deleteBooking.emit(booking)">
							<mat-icon color="warn">clear</mat-icon>
						</button>
					</div>
				</sat-popover>
				<mat-icon *ngIf="booking.status === 'ADDE'">more_vertical</mat-icon>
				<mat-icon
					*ngIf="mergeCandidatesYellow(booking)"
					style="color: #ffca28"
					matTooltip="{{ 'VALIDATIONS.possible_duplicates' | translate | capitalize }}"
					>warning</mat-icon
				>
				<mat-icon
					*ngIf="mergeCandidatesRed(booking)"
					color="warn"
					matTooltip="{{ 'VALIDATIONS.possible_duplicates_with_flights' | translate | capitalize }}"
					>warning</mat-icon
				>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="mismatchReasons">
			<mat-header-cell fxFlex="160px" *matHeaderCellDef>{{ 'FLIGHTS.mismatchReasons' | translate | capitalize }}</mat-header-cell>
			<mat-cell fxFlex="160px" *matCellDef="let booking" (click)="$event.stopPropagation()">
				<div fxLayout="column">
					<div *ngFor="let reason of booking.mismatchReasons" style="color: red; font-size: 12px">
						<div>{{ reason | translate | capitalize }}</div>
					</div>
				</div>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="messages">
			<mat-header-cell *matHeaderCellDef>{{ 'BOOKINGS.messages' | translate | capitalize }}</mat-header-cell>
			<mat-cell *matCellDef="let booking" (click)="$event.stopPropagation()">
				<div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
					<app-booking-maileventlatest [mailEventLatest]="booking.mailEventLatest"></app-booking-maileventlatest>
					<app-booking-smslatest [smsUnread]="booking.smsUnread" [smsLatest]="booking.smsLatest"></app-booking-smslatest>
				</div>
			</mat-cell>
		</ng-container>

		<!-- action -->
		<ng-container *ngIf="!isMobile" matColumnDef="action">
			<mat-header-cell fxFlex="300px" *matHeaderCellDef>{{ 'APPLICATION.action' | translate | capitalize }}</mat-header-cell>
			<mat-cell fxFlex="300px" *matCellDef="let booking" (click)="$event.stopPropagation()">
				<div fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="center center">
					<button
						*ngIf="(type === 'flight.all' || type === 'flight.onlyMatching') && !(booking?.flight?.id === flightId)"
						mat-raised-button
						color="accent"
						(click)="addBookingsToFlight.emit([booking])"
					>
						{{ 'FLIGHTS.add' | translate | capitalize }}
					</button>
					<button
						*ngIf="
							(type === 'flight.all' || (type === 'flight.onlyMatching' && countAllBookings() > 1)) && !(booking?.flight?.id === flightId)
						"
						mat-stroked-button
						color="none"
						(click)="addBookingsToFlight.emit(bookingsNotOnFlight())"
					>
						{{ 'FLIGHTS.add_all' | translate | capitalize }}&nbsp;({{ countAllBookings() }})
					</button>
					<button
						*ngIf="(type === 'flight.all' || type === 'flight.onlyMatching') && booking?.flight?.id === flightId"
						mat-stroked-button
						disabled
						color="none"
						(click)="addBookingsToFlight.emit(bookings)"
					>
						{{ 'FLIGHTS.already_added' | translate | capitalize }}
					</button>
					<button
						*ngIf="type === 'bookings'"
						mat-raised-button
						color="primary"
						[disabled]="booking.status !== 'ADDE'"
						(click)="inviteBooking.emit(booking)"
					>
						{{ 'FLIGHTS.invite' | translate | capitalize }}
					</button>
					<button
						*ngIf="type === 'bookings'"
						mat-raised-button
						color="primary"
						[disabled]="booking.status === 'CONF'"
						(click)="confirmBookingToFlight.emit(booking)"
					>
						{{ 'FLIGHTS.confirm' | translate | capitalize }}
					</button>
					<button *ngIf="type === 'bookings'" mat-raised-button color="warn" (click)="removeBookingFromFlight.emit(booking)">
						{{ 'FLIGHTS.remove' | translate | capitalize }}
					</button>
					<button *ngIf="type === 'flight.payment'" mat-raised-button color="accent" (click)="showPaymentStatusBooking.emit(booking)">
						{{ 'BOOKINGS.paymentStatus' | translate | capitalize }}
					</button>
				</div>
			</mat-cell>
		</ng-container>
		<!-- action mobile -->
		<ng-container *ngIf="isMobile" matColumnDef="action">
			<mat-header-cell
				[style.padding-right.px]="isXSmall ? 3 : {}"
				style="min-width: 80px; margin-left: 5px"
				*matHeaderCellDef
				fxLayoutAlign="start center"
			>
				{{ 'APPLICATION.action' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				[style.padding-right.px]="isXSmall ? 3 : {}"
				style="min-width: 80px"
				*matCellDef="let booking"
				(click)="$event.stopPropagation()"
				fxLayoutAlign="start center"
			>
				<div fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
					<div [fxLayout]="isMobile ? 'column' : 'row'">
						<button
							*ngIf="(type === 'flight.all' || type === 'flight.onlyMatching') && !(booking?.flight?.id === flightId)"
							mat-raised-button
							color="accent"
							(click)="addBookingsToFlight.emit([booking])"
							[style.margin.px]="isMobile ? 5 : {}"
						>
							{{ 'FLIGHTS.add' | translate | capitalize }}
						</button>
						<button
							*ngIf="
								(type === 'flight.all' || (type === 'flight.onlyMatching' && countAllBookings() > 1)) && !(booking?.flight?.id === flightId)
							"
							mat-stroked-button
							color="none"
							(click)="addBookingsToFlight.emit(bookingsNotOnFlight())"
							[style.margin.px]="isMobile ? 5 : {}"
						>
							{{ 'FLIGHTS.add_all' | translate | capitalize }}&nbsp;({{ countAllBookings() }})
						</button>
						<button
							*ngIf="(type === 'flight.all' || type === 'flight.onlyMatching') && booking?.flight?.id === flightId"
							mat-stroked-button
							disabled
							color="none"
							(click)="addBookingsToFlight.emit(bookings)"
						>
							{{ 'FLIGHTS.already_added' | translate | capitalize }}
						</button>
						<div fxLayout="row">
							<button
								*ngIf="type === 'bookings'"
								mat-mini-fab
								color="primary"
								[disabled]="booking.status !== 'ADDE'"
								(click)="inviteBooking.emit(booking)"
							>
								<mat-icon>textsms</mat-icon>
							</button>
							<button
								*ngIf="type === 'bookings'"
								mat-mini-fab
								color="primary"
								[disabled]="booking.status === 'CONF'"
								(click)="confirmBookingToFlight.emit(booking)"
							>
								<mat-icon>check</mat-icon>
							</button>
							<div fxLayoutAlign="center center">
								<button *ngIf="type === 'bookings'" mat-mini-fab color="warn" (click)="removeBookingFromFlight.emit(booking)">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
						</div>
					</div>
				</div>
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="getDisplayedColumns(); sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: getDisplayedColumns()" (click)="bookingClicked(row)"></mat-row>
	</mat-table>
</div>
