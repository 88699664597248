<h1 mat-dialog-title>{{ 'EVENTS.new_crew_event' | translate | capitalize }} {{ crew?.name }}</h1>
<mat-dialog-content class="mat-typography">
	<p>{{ 'EVENTS.crew_information' | translate | capitalize }}</p>
	<form [formGroup]="eventForm" novalidate autocomplete="off" style="margin: 20px">
		<mat-form-field *ngIf="isOperator">
			<mat-select matInput required placeholder="{{ 'EVENTS.crew' | translate | capitalize }}" formControlName="crewId">
				<mat-option *ngFor="let crew of crewList" [value]="crew.id">
					{{ crew.name }}
				</mat-option>
			</mat-select>
			<!-- <mat-error *ngIf="flightDetailForm.get('balloon').hasError('required')">
                {{ 'VALIDATIONS.required' | translate | capitalize }}
            </mat-error> -->
		</mat-form-field>
		<mat-form-field fxLayout="row">
			<input matInput [matDatepicker]="picker_date" placeholder="{{ 'EVENTS.date' | translate | capitalize }}" formControlName="date" />
			<mat-datepicker-toggle matSuffix [for]="picker_date"></mat-datepicker-toggle>
			<mat-datepicker touchUi="true" #picker_date></mat-datepicker>
			<mat-error *ngIf="eventForm.get('date').hasError('required')">
				{{ 'EVENTS.required' | translate | capitalize }}
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<input
				matInput
				type="text"
				placeholder="{{ 'EVENTS.description' | translate | capitalize }}"
				name="name"
				formControlName="description"
			/>
			<mat-error *ngIf="eventForm.get('description').hasError('required')">
				{{ 'EVENTS.required' | translate | capitalize }}
			</mat-error>
		</mat-form-field>
	</form>
</mat-dialog-content>
<div mat-dialog-actions>
	<button mat-raised-button [disabled]="!eventForm.valid" (click)="save(eventForm)" color="primary">
		{{ 'EVENTS.save' | translate | capitalize }}
	</button>
	<button mat-raised-button (click)="cancel()">{{ 'EVENTS.cancel' | translate | capitalize }}</button>
</div>
