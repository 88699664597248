import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SatPopover } from '@ncstate/sat-popover';
import { Flight, Tag, TagData } from 'src/app/core/graphql/generated/gen-types';

@Component({
	selector: 'app-flight-comments',
	templateUrl: './flight-comments.component.html',
	styleUrls: ['./flight-comments.component.scss'],
})
export class FlightCommentsComponent implements OnInit {
	@Input() flight: Flight;
	formFlightComments: FormGroup;
	selectedTags: TagData[];

	constructor(private formBuilder: FormBuilder, public popover: SatPopover) {}

	ngOnInit(): void {
		if (this.flight) {
			this.formFlightComments = this.formBuilder.group({
				id: this.flight?.id,
				comments: this.flight?.comment,
				save: false,
			});
			this.selectedTags = this.flight?.tagsData;
		}
	}

	cancel(form) {
		form.save = false;
		this.popover.close({ save: form.save, comments: form.comments, tags: this.selectedTags });
	}

	save(form) {
		form.save = true;
		this.popover.close({ save: form.save, comments: form.comments, tags: this.selectedTags });
	}

	setTags(tags: TagData[]): void {
		if (!tags) return;
		this.selectedTags = tags;
	}
}