<h1 style="width: 250px">{{ (tagId ? 'TAGS.edit' : 'TAGS.new_tag') | translate | capitalize }}</h1>
<form [formGroup]="newTagForm" fxLayout="column" fxLayoutAlign="start start">
	<mat-form-field appearance="outline" style="width: 100%">
		<mat-label>{{ 'TAGS.tag_name' | translate | capitalize }}</mat-label>
		<input matInput type="text" formControlName="tag" />
	</mat-form-field>
	<mat-form-field appearance="outline" style="width: 100%">
		<mat-label>{{ 'TAGS.tag_type' | translate | capitalize }}</mat-label>
		<mat-select formControlName="type">
			<mat-option *ngFor="let type of tagTypes" [value]="type">{{ 'COMMUNICATIONS.' + type | translate }}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-checkbox formControlName="dashboard" [checked]="newTagForm?.value?.dashboard">{{ 'TAGS.show_on_dashboard' | translate | capitalize }}</mat-checkbox>
	&nbsp;
	<div>{{ 'TAGS.select_tag_color' | translate | capitalize }} ({{ 'SETTINGS.optional' | translate }})</div>
	<button
		class="button"
		mat-raised-button
		color="accent"
		(click)="r.toggle()"
		[satPopoverAnchor]="r"
		matTooltip="{{ 'REGIONS.change_color' | translate | capitalize }}"
	>
		<mat-icon>bookmark</mat-icon>
	</button>
	<sat-popover #r hasBackdrop xAlign="after" yAlign="center">
		<chrome-picker [control]="colorPickerControl"></chrome-picker>
	</sat-popover>
	<div class="button">
		<div>{{ 'SETTINGS.example' | translate | capitalize }}:</div>
		<mat-chip-list>
			<mat-chip [ngStyle]="newTagForm.controls['backgroundColor'].value ? { 'background-color': newTagForm.controls['backgroundColor'].value, 'color': newTagForm.controls['fontColor'].value } : { 'background-color': '#FFCA28', 'color': 'black' }">
                {{ newTagForm.controls['tag'].value ? newTagForm.controls['tag'].value : ('TAGS.tag_name' | translate | capitalize) }}
            </mat-chip>
		</mat-chip-list>
	</div>
	<button mat-raised-button color="primary" (click)="saveTag()" class="button2" [disabled]="!newTagForm.valid || newTagForm.pristine || isLoading">
		<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
			<div>{{ 'TAGS.save' | translate | capitalize }}</div>
			<mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
		</div>
	</button>
	<button mat-raised-button mat-dialog-close style="width: 100%">{{ 'APPLICATION.close' | translate | capitalize }}</button>
</form>
