import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SatPopover } from '@ncstate/sat-popover';
import moment from 'moment';
import { Booking, FlightPeriod } from 'src/app/core/graphql/generated/gen-types';

@Component({
	selector: 'app-booking-preferred-date',
	templateUrl: './booking-preferred-date.component.html',
	styleUrls: ['./booking-preferred-date.component.scss'],
})
export class BookingPreferredDateComponent implements OnInit {
	@Input() booking: Booking;

	formBookingPreferredDate: FormGroup;
	periods: string[] = Object.values(FlightPeriod);

	constructor(private formBuilder: FormBuilder, public popover: SatPopover) {}

	ngOnInit() {
		this.formBookingPreferredDate = this.formBuilder.group({
			id: this.booking.id,
			flightDate: this.booking.preferredFlights?.[0]?.date,
			flightPeriod: this.booking.preferredFlights?.[0]?.period,
			save: false,
		});
	}

	cancel(form) {
		form.save = false;
		const preferredFlights = [];
		this.popover.close({ save: form.save, preferredFlights });
	}

	save(form) {
		form.save = true;
		const preferredFlights = [];
		preferredFlights.push({ date: moment(form.flightDate).format('YYYY-MM-DD'), period: form.flightPeriod });
		this.popover.close({ save: form.save, preferredFlights });
	}
}
