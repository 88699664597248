import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { FlightService } from 'src/app/core/services/flight/flight.service';
import { MeteoblueService } from 'src/app/core/services/meteoblue/meteoblue.service';

@Component({
	selector: 'app-dialog-skeyes',
	templateUrl: './dialog-skeyes.component.html',
	styleUrls: ['./dialog-skeyes.component.scss'],
})
export class DialogSkeyesComponent implements OnInit {
	full: string;
	flightSlot: string;
	forecastSlot: string;
	loading: boolean = false;
	error: boolean = false;

	forecastDate: string;
	forecastUsername: string;

	skeyesForm: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private meteoblueService: MeteoblueService,
		private flightService: FlightService,
		private formBuilder: FormBuilder
	) {
		this.skeyesForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
		});
	}

	ngOnInit(): void {
		// replace all matches of '\n' with '<br>'
		this.full = this.data.flight.skeyesForecast?.forecast?.full.replace(/\\n/g, '<br>');
		this.forecastDate = this.data.flight.skeyesForecast?.date
			? moment(this.data.flight.skeyesForecast?.date).format('YYYY-MM-DD HH:mm')
			: '';
		this.forecastUsername = this.data.flight.skeyesForecast?.username;
		this.flightSlot = moment(this.data.flight.date).format('YYYY-MM-DD') + ' ' + this.data.flight.period;
		this.forecastSlot = this.data.flight.skeyesForecast?.forecast.slot;
	}

	fetchSkeyesForecast() {
		this.loading = true;
		this.error = false;
		this.full = null;
		this.meteoblueService
			.fetchSkeyesForecast(this.skeyesForm.value.username, this.skeyesForm.value.password, this.data.flight.id)
			.valueChanges.subscribe((result) => {
				this.loading = false;
				if (result.data.fetchSkeyesForecast) {
					this.full = result.data.fetchSkeyesForecast?.forecast?.full?.replace(/\\n/g, '<br>');
					this.forecastDate = moment(result.data.fetchSkeyesForecast?.date).format('YYYY-MM-DD HH:mm');
					this.forecastUsername = result.data.fetchSkeyesForecast?.username;
					// Update the cached flight
					this.flightService.updateCacheSkeyesForecast(this.data.flight.id, result.data.fetchSkeyesForecast);
				} else {
					this.error = true;
				}
			}),
			(error) => {
				this.error = true;
				this.loading = false;
			};
	}
}
