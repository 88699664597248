import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCalendar, MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { FlightService } from 'src/app/core/services/flight/flight.service';
import { DialogTrajectoryComponent } from '../dialog-trajectory/dialog-trajectory.component';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
	selector: 'app-dialog-flight-copy',
	templateUrl: './dialog-flight-copy.component.html',
	styleUrls: ['./dialog-flight-copy.component.scss'],
})
export class DialogFlightCopyComponent implements OnInit {
	flightId: string;
	flight;
	selectedDates: any = [];
	isSaving = false;
	@ViewChild('calendar', { static: true }) calendar: MatCalendar<Date>;
	timezoneOffset = new Date().getTimezoneOffset();

	constructor(
		public dialogRef: MatDialogRef<DialogTrajectoryComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private flightService: FlightService,
		private translate: TranslateService,
		private _dateAdapter: DateAdapter<any>
	) {
		// set locale for date picker
		let lang = this.translate.currentLang;
		if (lang === 'du') lang = 'nl';
		if (lang) this._dateAdapter.setLocale(lang);

		this.flightId = data.flightId;
		this.flight = data.flight;
	}

	ngOnInit(): void {}

	dateClass(): MatCalendarCellClassFunction<any> {
		if (this.selectedDates) {
			return (date: Date): MatCalendarCellCssClasses => {
				const highlightDate = this.selectedDates.find((d) => moment(d).isSame(moment(date), 'day'));
				return highlightDate ? 'special-date' : '';
			};
		}
	}

	copyFlight() {
		this.isSaving = true;
		const destinationDates = this.selectedDates.map((date) => {
			return date.format('YYYY-MM-DD');
		});
		this.flightService.copyFlight(this.flightId, destinationDates, this.timezoneOffset).subscribe(
			(res) => {
				this.isSaving = false;
				this.dialogRef.close();
			},
			(err) => {
				this.isSaving = false;
			}
		);
	}

	cancel() {
		this.dialogRef.close();
	}

	onDateChange(date) {
		const dateIndex = this.selectedDates.findIndex((selectedDate) => moment(selectedDate).isSame(date, 'day'));
		if (dateIndex !== -1) {
			// remove date from selectedDates if it is already part of selectedDates
			this.selectedDates.splice(dateIndex, 1);
		} else {
			// add date to selectedDates
			this.selectedDates.push(date);
			// sort all moments in selectedDates
			this.selectedDates.sort((a, b) => {
				return moment(a).isBefore(b) ? -1 : 1;
			});
		}
		// Change the current month to force the calendar to update.
		this.calendar.updateTodaysDate();
	}
}
