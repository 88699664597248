<!-- no mail -->
<mat-icon *ngIf="!mailEventLatest" style="color: #f5f5f5"><span class="material-symbols-outlined">mail</span></mat-icon>
<!-- processing -->
<mat-icon *ngIf="mailEventLatest?.event === 'processed'" style="color: #ffca28" [matTooltip]="tooltipMailEventLatest()">mail</mat-icon>
<mat-icon *ngIf="mailEventLatest?.event === 'deferred'" style="color: #ffca28" [matTooltip]="tooltipMailEventLatest()">mail</mat-icon>
<!-- problem -->
<mat-icon *ngIf="mailEventLatest?.event === 'dropped'" color="warn" [matTooltip]="tooltipMailEventLatest()">mail</mat-icon>
<mat-icon *ngIf="mailEventLatest?.event === 'bounce'" color="warn" [matTooltip]="tooltipMailEventLatest()">mail</mat-icon>
<mat-icon *ngIf="mailEventLatest?.event === 'blocked'" color="warn" [matTooltip]="tooltipMailEventLatest()">mail</mat-icon>
<!-- delivered -->
<mat-icon *ngIf="mailEventLatest?.event === 'delivered'" style="color: #1faa00" [matTooltip]="tooltipMailEventLatest()">mail</mat-icon>
<!-- open -->
<mat-icon *ngIf="mailEventLatest?.event === 'open'" style="color: #1faa00" [matTooltip]="tooltipMailEventLatest()"
	><span class="material-symbols-outlined">drafts</span></mat-icon
>
<mat-icon *ngIf="mailEventLatest?.event === 'click'" style="color: #1faa00" [matTooltip]="tooltipMailEventLatest()"
	><span class="material-symbols-outlined">drafts</span></mat-icon
>
