import { trigger, transition, style, animate } from '@angular/animations';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserRole } from 'src/app/core/graphql/generated/gen-types';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { FlightService } from 'src/app/core/services/flight/flight.service';
import { SnackBar } from 'src/app/core/utility/snackBar';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-flight-card',
	templateUrl: './flight-card.component.html',
	styleUrls: ['./flight-card.component.scss'],
	animations: [
		trigger('fadeSlideInOut', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(-20px)' }),
				animate('250ms', style({ opacity: 1, transform: 'translateY(0)' })),
			]),
			transition(':leave', [animate('100ms', style({ opacity: 0, transform: 'translateY(-20px)' }))]),
		]),
	],
})
export class FlightCardComponent implements OnInit {
	@Input() flightId: any;
	@Input() userRole: UserRole;
	@Input() draggable: boolean;
	@Input() shareBookings: boolean = true;
	@Output() update = new EventEmitter<number[]>();

	// component guards
	guards = environment.componentGuards.flightCard;
	flight: any;
	flightBookingList: any[];

	showBookings = false;
	invalidData = false;

	flightSub: Subscription;
	bookingSub: Subscription;

	constructor(
		private flightService: FlightService,
		private bookingService: BookingService,
		private router: Router,
		private snackBar: SnackBar
	) {}

	ngOnInit(): void {
		if (this.flightId) {
			this.flightSub = this.flightService.getDashboard(this.flightId).subscribe(
				(flightResult) => {
					this.flight = flightResult.data.flight;
					if (this.flight) this.flightBookingList = [...this.flight.bookings.list];
				},
				(err) => this.snackBar.openSnackBarError(err.message)
			);
		}
	}

	ngOnDestroy(): void {
		if (this.flightSub) this.flightSub.unsubscribe();
		if (this.bookingSub) this.bookingSub.unsubscribe();
	}

	toggleVisibility() {
		if (this.flight && this.flight.id) {
			// this.flightService.get(this.flight.id).subscribe((f) => {
			//   if (f.visibility === 'PRI') {
			//     f.visibility = 'PUB';
			//   } else {
			//     f.visibility = 'PRI';
			//   }
			//   if (f.balloon && f.balloon.id) {
			//     f.balloon = f.balloon.id;
			//   }
			//   if (f.location && f.location.id) {
			//     f.location = f.location.id;
			//   }
			//   if (f.pilot && f.pilot.id) {
			//     f.pilot = f.pilot.id;
			//   }
			//   this.flightService.update(f).subscribe(
			//     (updatedFlight) => (this.flight.visibility = updatedFlight.visibility),
			//     (error) => console.log(error)
			//   );
			// });
		}
	}

	toggleBookings() {
		this.showBookings = !this.showBookings;
	}

	getWindRotationStyle(dir) {
		return {
			transform: 'rotate(' + Math.round(dir + 90) + 'deg)',
		};
	}

	edit() {
		if (this.guards.edit.includes(this.userRole)) {
			this.navigateToFlight(this.flight?.id);
		}
	}

	checkin() {
		let url = '/mobile';
		if (this.flight.id) {
			url = url + '/' + this.flight.id;
		}
		this.router.navigate([url]);
	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			if (isNaN(Number(event.container.id))) {
				transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
				const newFlight = event.container.id;
				const previousFlight = event.previousContainer.id;
				const bookingDragged = event.item.data;
				this.bookingSub = this.bookingService.addAndConfirmBookingsToFlight([bookingDragged], newFlight, previousFlight).subscribe(
					() => {},
					(err) => this.snackBar.openSnackBarError(err.message)
				);
			}
		}
	}

	invalidate() {
		this.invalidData = true;
	}

	navigateToFlight(id?) {
		let url = '/flight';
		if (id) {
			url = url + '/' + id;
		}
		this.router.navigate([url]);
	}
}
