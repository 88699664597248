<h1 mat-dialog-title>{{ 'FLIGHTS.offsets' | translate | capitalize}}</h1>
<mat-dialog-content class="mat-typography">
    <p>{{ 'FLIGHTS.flight_hour_will_be_calculated_based_on_sunset_minus_the_offset_minutes' | translate | capitalize }}</p>
    <form [formGroup]="offsetForm" novalidate autocomplete="off" style="margin: 20px" fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxLayout="row">
                <input type="number" matInput placeholder="{{ 'FLIGHTS.offset_sunrise' | translate | capitalize }}" 
                formControlName="sunrise">
                <mat-hint fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <span fxLayout="row">{{ 'FLIGHTS.sunrise' | translate | capitalize}}: 05:42</span>
                    <span *ngIf="offsetForm.get('sunrise').value >= 0" fxLayout="row">-{{ offsetForm.get('sunrise').value }}</span>
                    <span *ngIf="!(offsetForm.get('sunrise').value >= 0)" fxLayout="row">+{{ getAbs(offsetForm.get('sunrise').value) }}</span>
                </mat-hint>
            </mat-form-field>
            <mat-form-field fxLayout="row">
                <input matInput readonly="true" placeholder="{{ 'FLIGHTS.hour' | translate | capitalize }}" [value]="hourSunrise">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field fxLayout="row">
                <input type="number" matInput placeholder="{{ 'FLIGHTS.offset_sunset' | translate | capitalize }}" 
                formControlName="sunset">
                <mat-hint fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                    <span fxLayout="row">{{ 'FLIGHTS.sunset' | translate | capitalize}}: 21:08</span>
                    <span *ngIf="offsetForm.get('sunset').value >= 0" fxLayout="row">-{{ offsetForm.get('sunset').value }}</span>
                    <span *ngIf="!(offsetForm.get('sunset').value >= 0)" fxLayout="row">+{{ getAbs(offsetForm.get('sunset').value) }}</span>
                </mat-hint>
            </mat-form-field>
            <mat-form-field fxLayout="row">
                <input matInput readonly="true" placeholder="{{ 'FLIGHTS.hour' | translate | capitalize }}" [value]="hourSunset">
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-raised-button [disabled]="!offsetForm.valid" (click)="save()" color="primary">{{ 'APPLICATION.save' | translate | capitalize}}</button>
    <button mat-raised-button (click)="cancel()">{{ 'APPLICATION.cancel' | translate | capitalize}}</button>
</div>