import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '..';
import {
	AddAndConfirmBookingsToFlightDocument,
	AddBookingsToFlightDocument,
	AddPassengersDocument,
	BookingCountersDocument,
	BookingFilter,
	BookingInput,
	BookingsPreferredDateDocument,
	ConfirmBookingToFlightDocument,
	CreateBookingDocument,
	DeleteBookingDocument,
	DeletePassengerDocument,
	GetBookingDocument,
	GetFlightDocument,
	GetHistoricalBookingsDocument,
	GetMobileFlightDocument,
	GetUpcomingBookingsDocument,
	InviteBookingsToFlightDocument,
	LocationInput,
	MarkBookingPaidDocument,
	OrderByBooking,
	OrderDirection,
	PassengerCountersDocument,
	PassengerInput,
	PreferredDateInput,
	RemoveBookingFromFlightDocument,
	SaveBookingDocument,
	SavePassengerDocument,
	UpdateBookingCommentsDocument,
	BookingConversationsDocument,
	DashboardBookingsDocument,
	GetBookingLogsDocument,
	GetFirstBookingWithFlightDocument,
	GetBookingMailsDocument,
	BookingPaymentInput,
	SaveBookingPaymentDocument,
	PassengerPaymentInput,
	SavePassengerPaymentDocument,
	PaymentType,
	UpdatePassengerPaymentStatusDocument,
	PaymentStatus,
	UpdateBookingPaymentDocument,
	BookingsToConfirmDocument,
	GetBookingsOfFlightDocument,
	GetBookingForPaymentFormDocument,
	Tag,
	UpdateBookingTagsDocument,
	GetTagsOfTypeWithCountersDocument,
	TagType,
	GetUpcomingBookingsIsDocument,
	GetHistoricalBookingsIsDocument,
	Booking,
	MergeBookingsDocument,
	DeleteLogDocument,
	PreferredFlightInput,
	UpdateBookingPreferredFlightsDocument,
} from '../../graphql/generated/gen-types';

@Injectable({
	providedIn: 'root',
})
export class BookingService {
	currentUser;

	//InfiniteScroll
	private _storedBookings: Booking[] = [];
	private _bookingTableScrollY: number = 0;

	private saveBookingClick = new Subject();
	saveBookingClick$ = this.saveBookingClick.asObservable();

	private dropBooking = new BehaviorSubject(false);
	dropBooking$ = this.dropBooking.asObservable();

	private refreshSearchResults = new Subject();
	refreshSearchResults$ = this.refreshSearchResults.asObservable();

	constructor(private apollo: Apollo, private authenticationService: AuthenticationService) {
		this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}
	// communicate between list and table to refresh the search results
	public refreshSearchResultsClick() {
		this.refreshSearchResults.next();
	}

	public dropBookingRefetch() {
		this.dropBooking.next(false);
	}

	public getBooking(id: any): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetBookingDocument,
			variables: {
				id,
			},
			fetchPolicy: 'cache-and-network'
		});
	}
	public getFirstBookingWithFlight(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetFirstBookingWithFlightDocument,
		});
	}

	public getBookingLogs(id: any): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetBookingLogsDocument,
			variables: {
				id,
			},
			fetchPolicy: 'network-only',
		});
	}

	public getBookingMails(id: any): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetBookingMailsDocument,
			variables: {
				id,
			},
			fetchPolicy: 'network-only',
		});
	}

	public getUpcomingBookings(
		orderBy?: OrderByBooking,
		orderDirection?: OrderDirection,
		limit?: number,
		cursor?: string,
		filter?: string[],
		bookingFilter?: BookingFilter,
		flightId?: string,
		flightOnlyMatching?: boolean
	): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetUpcomingBookingsDocument,
			variables: {
				orderBy,
				orderDirection,
				limit,
				cursor,
				filter,
				bookingFilter,
				flightId,
				flightOnlyMatching,
			},
			fetchPolicy: 'network-only',
		});
	}

	public getHistoricalBookings(
		orderBy?: OrderByBooking,
		orderDirection?: OrderDirection,
		limit?: number,
		cursor?: string,
		filter?: string[],
		bookingFilter?: BookingFilter
	): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetHistoricalBookingsDocument,
			variables: {
				orderBy,
				orderDirection,
				limit,
				cursor,
				filter,
				bookingFilter,
			},
			fetchPolicy: 'network-only',
		});
	}

	public getBookingsIS(
		type: string,
		limit?: number,
		cursor?: string,
		filter?: string[],
		orderBy?: OrderByBooking,
		orderDirection?: OrderDirection,
		bookingFilter?: BookingFilter,
		flightId?: string,
		flightOnlyMatching?: boolean,
		endAt?: boolean
	): QueryRef<any> {
		let showUpcoming = true;
		let bookingQuery = GetUpcomingBookingsIsDocument;
		if (type === 'historical') {
			bookingQuery = GetHistoricalBookingsIsDocument;
			showUpcoming = false;
		}
		return this.apollo.watchQuery({
			query: bookingQuery,
			variables: {
				showUpcoming: showUpcoming,
				limit: limit,
				cursor: cursor,
				filter: filter,
				orderBy: orderBy,
				orderDirection: orderDirection,
				bookingFilter: bookingFilter,
				flightId: flightId,
				flightOnlyMatching: flightOnlyMatching,
				endAt: endAt,
			},
			fetchPolicy: 'no-cache',
		});
	}

	public getDashboardBookings(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: DashboardBookingsDocument,
			fetchPolicy: 'network-only',
		});
	}

	public getBookingsPreferredDate(preferredDateInput: PreferredDateInput): QueryRef<any> {
		return this.apollo.watchQuery({
			query: BookingsPreferredDateDocument,
			variables: {
				preferredDateInput,
			},
			fetchPolicy: 'network-only',
		});
	}

	public passengerCounters(passengers: any): QueryRef<any> {
		return this.apollo.watchQuery({
			query: PassengerCountersDocument,
			variables: {
				passengers,
			},
		});
	}

	public bookingCounters(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: BookingCountersDocument,
			fetchPolicy: 'no-cache',
		});
	}

	public bookingsToConfirm(): QueryRef<any> {
		return this.apollo.watchQuery({
			query: BookingsToConfirmDocument,
			fetchPolicy: 'no-cache',
			pollInterval: 5000,
		});
	}

	public bookingConversations(offset: number): QueryRef<any> {
		return this.apollo.watchQuery({
			query: BookingConversationsDocument,
			variables: {
				offset,
			},
		});
	}
	public save() {
		this.saveBookingClick.next();
	}

	public saveBooking(bookingInput: BookingInput, bookingId: string, locationInput: LocationInput): Observable<any> {
		if (bookingId) {
			return this.apollo
				.mutate<any>({
					mutation: SaveBookingDocument,
					variables: {
						bookingInput,
						bookingId,
						locationInput,
					},
					refetchQueries: [
						{
							query: GetBookingDocument,
							variables: {
								id: bookingId,
							},
						},
					],
				})
				.pipe(map((result: any) => result.data));
		} else {
			return this.apollo
				.mutate<any>({
					mutation: SaveBookingDocument,
					variables: { bookingInput, bookingId, locationInput },
				})
				.pipe(map((result: any) => result.data));
		}
	}

	public delete(bookingId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: DeleteBookingDocument,
				variables: {
					bookingId,
				},
			})
			.pipe(map((result: any) => result.data));
	}
	public deleteLog(bookingId: string, logId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: DeleteLogDocument,
				variables: {
					bookingId,
					logId,
				},
				refetchQueries: [
					{
						query: GetBookingLogsDocument,
						variables: {
							id: bookingId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public merge(bookingId1: string, bookingId2: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: MergeBookingsDocument,
				variables: {
					bookingIdFrom: bookingId1,
					bookingIdTo: bookingId2,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: {
							id: bookingId2,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public createBooking(flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: CreateBookingDocument,
				variables: {
					flightId,
				},
				refetchQueries: [
					{
						query: GetMobileFlightDocument,
						variables: { flightId },
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public savePassenger(passengerInput: PassengerInput, passengerId: string, bookingId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SavePassengerDocument,
				variables: {
					passengerInput,
					passengerId,
					bookingId,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: {
							id: bookingId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public saveMobilePassenger(passengerInput: PassengerInput, passengerId: string, bookingId: string, flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SavePassengerDocument,
				variables: {
					passengerInput,
					passengerId,
					bookingId,
				},
				refetchQueries: [
					{
						query: GetMobileFlightDocument,
						variables: {
							flightId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public deletePassenger(passengerId: string, bookingId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: DeletePassengerDocument,
				variables: {
					passengerId,
					bookingId,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: {
							id: bookingId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public addPassengers(bookingId: string, count: number) {
		return this.apollo
			.mutate<any>({
				mutation: AddPassengersDocument,
				variables: {
					bookingId,
					count,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: {
							id: bookingId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public updateBookingComments(bookingId: string, comments: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateBookingCommentsDocument,
				variables: {
					bookingId,
					comments,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: { id: bookingId },
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public updatePreferredFlights(bookingId: string, preferredFlights: [PreferredFlightInput]): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateBookingPreferredFlightsDocument,
				variables: {
					bookingId,
					preferredFlights,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: { id: bookingId },
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public updateBookingTags(bookingId: string, tags: Tag[] = []): Observable<any> {
		const tagIds: string[] = tags.map((tag) => tag.id);
		return this.apollo
			.mutate<any>({
				mutation: UpdateBookingTagsDocument,
				variables: {
					bookingId: bookingId,
					tags: tagIds,
				},
				refetchQueries: [
					{
						query: GetTagsOfTypeWithCountersDocument,
						variables: {
							tagType: TagType.Booking,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public markBookingPaid(bookingId: string) {
		return this.apollo
			.mutate<any>({
				mutation: MarkBookingPaidDocument,
				variables: {
					bookingId,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: {
							id: bookingId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public addBookingsToFlight(bookings: [any], flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: AddBookingsToFlightDocument,
				variables: {
					bookings: bookings.map((booking) => booking.id),
					flightId,
				},
				refetchQueries: [
					{
						query: GetFlightDocument,
						variables: {
							flightId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public inviteBookingsToFlight(bookings: [any], flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: InviteBookingsToFlightDocument,
				variables: {
					bookings: bookings.map((booking) => booking.id),
					flightId,
				},
				refetchQueries: [
					{
						query: GetFlightDocument,
						variables: {
							flightId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public addAndConfirmBookingsToFlight(bookings: [any], flightId: string, previousFlightId: string = ''): Observable<any> {
		this.dropBooking.next(true);
		return this.apollo
			.mutate<any>({
				mutation: AddAndConfirmBookingsToFlightDocument,
				variables: {
					bookings: bookings.map((booking) => booking.id),
					flightId,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public confirmBookingToFlight(bookingId: string, flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: ConfirmBookingToFlightDocument,
				variables: {
					bookingId,
				},
				refetchQueries: [
					{
						query: GetFlightDocument,
						variables: {
							flightId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public removeBookingFromFlight(bookingId: string, flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: RemoveBookingFromFlightDocument,
				variables: {
					bookingId,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: {
							id: bookingId,
						},
					},
					{
						query: GetFlightDocument,
						variables: {
							flightId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public removeFlightFromBookingDetail(bookingId: string, flightId: string): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: RemoveBookingFromFlightDocument,
				variables: {
					bookingId,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: {
							id: bookingId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public saveBookingPayments(bookingId: string, bookingPaymentInput: BookingPaymentInput): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SaveBookingPaymentDocument,
				variables: {
					bookingId,
					bookingPaymentInput,
				},
			})
			.pipe(map((result: any) => result.data));
	}

	public savePassengerPayments(bookingId: string, passengerId: string, passengerPaymentInput: PassengerPaymentInput): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: SavePassengerPaymentDocument,
				variables: {
					bookingId,
					passengerId,
					passengerPaymentInput,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: {
							id: bookingId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public updatePassengerPaymentStatus(bookingId: string, passengerId: string, paymentStatus: PaymentStatus): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdatePassengerPaymentStatusDocument,
				variables: {
					bookingId,
					passengerId,
					paymentStatus,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: {
							id: bookingId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public updateBookingPayment(bookingId: string, paymentType: PaymentType): Observable<any> {
		return this.apollo
			.mutate<any>({
				mutation: UpdateBookingPaymentDocument,
				variables: {
					bookingId,
					paymentType,
				},
				refetchQueries: [
					{
						query: GetBookingDocument,
						variables: {
							id: bookingId,
						},
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	public getBookingsOfFlight(flightId: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetBookingsOfFlightDocument,
			variables: {
				flightId,
			},
		});
	}

	public getBookingForPaymentForm(id: string): QueryRef<any> {
		return this.apollo.watchQuery({
			query: GetBookingForPaymentFormDocument,
			variables: {
				id,
			},
		});
	}
}
