import { Component, Input, OnInit } from '@angular/core';
import { CrewAvailability } from 'src/app/core/graphql/generated/gen-types';
import { Availabilities } from '../../../features/crew/availabilities';

@Component({
	selector: 'app-crew-badge',
	templateUrl: './crew-badge.component.html',
	styleUrls: ['./crew-badge.component.scss'],
})
export class CrewBadgeComponent implements OnInit {
	@Input() crew;
	@Input() groundCrews;
	@Input() availability: CrewAvailability;
	@Input() disableTooltip = false;
	@Input() showAvailability = false;
	@Input() highlight = false;

	availabilities = Availabilities;
	groundCrewNames: string;

	NOTIFY = 30;
	crewAvailability = CrewAvailability;

	constructor() {}

	ngOnInit(): void {
		// only first word of every name of every crew in groundCrews
		this.groundCrewNames = this.groundCrews?.map((p) => p.crew.name.split(' ')[0]).join(', ');
	}

	getAvailability(availability: CrewAvailability) {
		const foundAvailability = this.availabilities.find((p) => p.availability === availability);
		return foundAvailability;
	}
}
