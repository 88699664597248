import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { EventType } from 'src/app/core/graphql/generated/gen-types';
import { CrewService } from 'src/app/core/services/crew/crew.service';
import { EventService } from 'src/app/core/services/event/event.service';
import { SnackBar } from 'src/app/core/utility/snackBar';

@Component({
	selector: 'app-dialog-event-crew',
	templateUrl: './dialog-event-crew.component.html',
	styleUrls: ['./dialog-event-crew.component.scss'],
})
export class DialogEventCrewComponent implements OnInit {
	eventForm: FormGroup;
	holidays = [];
	language: string;
	spinner = false;
	isOperator: boolean = true;
	crew;
	crewList;

	constructor(
		public dialogRef: MatDialogRef<DialogEventCrewComponent>,
		private fb: FormBuilder,
		private eventService: EventService,
		private translate: TranslateService,
		private crewService: CrewService,
		private snackBar: SnackBar,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _dateAdapter: DateAdapter<any>
	) {
		// set locale for date picker
		let lang = this.translate.currentLang;
		if (lang === 'du') lang = 'nl';
		if (lang) this._dateAdapter.setLocale(lang);
	}

	ngOnInit(): void {
		this.isOperator = this.data.isOperator;
		const date = this.data.date ? this.data.date : new Date();

		this.eventForm = this.fb.group({
			crewId: this.data.crewId,
			date: [date, Validators.required],
			description: ['', Validators.required],
		});

		if (!this.isOperator && this.data.crewId) {
			// set crewId and get the name in case of CREW
			this.crewService.get(this.data.crewId).valueChanges.subscribe(
				(result) => {
					this.crew = result.data.crew;
				},
				(err) => this.snackBar.openSnackBarError(err.message)
			);
		} else {
			// get all crew to select the name in case of OPERATOR
			this.crewService.getList().valueChanges.subscribe(
				(result) => {
					this.crewList = result.data.crews;
					if (this.crewList.length > 0) {
						this.eventForm.get('crewId').patchValue(this.crewList[0].id);
					}
				},
				(err) => this.snackBar.openSnackBarError(err.message)
			);
		}

		this.language = this.translate.getDefaultLang();
	}

	cancel() {
		this.dialogRef.close({ status: 'cancel' });
	}

	save(eventForm) {
		if (eventForm.valid) {
			const newEvent = {
				date: moment(eventForm.get('date').value).format('YYYY-MM-DD'),
				description: eventForm.get('description').value,
				type: EventType.Cre,
				crewId: eventForm.get('crewId').value,
			};
			this.eventService.save(newEvent).subscribe(
				(event) => {
					this.dialogRef.close({ status: 'save', event });
				},
				(err) => this.snackBar.openSnackBarError(err.message)
			);
		}
	}
}
