import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ContactLanguage, FlightPeriod, SmsRepeat, SmsType } from 'src/app/core/graphql/generated/gen-types';
import { BookingService } from 'src/app/core/services/booking/booking.service';
import { CrewService } from 'src/app/core/services/crew/crew.service';
import { FlightService } from 'src/app/core/services/flight/flight.service';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import { SmsService } from 'src/app/core/services/sms/sms.service';
import { TenantSettingService } from 'src/app/core/services/tenantSetting/tenant-setting.service';
import { SMS_CHARS } from 'src/app/core/utility/conversion';
import { TranslateCustomService } from 'src/app/core/utility/translate-custom.service';

@Component({
	selector: 'app-dialog-sms',
	templateUrl: './dialog-sms.component.html',
	styleUrls: ['./dialog-sms.component.scss'],
})
export class DialogSmsComponent {
	smsDetailForm: FormGroup;
	smsMessage = { to: null, smsNL: '', smsFR: '', smsEN: '', smsDE: '', role: '' };
	smsTemplate: any;
	smsFooter: any;
	smsUp = false;
	upEnabled = false;
	smsReminder = false;
	smsCancel = false;
	sendTo: string;
	settings: any;
	chipList: string[] = [];
	chipLists = { nl: [], fr: [], en: [], de: [] };
	languages = {
		nl: 0,
		fr: 0,
		en: 0,
		de: 0,
	};
	langSelected = 'nl';
	dutch: 'nl' | 'du' = 'nl';
	chipListSelected;
	exampleUp = 'https://up.quickrelease.aero/?l=U2FsdG**************';
	LANG = ['nl', 'en', 'fr', 'de'];
	positiveReply;
	negativeReply;
	footerEnabled: boolean = true;

	constructor(
		public dialogRef: MatDialogRef<DialogSmsComponent>,
		public translate: TranslateService,
		public translateCustom: TranslateCustomService,
		public flightService: FlightService,
		public bookingService: BookingService,
		public crewService: CrewService,
		public settingsService: SettingsService,
		private tenantSettingService: TenantSettingService,
		private smsService: SmsService,
		private formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		if (this.translate.currentLang === 'du') this.dutch = 'du';

		this.smsDetailForm = this.formBuilder.group({
			to: '',
			smsNL: '',
			smsFR: '',
			smsEN: '',
			smsDE: '',
			role: '',
			up: false,
		});

		// check up active or not // check footerEnabled
		this.tenantSettingService.getTenantSetting().valueChanges.subscribe((result) => {
			if (result.data.tenantSetting.upEnabled) {
				this.upEnabled = true;
			}
			this.footerEnabled = result.data.tenantSetting.operatorSettings?.sms?.footerEnabled;
		});

		this.smsService.getSmsTemplates(this.data.smsTemplate).valueChanges.subscribe((result) => {
			if (result.data.getSmsTemplates.length > 0) {
				this.settings = result.data.getSmsTemplates[0];
				this.updateTemplate();
			}
		});

		if (this.data.flight.location) {
			this.chipList.push(this.data.flight.location.city);
			this.chipList.push(this.data.flight.location.address);
			if (this.data.flight.location.description !== '') {
				this.chipList.push(this.data.flight.location.description);
			}
			this.LANG.forEach((lang) => {
				this.chipLists[lang].push(this.data.flight.location.city);
				this.chipLists[lang].push(this.data.flight.location.address);
				if (this.data.flight.location.description !== '') {
					this.chipLists[lang].push(this.data.flight.location.description);
				}
			});
		}
		if (this.data.flight.date) {
			this.chipList.push(this.formatDate(this.data.flight.date, 'nl-BE'));
			this.LANG.forEach((lang) => {
				this.chipLists[lang].push(this.formatDate(this.data.flight.date, 'nl-BE'));
			});
		}
		if (this.data.flight.hour) {
			this.chipList.push(this.formatTime(this.data.flight.hour));
			this.LANG.forEach((lang) => {
				this.chipLists[lang].push(this.formatTime(this.data.flight.hour));
			});
		}

		this.smsMessage.to = this.data.to + ' (' + this.data.role + ')';
		this.smsMessage.smsNL = this.smsTemplate ? this.smsTemplate['nl'] : '';
		this.smsMessage.smsFR = this.smsTemplate ? this.smsTemplate['fr'] : '';
		this.smsMessage.smsEN = this.smsTemplate ? this.smsTemplate['en'] : '';
		this.smsMessage.smsDE = this.smsTemplate ? this.smsTemplate['de'] : '';
		this.smsMessage.role = this.data.role;
		// this.updateForm();
	}

	formatChip(chip: string) {
		if (chip.length > 16) {
			return chip.substr(0, 16) + '...';
		} else {
			return chip;
		}
	}

	updateTemplate() {
		this.smsTemplate = {
			nl: this.parseTemplate(this.settings.template.nl, this.data.flight, 'nl'),
			fr: this.parseTemplate(this.settings.template.fr, this.data.flight, 'fr'),
			en: this.parseTemplate(this.settings.template.en, this.data.flight, 'en'),
			de: this.parseTemplate(this.settings.template.de, this.data.flight, 'de'),
		};
		switch (this.data.smsTemplate) {
			case SmsType.LocationContactSms: {
				this.smsFooter = '';
				this.sendTo = 'send';
				this.smsMessage.role = 'location';
				const locationContactLanguage = this.data.flight.location.contact.language;
				this.languages[locationContactLanguage] += 1;
				break;
			}
			case SmsType.PilotInvitationSms: {
				this.smsFooter = {
					nl: this.translateCustom.translate('FLIGHTS.answer_pilot_yes_no', this.dutch),
					fr: this.translateCustom.translate('FLIGHTS.answer_pilot_yes_no', 'fr'),
					en: this.translateCustom.translate('FLIGHTS.answer_pilot_yes_no', 'en'),
					de: this.translateCustom.translate('FLIGHTS.answer_pilot_yes_no', 'de'),
				};
				this.positiveReply = {
					nl: this.translateCustom.translate('FLIGHTS.pilot_yes', this.dutch),
					fr: this.translateCustom.translate('FLIGHTS.pilot_yes', 'fr'),
					en: this.translateCustom.translate('FLIGHTS.pilot_yes', 'en'),
					de: this.translateCustom.translate('FLIGHTS.pilot_yes', 'de'),
				};
				this.negativeReply = {
					nl: this.translateCustom.translate('FLIGHTS.pilot_no', this.dutch),
					fr: this.translateCustom.translate('FLIGHTS.pilot_no', 'fr'),
					en: this.translateCustom.translate('FLIGHTS.pilot_no', 'en'),
					de: this.translateCustom.translate('FLIGHTS.pilot_no', 'de'),
				};
				this.sendTo = 'send';
				this.smsMessage.role = 'pilot';
				const pilotLanguage = this.data.flight.pilot.crew.language;
				this.languages[pilotLanguage] += 1;
				break;
			}
			case SmsType.CrewInvitationSms: {
				this.smsFooter = {
					nl: this.translateCustom.translate('FLIGHTS.answer_crew_yes_no', this.dutch),
					fr: this.translateCustom.translate('FLIGHTS.answer_crew_yes_no', 'fr'),
					en: this.translateCustom.translate('FLIGHTS.answer_crew_yes_no', 'en'),
					de: this.translateCustom.translate('FLIGHTS.answer_crew_yes_no', 'de'),
				};
				this.positiveReply = {
					nl: this.translateCustom.translate('BOOKINGS.crew_yes', this.dutch),
					fr: this.translateCustom.translate('BOOKINGS.crew_yes', 'fr'),
					en: this.translateCustom.translate('BOOKINGS.crew_yes', 'en'),
					de: this.translateCustom.translate('BOOKINGS.crew_yes', 'de'),
				};
				this.negativeReply = {
					nl: this.translateCustom.translate('BOOKINGS.crew_no', this.dutch),
					fr: this.translateCustom.translate('BOOKINGS.crew_no', 'fr'),
					en: this.translateCustom.translate('BOOKINGS.crew_no', 'en'),
					de: this.translateCustom.translate('BOOKINGS.crew_no', 'de'),
				};
				this.sendTo = 'send';
				this.smsMessage.role = 'crew';

				if (this.data.all) {
					this.data.flight.groundCrews.forEach((c) => {
						if (c.status === 'UNK') {
							this.languages[c.crew.language] += 1;
						}
					});
				} else {
					const lang = this.data.flight.groundCrews.find((c) => c.crew.id === this.data.crew).crew.language;
					this.languages[lang] += 1;
				}
				break;
			}
			case SmsType.PassengerInvitationSms: {
				this.smsFooter = {
					nl: this.translateCustom.translate('BOOKINGS.answer_flight_yes_no', this.dutch),
					fr: this.translateCustom.translate('BOOKINGS.answer_flight_yes_no', 'fr'),
					en: this.translateCustom.translate('BOOKINGS.answer_flight_yes_no', 'en'),
					de: this.translateCustom.translate('BOOKINGS.answer_flight_yes_no', 'de'),
				};
				this.positiveReply = {
					nl: this.translateCustom.translate('BOOKINGS.flight_yes', this.dutch),
					fr: this.translateCustom.translate('BOOKINGS.flight_yes', 'fr'),
					en: this.translateCustom.translate('BOOKINGS.flight_yes', 'en'),
					de: this.translateCustom.translate('BOOKINGS.flight_yes', 'de'),
				};
				this.negativeReply = {
					nl: this.translateCustom.translate('BOOKINGS.flight_no', this.dutch),
					fr: this.translateCustom.translate('BOOKINGS.flight_no', 'fr'),
					en: this.translateCustom.translate('BOOKINGS.flight_no', 'en'),
					de: this.translateCustom.translate('BOOKINGS.flight_no', 'de'),
				};
				// set languages
				if (this.data.all) {
					this.data.flight.bookings.list.forEach((b) => {
						if (b.status === 'ADDE') {
							this.languages[b.contactLanguage] += 1;
						}
					});
				} else {
					const lang = this.data.flight.bookings.list.find((b) => b.id === this.data.booking).contactLanguage;
					this.languages[lang] += 1;
				}
				this.sendTo = 'send_to_pax';
				this.smsMessage.role = 'PAX';
				break;
			}
			case SmsType.ReminderSms: {
				this.smsReminder = true;
				// set languages
				if (this.data.flight.pilot) {
					const pilotLanguage = this.data.flight.pilot.crew.language;
					this.languages[pilotLanguage] += 1;
				}
				if (this.data.flight.groundCrews) {
					this.data.flight.groundCrews.forEach((c) => {
						if (c.status === 'CON') {
							this.languages[c.crew.language] += 1;
						}
					});
				}
				if (this.data.flight.bookings) {
					this.data.flight.bookings.list.forEach((b) => {
						if (b.status === 'CONF') {
							this.languages[b.contactLanguage] += 1;
						}
					});
				}

				this.sendTo = 'send_to_pax';
				this.smsMessage.role = 'PAX';
				break;
			}
			case SmsType.CancelSms: {
				this.smsCancel = true;
				// set languages
				if (this.data.flight.pilot) {
					const pilotLanguage = this.data.flight.pilot.crew.language;
					this.languages[pilotLanguage] += 1;
				}
				this.data.flight.groundCrews.forEach((c) => {
					if (c.status === 'CON') {
						this.languages[c.crew.language] += 1;
					}
				});
				this.data.flight.bookings.list.forEach((b) => {
					if (b.status === 'CONF') {
						this.languages[b.contactLanguage] += 1;
					}
				});
				this.sendTo = 'send_to_pax';
				this.smsMessage.role = 'PAX';
				break;
			}
		}

		// reset footer if not enabled
		if (!this.footerEnabled) {
			this.smsFooter = {
				nl: '',
				fr: '',
				en: '',
				de: '',
			};
		}

		this.smsMessage.smsNL = this.smsTemplate['nl'];
		this.smsMessage.smsFR = this.smsTemplate['fr'];
		this.smsMessage.smsEN = this.smsTemplate['en'];
		this.smsMessage.smsDE = this.smsTemplate['de'];
		this.updateForm();
		// make sure language selected is available
		if (this.languages.nl > 0) {
			this.updateLang('nl');
			return;
		} else if (this.languages.fr > 0) {
			this.updateLang('fr');
			return;
		} else if (this.languages.en > 0) {
			this.updateLang('en');
			return;
		} else if (this.languages.de > 0) {
			this.updateLang('de');
			return;
		}
	}

	getSMSSelected(lang: string) {
		switch (lang) {
			case 'nl':
				return this.smsDetailForm.controls['smsNL'].value;
			case 'fr':
				return this.smsDetailForm.controls['smsFR'].value;
			case 'en':
				return this.smsDetailForm.controls['smsEN'].value;
			case 'de':
				return this.smsDetailForm.controls['smsDE'].value;
		}
	}

	characterCount(text: string, lang: string) {
		let fullText = text;
		if (this.smsFooter) {
			fullText = fullText + this.smsFooter[lang];
		}
		if (this.smsDetailForm.get('up').value) {
			fullText = `${fullText} ${this.exampleUp} ******************************`;
		}
		return SMS_CHARS - (fullText.length - (this.smsCount(text, lang) - 1) * SMS_CHARS);
	}

	smsCount(text: string, lang: string) {
		let fullText = text;
		if (this.smsFooter) {
			fullText = text + this.smsFooter[lang];
		}
		if (this.smsDetailForm.get('up').value) {
			fullText = `${fullText} ${this.exampleUp} ******************************`;
		}
		return Math.floor((fullText.length - 1) / SMS_CHARS) + 1;
	}

	parseTemplate(smsTemplate: string, flight, lang: string) {
		let customLang = lang;
		if (this.translate.currentLang === 'du' && customLang === 'nl') customLang = 'du';
		const reBalloon = /{{ balloon }}/gi;
		const reHour = /{{ hour }}/gi;
		const reCity = /{{ city }}/gi;
		const rePeriod = /{{ period }}/gi;
		const reDate = /{{ date }}/gi;
		const reAddress = /{{ address }}/gi;
		const reLocation = /{{ location }}/gi;
		const reCountry = /{{ country }}/gi;

		let smsText = smsTemplate;
		// try {
		// 	smsText = JSON.parse(smsTemplate)[lang];
		// } catch (e) {}

		smsText = smsText.replace(reBalloon, flight?.balloon?.name);
		smsText = smsText.replace(reHour, this.formatTime(flight.hour));
		smsText = smsText.replace(reCity, flight.location.city);
		smsText = smsText.replace(rePeriod, this.translateCustom.translate('FLIGHTS.' + flight.period, customLang));
		smsText = smsText.replace(reDate, this.formatDate(flight.date, lang));
		smsText = smsText.replace(reAddress, flight.location.address);
		smsText = smsText.replace(reLocation, flight.location.description);
		smsText = smsText.replace(reCountry, flight.location.country);
		return smsText;
	}

	formatDate(date, lang) {
		const localMoment = moment(date);
		return localMoment.locale(lang).format('dd D MMM');
	}

	formatToday() {
		return moment().format('dd D/MM/YY');
	}

	formatTime(time: string) {
		return moment(time).format('HH:mm');
	}

	updateForm() {
		this.smsDetailForm.patchValue(this.smsMessage);
	}

	onNoClick(): void {
		this.dialogRef.close('cancel');
	}

	updateLang(lang) {
		this.langSelected = lang;
		this.chipListSelected = this.chipLists[lang];
	}

	getNumberOfLanguages() {
		let count = 0;
		if (this.languages.nl > 0) {
			count += 1;
		}
		if (this.languages.fr > 0) {
			count += 1;
		}
		if (this.languages.en > 0) {
			count += 1;
		}
		if (this.languages.de > 0) {
			count += 1;
		}
		return count;
	}

	chipClick(smsText: string, lang: string) {
		switch (lang) {
			case 'nl':
				this.smsMessage.smsNL = this.smsDetailForm.controls['smsNL'].value.trim() + ' ' + smsText;
				break;
			case 'fr':
				this.smsMessage.smsFR = this.smsDetailForm.controls['smsFR'].value.trim() + ' ' + smsText;
				break;
			case 'en':
				this.smsMessage.smsEN = this.smsDetailForm.controls['smsEN'].value.trim() + ' ' + smsText;
				break;
			case 'de':
				this.smsMessage.smsDE = this.smsDetailForm.controls['smsDE'].value.trim() + ' ' + smsText;
				break;
		}
		this.updateForm();
	}

	cancel() {
		// if (this.smsCancel) {
		// 	this.flightService.updateFlightStatus(this.data.flight).subscribe();
		// }
		this.dialogRef.close('cancel');
	}

	confirm(smsMessage: any) {
		this.sendSMS(smsMessage, false);
		this.dialogRef.close('confirm');
	}

	confirmToAll(smsMessage: any) {
		this.sendSMS(smsMessage, true);
		this.dialogRef.close('confirm');
	}

	sendSMS(smsMessage: any, toAll: boolean) {
		let smsText = '';
		let positiveReply = '';
		let negativeReply = '';
		// invite PILOT
		if (smsMessage.role === 'pilot') {
			const pilotLanguage = this.data.flight.pilot.crew.language;
			switch (pilotLanguage) {
				case 'nl':
					smsText = smsMessage.smsNL;
					positiveReply = this.positiveReply.nl;
					negativeReply = this.negativeReply.nl;
					break;
				case 'fr':
					smsText = smsMessage.smsFR;
					positiveReply = this.positiveReply.fr;
					negativeReply = this.negativeReply.fr;
					break;
				case 'en':
					smsText = smsMessage.smsEN;
					positiveReply = this.positiveReply.en;
					negativeReply = this.negativeReply.en;
					break;
				case 'de':
					smsText = smsMessage.smsDE;
					positiveReply = this.positiveReply.de;
					negativeReply = this.negativeReply.de;
					break;
			}
			const smsInput = {
				text: smsText,
				up: null,
				positiveReply,
				negativeReply,
				repeat: SmsRepeat.Single,
			};
			this.smsService.sendCrewSms(this.data.flight.pilot.crew.id, smsInput).subscribe();
		}
		// invite 1 CREW
		if (smsMessage.role === 'crew' && !this.data.all) {
			if (this.languages.nl > 0) {
				smsText = smsMessage.smsNL;
				positiveReply = this.positiveReply.nl;
				negativeReply = this.negativeReply.nl;
			}
			if (this.languages.fr > 0) {
				smsText = smsMessage.smsFR;
				positiveReply = this.positiveReply.fr;
				negativeReply = this.negativeReply.fr;
			}
			if (this.languages.en > 0) {
				smsText = smsMessage.smsEN;
				positiveReply = this.positiveReply.en;
				negativeReply = this.negativeReply.en;
			}
			if (this.languages.de > 0) {
				smsText = smsMessage.smsDE;
				positiveReply = this.positiveReply.de;
				negativeReply = this.negativeReply.de;
			}
			const smsInput = {
				text: smsText,
				up: null,
				positiveReply,
				negativeReply,
				repeat: SmsRepeat.Single,
			};
			this.smsService.sendCrewSms(this.data.crew, smsInput).subscribe();
		}
		// invite ALL CREW
		if (smsMessage.role === 'crew' && this.data.all) {
			if (this.languages.nl > 0) {
				smsText = smsMessage.smsNL;
				positiveReply = this.positiveReply.nl;
				negativeReply = this.negativeReply.nl;
				this.data.flight.groundCrews.forEach((c) => {
					if (c.status === 'UNK' && c.crew.language === 'nl') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(c.crew.id, smsInput).subscribe();
					}
				});
			}
			if (this.languages.fr > 0) {
				smsText = smsMessage.smsFR;
				positiveReply = this.positiveReply.fr;
				negativeReply = this.negativeReply.fr;
				this.data.flight.groundCrews.forEach((c) => {
					if (c.status === 'UNK' && c.crew.language === 'fr') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(c.crew.id, smsInput).subscribe();
					}
				});
			}
			if (this.languages.en > 0) {
				smsText = smsMessage.smsEN;
				positiveReply = this.positiveReply.en;
				negativeReply = this.negativeReply.en;
				this.data.flight.groundCrews.forEach((c) => {
					if (c.status === 'UNK' && c.crew.language === 'en') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(c.crew.id, smsInput).subscribe();
					}
				});
			}
			if (this.languages.de > 0) {
				smsText = smsMessage.smsDE;
				positiveReply = this.positiveReply.de;
				negativeReply = this.negativeReply.de;
				this.data.flight.groundCrews.forEach((c) => {
					if (c.status === 'UNK' && c.crew.language === 'de') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(c.crew.id, smsInput).subscribe();
					}
				});
			}
		}
		// Notify location contact
		if (smsMessage.role === 'location') {
			const lang = this.data.flight.location.contact.language;
			switch (lang) {
				case 'nl':
					smsText = `${smsMessage.smsNL}`;
					break;
				case 'fr':
					smsText = `${smsMessage.smsFR}`;
					break;
				case 'en':
					smsText = `${smsMessage.smsEN}`;
					break;
				case 'de':
					smsText = `${smsMessage.smsDE}`;
					break;
			}
			const smsInput = {
				text: smsText,
				up: false,
				positiveReply,
				negativeReply,
				repeat: SmsRepeat.Single,
			};
			this.smsService.sendLocationSMS(this.data.flight.location.id, this.data.flight.id, smsInput).subscribe();
		}
		// invite 1 PAX
		if (smsMessage.role === 'PAX' && !this.data.all) {
			const lang = this.data.flight.bookings.list.find((b) => b.id === this.data.booking).contactLanguage;
			switch (lang) {
				case 'nl':
					smsText = `${smsMessage.smsNL} ${this.smsFooter.nl}`;
					positiveReply = this.positiveReply.nl;
					negativeReply = this.negativeReply.nl;
					break;
				case 'fr':
					smsText = `${smsMessage.smsFR} ${this.smsFooter.fr}`;
					positiveReply = this.positiveReply.fr;
					negativeReply = this.negativeReply.fr;
					break;
				case 'en':
					smsText = `${smsMessage.smsEN} ${this.smsFooter.en}`;
					positiveReply = this.positiveReply.en;
					negativeReply = this.negativeReply.en;
					break;
				case 'de':
					smsText = `${smsMessage.smsDE} ${this.smsFooter.de}`;
					positiveReply = this.positiveReply.de;
					negativeReply = this.negativeReply.de;
					break;
			}
			const smsInput = {
				text: smsText,
				up: smsMessage.up,
				positiveReply,
				negativeReply,
				repeat: SmsRepeat.Single,
			};
			this.smsService.sendBookingSMS(this.data.booking, smsInput).subscribe();
		}
		// invite ALL PAX
		if (smsMessage.role === 'PAX' && this.data.all) {
			if (!this.smsReminder && !this.smsCancel) {
				if (this.languages.nl > 0) {
					smsText = `${smsMessage.smsNL} ${this.smsFooter.nl}`;
					positiveReply = this.positiveReply.nl;
					negativeReply = this.negativeReply.nl;
					this.data.flight.bookings.list.forEach((b) => {
						if (b.status === 'ADDE' && b.contactLanguage === 'nl') {
							const smsInput = {
								text: smsText,
								up: smsMessage.up,
								positiveReply,
								negativeReply,
								repeat: SmsRepeat.Single,
							};
							this.smsService.sendBookingSMS(b.id, smsInput).subscribe();
						}
					});
				}
				if (this.languages.fr > 0) {
					smsText = `${smsMessage.smsFR} ${this.smsFooter.fr}`;
					positiveReply = this.positiveReply.fr;
					negativeReply = this.negativeReply.fr;
					this.data.flight.bookings.list.forEach((b) => {
						if (b.status === 'ADDE' && b.contactLanguage === 'fr') {
							const smsInput = {
								text: smsText,
								up: smsMessage.up,
								positiveReply,
								negativeReply,
								repeat: SmsRepeat.Single,
							};
							this.smsService.sendBookingSMS(b.id, smsInput).subscribe();
						}
					});
				}
				if (this.languages.en > 0) {
					smsText = `${smsMessage.smsEN} ${this.smsFooter.en}`;
					positiveReply = this.positiveReply.en;
					negativeReply = this.negativeReply.en;
					this.data.flight.bookings.list.forEach((b) => {
						if (b.status === 'ADDE' && b.contactLanguage === 'en') {
							const smsInput = {
								text: smsText,
								up: smsMessage.up,
								positiveReply,
								negativeReply,
								repeat: SmsRepeat.Single,
							};
							this.smsService.sendBookingSMS(b.id, smsInput).subscribe();
						}
					});
				}
				if (this.languages.de > 0) {
					smsText = `${smsMessage.smsDE} ${this.smsFooter.de}`;
					positiveReply = this.positiveReply.de;
					negativeReply = this.negativeReply.de;
					this.data.flight.bookings.list.forEach((b) => {
						if (b.status === 'ADDE' && b.contactLanguage === 'de') {
							const smsInput = {
								text: smsText,
								up: smsMessage.up,
								positiveReply,
								negativeReply,
								repeat: SmsRepeat.Single,
							};
							this.smsService.sendBookingSMS(b.id, smsInput).subscribe();
						}
					});
				}
			}
		}
		// remind or cancel ALL PAX
		if (this.smsReminder || this.smsCancel) {
			if (this.languages.nl > 0) {
				smsText = smsMessage.smsNL;
				this.data.flight.bookings.list.forEach((b) => {
					if (b.status === 'CONF' && b.contactLanguage === 'nl') {
						const smsInput = {
							text: smsText,
							up: smsMessage.up,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendBookingSMS(b.id, smsInput).subscribe();
					}
				});
			}
			if (this.languages.fr > 0) {
				smsText = smsMessage.smsFR;
				this.data.flight.bookings.list.forEach((b) => {
					if (b.status === 'CONF' && b.contactLanguage === 'fr') {
						const smsInput = {
							text: smsText,
							up: smsMessage.up,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendBookingSMS(b.id, smsInput).subscribe();
					}
				});
			}
			if (this.languages.en > 0) {
				smsText = smsMessage.smsEN;
				this.data.flight.bookings.list.forEach((b) => {
					if (b.status === 'CONF' && b.contactLanguage === 'en') {
						const smsInput = {
							text: smsText,
							up: smsMessage.up,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendBookingSMS(b.id, smsInput).subscribe();
					}
				});
			}
			if (this.languages.de > 0) {
				smsText = smsMessage.smsDE;
				this.data.flight.bookings.list.forEach((b) => {
					if (b.status === 'CONF' && b.contactLanguage === 'de') {
						const smsInput = {
							text: smsText,
							up: smsMessage.up,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendBookingSMS(b.id, smsInput).subscribe();
					}
				});
			}
		}
		// remind or cancel ALL PAX & ALL CREW
		if ((this.smsReminder || this.smsCancel) && toAll) {
			if (this.languages.nl > 0) {
				smsText = smsMessage.smsNL;
				this.data.flight.groundCrews.forEach((c) => {
					if (c.status === 'CON' && c.crew.language === 'nl') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(c.crew.id, smsInput).subscribe();
					}
				});
				if (this.data.flight.pilot) {
					if (this.data.flight.pilot.crew.language === 'nl') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(this.data.flight.pilot.crew.id, smsInput).subscribe();
					}
				}
			}
			if (this.languages.fr > 0) {
				smsText = smsMessage.smsFR;
				this.data.flight.groundCrews.forEach((c) => {
					if (c.status === 'CON' && c.crew.language === 'fr') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(c.crew.id, smsInput).subscribe();
					}
				});
				if (this.data.flight.pilot) {
					if (this.data.flight.pilot.crew.language === 'fr') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(this.data.flight.pilot.crew.id, smsInput).subscribe();
					}
				}
			}
			if (this.languages.en > 0) {
				smsText = smsMessage.smsEN;
				this.data.flight.groundCrews.forEach((c) => {
					if (c.status === 'CON' && c.crew.language === 'en') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(c.crew.id, smsInput).subscribe();
					}
				});
				if (this.data.flight.pilot) {
					if (this.data.flight.pilot.crew.language === 'en') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(this.data.flight.pilot.crew.id, smsInput).subscribe();
					}
				}
			}
			if (this.languages.de > 0) {
				smsText = smsMessage.smsDE;
				this.data.flight.groundCrews.forEach((c) => {
					if (c.status === 'CON' && c.crew.language === 'de') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(c.crew.id, smsInput).subscribe();
					}
				});
				if (this.data.flight.pilot) {
					if (this.data.flight.pilot.crew.language === 'de') {
						const smsInput = {
							text: smsText,
							up: null,
							positiveReply,
							negativeReply,
							repeat: SmsRepeat.Single,
						};
						this.smsService.sendCrewSms(this.data.flight.pilot.crew.id, smsInput).subscribe();
					}
				}
			}
		}
		if (this.smsCancel) {
			// this.flightService.updateFlightStatus(this.data.flight).subscribe();
		}
	}
}
