<div fxLayout="column" fxLayoutGap="5px">
	<button *ngFor="let booking of bookings" mat-stroked-button (click)="navigateToBooking(booking.id)">
		<span [ngStyle]="findFilter(booking) ? { 'background-color': 'yellow' } : {}">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" *ngIf="booking.status != 'CONF'">
                <span>({{booking.passengers?.length}}) {{ booking.contactName }}</span>
                <mat-icon [color]="booking.iconColor">{{ booking.icon }}</mat-icon>
            </div>
            <span *ngIf="booking.status === 'CONF'">({{booking.passengers?.length}}) {{ booking.contactName }}</span>
            <span *ngIf="booking.status === 'COMP'">({{booking.passengers?.length}}) {{ booking.contactName }}</span>
		</span>
	</button>
</div>
