import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InputOperatorSettingsTrajectoryProfile, TrajectoryProfile } from 'src/app/core/graphql/generated/gen-types';
import { TenantSettingService } from 'src/app/core/services/tenantSetting/tenant-setting.service';
import { SnackBar } from 'src/app/core/utility/snackBar';

@Component({
	selector: 'app-dialog-trajectory',
	templateUrl: './dialog-trajectory.component.html',
	styleUrls: ['./dialog-trajectory.component.scss'],
})
export class DialogTrajectoryComponent {
	profileDetailForm: FormGroup;
	levels = [
		{ hPa: 0, alt: 'GL', height: 5, color: '#9400D3' },
		{ hPa: 1000, alt: '100m', height: 10, color: '#0000FF' },
		{ hPa: 975, alt: '330m (1000ft)', height: 15, color: '#00FF00' },
		{ hPa: 950, alt: '500m', height: 20, color: '#FFFF00' },
		{ hPa: 925, alt: '1000m', height: 40, color: '#FF7F00' },
		{ hPa: 800, alt: '2000m', height: 60, color: '#FF0000' },
		{ hPa: 700, alt: '3000m', height: 80, color: '#FF00FF' },
		{ hPa: 600, alt: '4200m', height: 100, color: '#00FFFF' },
		{ hPa: 500, alt: '5500m', height: 120, color: '#000000' },
	];
	flightProfile: InputOperatorSettingsTrajectoryProfile[] = [];
	flightId: string;

	constructor(
		public dialogRef: MatDialogRef<DialogTrajectoryComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private formBuilder: FormBuilder,
		private tenantSettingService: TenantSettingService,
		private snackBar: SnackBar
	) {
		data.trajectoryProfile.forEach((profile) => {
			this.flightProfile.push({ duration: profile.duration, level: profile.level });
		});
		this.flightId = data.flightId;
		this.profileDetailForm = this.formBuilder.group({
			duration: 30,
			level: 0,
		});
	}

	getAlt(hPa: number) {
		return this.levels.find((level) => level.hPa === hPa).alt;
	}
	getHeight(hPa: number) {
		return this.levels.find((level) => level.hPa === hPa).height;
	}

	getColor(hPa: number) {
		return this.levels.find((level) => level.hPa === hPa).color;
	}

	onDelete(i) {
		// delete the trajectory from the flightProfile
		this.flightProfile.splice(i, 1);
	}

	onSubmit() {
		// add the new trajectory to the flightProfile
		this.flightProfile.push({
			duration: this.profileDetailForm.value.duration,
			level: this.profileDetailForm.value.level,
		});
	}
	updateTrajectoryCustom() {
		// save the trajectoryProfile in operatorSettings
		this.tenantSettingService.updateTenantOperatorSettingsTrajectoryProfile(this.flightProfile, this.flightId).subscribe(
			(result) => {
				this.dialogRef.close();
			},
			(err) => {
				(err) => this.snackBar.openSnackBarError(err.message);
			}
		);
	}
}
