<div style="display: flex; justify-content:left; align-items: center; gap: 10px; margin-bottom: 20px;">
	<mat-icon>group</mat-icon>
	<span style="font-size: large;">{{ 'FLIGHTS.pilot_and_crew' | translate | capitalize }}</span>
</div>
<mat-card class="mat-elevation-z8" style="margin-bottom: 20px; background-color: #f5f5f5">
	<mat-card-header>
		<mat-card-title>{{ 'FLIGHTS.PILOTANDCREW.selected' | translate | capitalize }}</mat-card-title>
		<mat-card-subtitle>{{ flight?.balloon?.name }} - {{ flight?.date | formatDate: 'ddd DD MMM YYYY' }} {{ flight?.period === 'EVE' ? '' : '(AM)' }}</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div style="margin-left: 20px;">
			<app-crew-badge [crew]="selectedPilot"></app-crew-badge>
			{{ displayCrew(selectedCrew) }}
		</div>
	</mat-card-content>
	<mat-card-actions align="end">
		<button mat-raised-button [disabled]="!dirty" (click)="save()" color="primary">
			{{ 'APPLICATION.save' | translate | capitalize }}
		</button>
		<button mat-raised-button (click)="cancel()">{{ 'APPLICATION.cancel' | translate | capitalize }}</button>
	</mat-card-actions>
</mat-card>
<mat-card style="margin-bottom: 20px">
	<mat-card-header>
		<mat-card-title>{{ 'FLIGHTS.PILOTANDCREW.most_used' | translate | capitalize }}</mat-card-title>
		<mat-card-subtitle>{{ 'FLIGHTS.PILOTANDCREW.this_is_the_most_used_pilot_and_crew' | translate | capitalize }}</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<div style="color: darkgray; font-style: italic; margin-left: 20px">
				<app-crew-badge [crew]="mostPopularPilot"></app-crew-badge>
				{{ displayCrew(mostPopularCrew) }}
		</div>
	</mat-card-content>
	<mat-card-actions align="end">
		<button mat-raised-button (click)="selectMostPopular('pilot')" color="blank">
			{{ 'FLIGHTS.PILOTANDCREW.accept' | translate | capitalize }} {{ 'ACTORS.pilot' | translate | capitalize }}
		</button>
		<button mat-raised-button (click)="selectMostPopular('crew')" color="blank">
			{{ 'FLIGHTS.PILOTANDCREW.accept' | translate | capitalize }} {{ 'ACTORS.crew' | translate | capitalize }}
		</button>
		<button mat-raised-button (click)="selectMostPopular()" color="accent">
			{{ 'FLIGHTS.PILOTANDCREW.accept' | translate | capitalize }}
		</button>
	</mat-card-actions>
</mat-card>
<mat-card style="margin-bottom: 20px">
	<mat-card-header>
		<mat-card-title>{{ 'FLIGHTS.PILOTANDCREW.all_pilots_and_crew' | translate | capitalize }}</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<mat-tab-group dynamicHeight="false" style="height: 500px; scroll-behavior: auto" (selectedTabChange)="filterValue = ''">
			<mat-tab [label]="'ACTORS.pilots' | translate | capitalize">
				<ng-container *ngIf="availablePilots">
					<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" style="margin-top: 20px">
						<!-- search field -->
						<mat-form-field style="width: 100%" appearance="outline">
							<mat-label> <mat-icon>search</mat-icon>{{ 'APPLICATION.search' | translate | capitalize }} </mat-label>
							<input mat-line matInput (keyup)="applyFilter($event)" [value]="filterValue" />
							<mat-icon *ngIf="filterValue !== ''" matSuffix (click)="filterValue = ''" color="primary">clear </mat-icon>
						</mat-form-field>
						<div
							*ngFor="let pilot of filter(availablePilots, filterValue)"
							fxLayout="row"
							fxLayoutAlign="space-between center"
							style="width: 100%"
							fxLayoutGap="10px"
						>
							<app-crew-badge [crew]="pilot" showAvailability="true" [availability]="pilot.availability"></app-crew-badge>
							<div style="display: flex; justify-content: start; align-items: center; gap: 10px">
								<mat-icon *ngIf="pilot.mostPopular" style="color: #daa520"><span class="material-symbols-outlined"> stars </span></mat-icon>
								<button *ngIf="pilot.otherFlight" mat-stroked-button disabled="true">
									{{ pilot.otherFlight?.balloon.name }}
								</button>
								<button *ngIf="pilot.id !== selectedPilot?.id" mat-raised-button (click)="selectPilot(pilot)" color="accent">
									{{ 'FLIGHTS.PILOTANDCREW.select' | translate | capitalize }}
								</button>
								<button *ngIf="pilot.id === selectedPilot?.id" mat-icon-button (click)="removePilot()" color="warn">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
						</div>
					</div>
				</ng-container>
			</mat-tab>
			<mat-tab [label]="'ACTORS.ground_crew' | translate | capitalize">
				<ng-container *ngIf="availableCrew" style="margin-top: 20px">
					<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" style="margin-top: 20px">
						<!-- search field -->
						<mat-form-field style="width: 100%" appearance="outline">
							<mat-label> <mat-icon>search</mat-icon>{{ 'APPLICATION.search' | translate | capitalize }} </mat-label>
							<input mat-line matInput (keyup)="applyFilter($event)" [value]="filterValue" />
							<mat-icon *ngIf="filterValue !== ''" matSuffix (click)="filterValue = ''" color="primary">clear </mat-icon>
						</mat-form-field>
						<div
							*ngFor="let crew of filter(availableCrew, filterValue)"
							fxLayout="row"
							fxLayoutAlign="space-between center"
							style="width: 100%"
						>
							<app-crew-badge [crew]="crew" [availability]="crew.availability" [showAvailability]="true"></app-crew-badge>
							<div style="display: flex; justify-content: start; align-items: center; gap: 10px">
								<mat-icon *ngIf="crew.mostPopular" style="color: #daa520"><span class="material-symbols-outlined"> stars </span> </mat-icon>
								<button *ngIf="crew.otherFlight" mat-stroked-button disabled="true">
									{{ crew.otherFlight?.balloon.name }}
								</button>
								<button *ngIf="!crewSelected(crew)" mat-raised-button (click)="selectCrew(crew)" color="accent">
									{{ 'FLIGHTS.PILOTANDCREW.select' | translate | capitalize }}
								</button>
								<button *ngIf="crewSelected(crew)" mat-icon-button (click)="removeCrew(crew)" color="warn">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
						</div>
					</div>
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</mat-card-content>
</mat-card>
