<div *ngIf="flightCount > 0" style="margin-bottom: 5px">
    <div fxLayout="column" fxLayoutGap="5px">
        <mat-card data-tour="card-date" [ngClass]="getCardClass()" (click)="toggleFlights()" class="mat-elevation-z8">
            <mat-card-content>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between start">
                    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex="100px">
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span [ngStyle]="today ? {'font-weight': 'bold', 'font-size' : 'x-large'} : {'font-size' : 'x-large'}">
                                {{ date | formatDate:'dd DD' | capitalize }}
                            </span>
                            <span style="font-size: small">/{{ date | formatDate:'M' }}</span>
                        </div>
                        <mat-card-subtitle fxLayout="row" *ngIf="event">
                            {{ event| capitalize }}
                        </mat-card-subtitle>
                    </div>
                    <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="start center">
                            <div *ngFor="let flight of morningFlights" fxLayout="row wrap" fxLayoutAlign="start center">
                                <mat-icon [color]="'accent'">fiber_manual_record</mat-icon>
                                <span style="font-size: small">{{ flight.balloon?.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="start center">
                            <div *ngFor="let flight of eveningFlights" fxLayout="row wrap" fxLayoutAlign="start center">
                                <mat-icon [color]="'warn'">fiber_manual_record</mat-icon>
                                <span style="font-size: small">{{ flight.balloon?.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="space-between center" (click)="$event.stopPropagation()">
                        <button *ngIf="(flightCount > 0) && guards.plan.includes(userRole)" mat-icon-button (click)="plan()"><mat-icon matTooltip="Plan">drag_indicator</mat-icon></button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <div *ngIf="!isXSmall" fxLayout="column" fxLayoutGap="5px">
            <div *ngIf="showFlights && morningFlights?.length > 0" @fadeSlideInOut fxLayout="row wrap" fxLayoutGap="5px grid">
                <div *ngFor="let flight of morningFlights" style="width: 392px">
                    <app-flight-card [flightId]="flight?.id" [userRole]="userRole" [shareBookings]="shareBookings"></app-flight-card>
                </div>
            </div>
            <div *ngIf="showFlights && eveningFlights?.length > 0" @fadeSlideInOut fxLayout="row wrap" fxLayoutGap="5px grid">
                <div *ngFor="let flight of eveningFlights" style="width: 392px">
                    <app-flight-card [flightId]="flight?.id" [userRole]="userRole" [shareBookings]="shareBookings"></app-flight-card>
                </div>
            </div>
        </div>
        <div *ngIf="isXSmall" fxLayout="column" fxLayoutGap="5px">
            <div *ngIf="showFlights && morningFlights?.length > 0" fxLayout="column" fxLayoutGap="5px">
                <div *ngFor="let flight of morningFlights" style="max-width: 590px">
                    <app-flight-card [flightId]="flight?.id" [userRole]="userRole" [shareBookings]="shareBookings"></app-flight-card>
                </div>
            </div>
            <div *ngIf="showFlights && eveningFlights?.length > 0" fxLayout="column" fxLayoutGap="5px">
                <div *ngFor="let flight of eveningFlights" style="max-width: 590px">
                    <app-flight-card [flightId]="flight?.id" [userRole]="userRole" [shareBookings]="shareBookings"></app-flight-card>
                </div>
            </div>
        </div>
    </div>
    </div>