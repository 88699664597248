<div fxLayout="column" fxLayoutGap="2px">
    <mat-card style="height: 140px" class="mat-elevation-z8" data-tour="card-flight">
    <mat-card-content>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start start" >
            <div fxLayout="column" fxLayoutGap="5px" style="width: 100%; height: 140px">
                <div fxLayout="row" fxLayoutAlign="space-between center" style="font-size: medium; font-weight: bold;" (click)="edit()">
                    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start end">
                        <mat-icon [color]="flight?.icon?.color">{{ flight?.icon?.sign }}</mat-icon>
                        <span >{{ flight?.date | formatDate:'dd DD MMM' }}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start end" (click)="$event.stopPropagation()">
                        <button *ngIf="flight?.visibility === 'PUB'" mat-icon-button (click)="toggleVisibility()"><mat-icon color="primary">visibility</mat-icon></button>
                        <button *ngIf="flight?.visibility === 'PRI'" mat-icon-button (click)="toggleVisibility()"><mat-icon color="warn">visibility_off</mat-icon></button>                        
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span style="font-size: medium;">{{ flight?.balloon?.name | uppercase }}</span>
                        <mat-icon [color]="flight?.period === 'MOR'? 'accent' : 'warn' ">fiber_manual_record</mat-icon>
                    </div>
                </div>
                <mat-progress-bar fxLayout="row" mode="determinate" [value]="flight?.occupancy" [color]="flight?.period === 'MOR' ? 'accent' : 'primary'"></mat-progress-bar>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <div fxLayout="row" style="font-size: small;">
                            {{ flight?.date | formatDate:'HH:mm' }}
                        </div>
                        <div fxLayout="row" style="font-size: small;" fxLayoutAlign="start center">
                            {{ flight?.meteo?.tempTakeoff ? flight?.meteo?.tempTakeoff : '??' }}°C - {{ flight?.meteo?.windGl ? flight?.meteo?.windGl : '??' }}kt
                            <mat-icon [ngStyle]="getWindRotationStyle(flight?.meteo?.windGlDirection)">arrow_right_alt</mat-icon>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <button [disabled]="true" mat-stroked-button style="background-color: #1AA194; color: white">
                            {{ flight?.location?.name | uppercase }}
                        </button>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                    <button mat-stroked-button data-tour="card-flight-bookings" [disabled]="flight?.bookings?.count === 0 || !shareBookings || !guards.bookings.includes(userRole)" (click)="toggleBookings()">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <div *ngIf="flight?.passengerCounters.passengersCountAdded !== 0" fxLayoutAlign="start center" fxLayoutGap="5px">
                                <span>{{ flight?.passengerCounters.passengersCountAdded }}</span>
                                <mat-icon color="warn" class="transform-small">assignment</mat-icon>
                            </div>
                            <div *ngIf="flight?.passengerCounters.passengersCountInvited !== 0" fxLayoutAlign="start center" fxLayoutGap="5px">
                                <span>{{ flight?.passengerCounters.passengersCountInvited }}</span>
                                <mat-icon color="warn" class="transform-small">sms</mat-icon>
                            </div>
                            <div fxLayoutAlign="start center" fxLayoutGap="5px">
                                <span>{{ flight?.passengerCounters.passengersCountConfirmed }}/{{ flight?.balloon?.capacity }}</span>
                                <mat-icon color="primary" class="transform-small">assignment_turned_in</mat-icon>
                            </div>
                        </div>
                    </button>
                    
                    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                        <span>{{ flight?.liftSpare }}kg</span>
                        <mat-icon *ngIf="flight?.liftSpare >= 0">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="!(flight?.liftSpare >= 0)" color="warn" >block</mat-icon>
                    </div>
                    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                        <span *ngIf="flight?.pilot?.crew?.name">{{ flight?.pilot?.crew?.name.substr(0, 10) }}</span>
                        <mat-icon [color]="flight?.pilot ? 'primary' : 'warn'">emoji_people</mat-icon>
                    </div>
                </div>
    
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between end" style="width: 40px; height: 140px">
                <button *ngIf="guards.checkIn.includes(userRole)"
                    mat-icon-button
                    (click)="checkin()" 
                    [disabled]="flight?.status !== 'REA'">
                    <mat-icon [color]="flight?.status !== 'REA' ? '' : flight?.passengersCheckedIn ? 'accent' : 'warn'" >desktop_windows</mat-icon>
                </button>
                <button mat-menu-item *ngIf="guards.edit.includes(userRole)"
                    [matMenuTriggerFor]="dropdownMenu"
                    [matMenuTriggerData]="{flight: flight}">
                    <mat-icon>more_vertical</mat-icon>
                </button>
                <button *ngIf="flight?.bookings?.count > 0" mat-icon-button (click)="toggleBookings()">
                    <mat-icon *ngIf="!showBookings" color="primary">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="showBookings" color="primary">keyboard_arrow_up</mat-icon>
                </button> 
            </div>
        </div>
    </mat-card-content>
    </mat-card>
    <div *ngIf="draggable" cdkDropList [id]="flight?.id" [cdkDropListData]="flightBookingList" (cdkDropListDropped)="drop($event)">
        <div  fxLayout="column" fxLayoutGap="2px">
            <div *ngFor="let booking of flightBookingList" cdkDrag [cdkDragData]="booking">
                <app-booking-card [bookingId]="booking?.id" [draggable]="draggable"></app-booking-card>
            </div>
            <mat-list dense *ngIf="flightBookingList?.length === 0">
                <mat-list-item>
                  <h3 *ngIf="flight?.id" fxLayoutAlign="center center" cdkDragHandle mat-line>{{ 'CALENDAR.no_bookings_yet' | translate | capitalize }}</h3>
                  <h3 *ngIf="flight?.id === -1" fxLayoutAlign="center center" cdkDragHandle mat-line>{{ 'CALENDAR.drag_and_drop_reservations_here_to_remove_from_this_flight' | translate | capitalize }}</h3>
                  <h3 *ngIf="flight?.id === 0" fxLayoutAlign="center center" mat-line>{{ 'CALENDAR.good_job' | translate | uppercase }}</h3>
                  <h3 *ngIf="flight?.id === 0" fxLayoutAlign="center center" mat-line>{{ 'CALENDAR.all_bookings_are_added_to_a_flight' | translate | capitalize }}</h3>
                  <h3 *ngIf="flight?.id === 0" fxLayoutAlign="center center" mat-line>{{ 'CALENDAR.you_cannot_drop_bookings_here' | translate | capitalize }}</h3>
                </mat-list-item>
              </mat-list>
        </div>
    </div>
    <div *ngIf="showBookings && !draggable" @fadeSlideInOut fxLayout="column" fxLayoutGap="2px">
        <div *ngFor="let booking of flight?.bookings.list">
            <app-booking-card [bookingId]="booking?.id" [draggable]="draggable"></app-booking-card>
        </div>
    </div>
</div>



<mat-menu #dropdownMenu xPosition="before">
    <ng-template matMenuContent let-flight="flight">
        <button mat-menu-item *ngIf="guards.checkIn.includes(userRole)"
            (click)="checkin()" 
            [disabled]="flight?.status !== 'REA'">
            <mat-icon [color]="flight?.status !== 'REA' ? '' : flight?.passengersCheckedIn ? 'accent' : 'warn'" >desktop_windows</mat-icon>
            <span>{{ 'FLIGHTS.checkin' | translate | capitalize}}</span>
        </button>
        <button mat-menu-item 
            (click)="edit()" 
            [disabled]="!guards.edit.includes(userRole)">
            <mat-icon color="primary">edit</mat-icon>
            <span>{{ 'FLIGHTS.update' | translate | capitalize}}</span>
        </button>
    </ng-template>
</mat-menu>