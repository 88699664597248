import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { EmailService } from 'src/app/core/services/email/email.service';
import { FileService } from 'src/app/core/services/file/file.service';
import { SnackBar } from 'src/app/core/utility/snackBar';

@Component({
	selector: 'app-dialog-email',
	templateUrl: './dialog-email.component.html',
	styleUrls: ['./dialog-email.component.scss'],
})
export class DialogEmailComponent {
	mailDetailForm: FormGroup;
	mailMessage;
	upEnabled: boolean;
	langSelected = 'nl';
	selectedIndex: number = 0;
	readOnly = true;

	bodyEditor = ClassicEditor;
	editorData: string;
	config = {
		toolbar: [
			'Heading',
			'|',
			'Bold',
			'Italic',
			'Link',
			'bulletedList',
			'numberedList',
			'|',
			'indent',
			'outdent',
			'|',
			// 'imageUpload',
			'blockQuote',
			'insertTable',
			'undo',
			'redo',
		],
	};
	mailAttachments: {
		filename: string;
		reference: string;
		progress: number;
		size: number;
		valid: boolean;
	}[] = [];
	maxFileSize = 1024 * 1024 * 10;
	maxAttachmentSize = 1024 * 1024 * 10;

	constructor(
		public dialogRef: MatDialogRef<DialogEmailComponent>,
		private formBuilder: FormBuilder,
		private fileService: FileService,
		private emailService: EmailService,
		private snackBar: SnackBar,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		// handle incoming this.data here
		if (this.data && this.data.emails) {
			this.mailMessage = this.data.emails[0];
			this.readOnly = this.data?.readOnly;
			this.data.emails[0].attachments.forEach((attachment) => {
				this.mailAttachments.push({
					filename: attachment.filename,
					reference: attachment.reference,
					progress: 100,
					size: 0,
					valid: true,
				});
			});
		}

		this.mailDetailForm = this.formBuilder.group({
			flightId: null,
			locationId: null,
			bookingId: null,
			crewId: null,
			from: null,
			replyTo: null,
			to: null,
			subject: null,
			html: null,
			text: null,
		});

		this.updateForm();
	}

	selectMail(i: number) {
		this.data.emails[this.selectedIndex] = this.mailDetailForm.value;
		this.selectedIndex = i;
		this.mailMessage = this.data.emails[i];
		this.updateForm();
	}

	updateForm() {
		this.mailDetailForm.patchValue(this.mailMessage);
		this.editorData = this.mailDetailForm.controls['html'].value;
	}

	cancel() {
		this.dialogRef.close({ status: 'cancel' });
	}

	send() {
		const mail = this.mailDetailForm.value;
		mail.html = this.convertText2Html(mail.html);

		// adding attachments
		this.removeInvalidAttachments();
		mail.attachments = this.mailAttachments.map((mailAttachment) => {
			return {
				filename: mailAttachment.filename,
				reference: mailAttachment.reference,
			};
		});

		this.sendGridMail(mail);

		// remove from array
		this.data.emails.splice(this.selectedIndex, 1);

		if (this.data.emails.length === 0) {
			this.cancel();
		} else {
			// reset form
			this.selectedIndex = 0;
			this.mailMessage = this.data.emails[0];
			this.updateForm();
		}
	}

	sendGridMail(email) {
		this.emailService.addEmailOut(email).subscribe();
	}

	convertText2Html(text: string): string {
		if (text === '') return '';
		while (text.includes('\n')) {
			text = text.replace('\n', '<br>');
		}
		if (text.substring(0, 3) === '<p>') return text;
		return '<p>' + text + '</p>';
	}

	onChangeBody({ editor }: ChangeEvent) {
		this.mailDetailForm.controls['html'].patchValue(this.editorData);
	}

	dropped(attachments: NgxFileDropEntry[]) {
		attachments.forEach((attachment) =>
			this.mailAttachments.push({
				filename: attachment.relativePath,
				reference: '',
				progress: 0,
				size: 0,
				valid: true,
			})
		);
		for (const droppedFile of attachments) {
			// Is it a file and is it a valid file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					const index = this.mailAttachments.findIndex((attachment) => attachment.filename === file.name);
					if (index !== -1) {
						this.mailAttachments[index].size = Math.round(file.size / 1024);
						if (this.validFile(file)) {
							const uploadFile = this.fileService.uploadFile().put(file);
							uploadFile.percentageChanges().subscribe((uploadProgressValue) => {
								this.mailAttachments[index].progress = Math.round(uploadProgressValue);
							});
							uploadFile.then(
								(res) => {
									this.mailAttachments[index].reference = res.metadata?.fullPath;
									console.log(res);
									this.removeInvalidAttachments();
								},
								(error) => this.snackBar.openSnackBarError(error.message)
							);
						} else {
							// not a valid file
							this.mailAttachments[index].valid = false;
							this.removeInvalidAttachments();
						}
					}
				});
			}
		}
	}

	removeInvalidAttachments() {
		// remove attachements with no filename or no reference
		this.mailAttachments = this.mailAttachments.filter(
			(attachment) => attachment.filename !== '' && attachment.reference !== '' && attachment.filename && attachment.reference
		);
	}

	removeAttachment(attachment: any) {
		const index = this.mailAttachments.findIndex((mailAttachment) => mailAttachment === attachment);
		if (this.mailAttachments[index].reference != '') {
			this.fileService.deleteFile(this.mailAttachments[index].reference);
		}
		this.mailAttachments.splice(index, 1);
	}

	fileUploadFinished(): boolean {
		let finished = true;
		this.mailAttachments.forEach((attachment) => {
			finished = attachment.progress === 100;
		});
		return finished;
	}

	validFile(file: File): boolean {
		let valid = file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png';
		valid = valid && file.size < this.maxFileSize;
		return valid;
	}

	validFileSize() {
		let size = 0;
		this.mailAttachments.forEach((attachment) => (size += attachment.size));
		return size * 1024 < this.maxAttachmentSize;
	}
}
