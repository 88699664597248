<mat-progress-bar *ngIf="isLoadingFlights" mode="indeterminate" style="position: absolute; z-index: 103; width: 100%"></mat-progress-bar>
<div class="table-container" [ngStyle]="tableHeight ? { 'height.px': tableHeight } : {}" (scroll)="scrollHandler($event)">
	<mat-table #table [dataSource]="datasourceFlights">
		<!-- Status (Summary) -->
		<ng-container matColumnDef="status">
			<mat-header-cell *matHeaderCellDef matSortDisabled [ngStyle]="isMobile ? { 'padding-left': '12px' } : {}" style="max-width: 52px">
			</mat-header-cell>
			<mat-cell *matCellDef="let flight" [ngStyle]="isMobile ? { 'padding-left': '12px' } : {}" style="max-width: 52px">
				<mat-icon [color]="flight.icon?.color">{{ flight.icon?.sign }}</mat-icon>
				<mat-icon *ngIf="flight.period === 'MOR'" [color]="'accent'">fiber_manual_record</mat-icon>
			</mat-cell>
		</ng-container>
		<!-- Name (Summary) -->
		<ng-container matColumnDef="name">
			<mat-header-cell
				[ngStyle]="
					isXSmall
						? { 'margin-right': '5px', 'min-width': '70px' }
						: isSmall
						? { 'min-width': '60px', 'margin-left': '20px', 'margin-right': '20px', 'max-width': '110px' }
						: { 'min-width': '125px' }
				"
				*matHeaderCellDef
				matSortDisabled
				>{{ 'FLIGHTS.balloon' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				style="word-break: break-word"
				[ngStyle]="
					isXSmall
						? { 'margin-right': '5px', 'min-width': '75px' }
						: isSmall
						? { 'min-width': '60px', 'margin-left': '20px', 'margin-right': '20px', 'max-width': '110px' }
						: { 'min-width': '125px' }
				"
				*matCellDef="let flight"
			>
				<span [ngStyle]="flight.findFilterBalloonName ? { 'background-color': 'yellow' } : {}">
					{{ flight?.balloon?.name }}
				</span>
				<mat-icon *ngIf="flight.duplicateBalloon" matTooltip="{{ 'VALIDATIONS.duplicate_flight' | translate | capitalize }}" color="warn"
					>warning</mat-icon
				>
			</mat-cell>
		</ng-container>
		<!-- Badge & name (Non summary) -->
		<ng-container matColumnDef="nameBadge">
			<mat-header-cell
				[ngStyle]="
					isXSmall
						? { 'margin-right': '5px', 'min-width': '70px' }
						: isSmall
						? { 'min-width': '60px', 'margin-left': '20px', 'margin-right': '20px', 'max-width': '110px' }
						: { 'min-width': '125px' }
				"
				*matHeaderCellDef
				matSortDisabled
				>{{ 'FLIGHTS.name' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				style="word-break: break-word"
				[ngStyle]="
					isXSmall
						? { 'margin-right': '5px', 'min-width': '75px' }
						: isSmall
						? { 'min-width': '60px', 'margin-left': '20px', 'margin-right': '20px', 'max-width': '110px' }
						: { 'min-width': '125px' }
				"
				*matCellDef="let flight"
			>
				<span *ngIf="!showBlurry(flight)" [ngStyle]="flight.findFilterBalloonName ? { 'background-color': 'yellow' } : {}">
					<app-balloon-badge [balloon]="flight?.balloon"></app-balloon-badge>
				</span>
				<span *ngIf="showBlurry(flight)" [ngStyle]="flight.findFilterBalloonName ? { 'background-color': 'yellow' } : {}">
					{{ flight?.balloon?.name }}
				</span>
				<mat-icon *ngIf="flight.duplicateBalloon" matTooltip="{{ 'VALIDATIONS.duplicate_flight' | translate | capitalize }}" color="warn"
					>warning</mat-icon
				>
			</mat-cell>
		</ng-container>
		<!-- Date (Summary) -->
		<ng-container matColumnDef="date">
			<mat-header-cell
				[ngStyle]="
					isXSmall ? { 'max-width': '50px', 'margin-right': '15px' } : isSmall ? { 'max-width': '105px' } : { 'min-width': '105px' }
				"
				*matHeaderCellDef
				matSortDisabled
				>{{ 'FLIGHTS.date' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				[ngStyle]="
					isXSmall ? { 'max-width': '50px', 'margin-right': '15px' } : isSmall ? { 'max-width': '105px' } : { 'min-width': '105px' }
				"
				*matCellDef="let flight"
				[satPopoverAnchor]="d"
			>
				<sat-popover #d hasBackdrop xAlign="start" yAlign="start" (closed)="updateComments(flight, $event)">
					<div class="sat">
						<app-flight-comments [flight]="flight"></app-flight-comments>
					</div>
				</sat-popover>
				<div *ngIf="!isXSmall">
					{{ flight.date | formatDate: 'ddd DD MMM YY' }}
				</div>
				<div *ngIf="isXSmall" (click)="$event.stopPropagation(); d.toggle()">
					{{ flight.date | formatDate: 'ddd D/M' }}
					<div *ngIf="flight.tagsData && flight.tagsData.length > 0" style="padding: 3px 0">
						<div fxLayout="row">
							<div *ngFor="let tag of flight.tagsData">
								<mat-icon [matTooltip]="tag.tag" [ngStyle]="tag.backgroundColor ? { color: tag.backgroundColor } : {}">bookmark</mat-icon>
							</div>
						</div>
					</div>
				</div>
			</mat-cell>
		</ng-container>
		<!-- Events (Non summary) -->
		<ng-container matColumnDef="event">
			<mat-header-cell style="min-width: 120px" *matHeaderCellDef>
				{{ 'FLIGHTS.event' | translate | capitalize }}
				<button mat-icon-button>
					<mat-icon (click)="addEvent()">add</mat-icon>
				</button>
			</mat-header-cell>
			<mat-cell
				style="min-width: 120px"
				*matCellDef="let flight"
				(click)="displayDetails(flight, false); $event.stopPropagation()"
				[ngStyle]="{ cursor: showBlurry(flight) ? 'pointer' : 'default' }"
			>
				<mat-chip-list *ngIf="!showBlurry(flight)">
					<mat-chip
						*ngFor="let event of flight.eventsData"
						(removed)="removeEvent(event?.id, flight?.date)"
						removable="true"
						selectable="true"
						style="font-size: small; height: auto; word-break: break-all"
					>
						{{ event.description }}
						<mat-icon matChipRemove>cancel</mat-icon>
					</mat-chip>
				</mat-chip-list>
				<div class="blurry" *ngIf="showBlurry(flight)">&nbsp;</div>
			</mat-cell>
		</ng-container>
		<!-- Hour (Summary) -->
		<ng-container matColumnDef="hour">
			<mat-header-cell
				[ngStyle]="isSmall ? { 'max-width': '45px' } : !isMobile ? { 'max-width': '45px', margin: '0px 10px' } : {}"
				*matHeaderCellDef
				matSortDisabled
				>{{ 'FLIGHTS.hour' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				[ngStyle]="isSmall ? { 'max-width': '45px' } : !isMobile ? { 'max-width': '45px', margin: '0px 10px' } : {}"
				*matCellDef="let flight"
			>
				{{ flight.hour | formatDate: 'HH:mm' }}
			</mat-cell>
		</ng-container>
		<!-- Location (Summary) -->
		<ng-container matColumnDef="location">
			<mat-header-cell
				[ngStyle]="
					isXSmall ? { 'padding-right': '12px' } : isSmall ? { 'min-width': '85px', 'margin-right': '20px' } : { 'min-width': '105px' }
				"
				*matHeaderCellDef
				matSortDisabled
			>
				{{ 'FLIGHTS.location' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				[ngStyle]="
					isXSmall ? { 'padding-right': '12px' } : isSmall ? { 'min-width': '85px', 'margin-right': '20px' } : { 'min-width': '105px' }
				"
				*matCellDef="let flight"
			>
				<span [ngStyle]="flight.findFilterLocationName ? { 'background-color': 'yellow' } : {}">
					{{ flight.location?.name }}
				</span>
			</mat-cell>
		</ng-container>
		<!-- Pilot (summary) -->
		<ng-container matColumnDef="pilot">
			<mat-header-cell [ngStyle]="!isMobile ? { 'min-width': '110px' } : {}" *matHeaderCellDef matSortDisabled
				>{{ 'FLIGHTS.pilot' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell *matCellDef="let flight" (click)="showDialogPilotCrew(flight); $event.stopPropagation()" class="hover-cell">
				<span *ngIf="!showBlurry(flight)" [ngStyle]="flight.findFilterPilotName ? { 'background-color': 'yellow' } : {}">
					<app-crew-badge [crew]="flight?.pilot?.crew" [groundCrews]="flight?.groundCrews"></app-crew-badge>
				</span>
				<span *ngIf="showBlurry(flight) && flight?.pilot" [ngStyle]="flight.findFilterPilotName ? { 'background-color': 'yellow' } : {}">
					{{ flight.pilot.crew?.name }}
				</span>
				<span *ngIf="showBlurry(flight) && flight && !flight.pilot" style="color: darkgray; font-style: italic">
					{{ 'COMMUNICATIONS.none' | translate | capitalize }}
				</span>
				<mat-icon *ngIf="flight.duplicatePilot" matTooltip="{{ 'VALIDATIONS.duplicate_pilot' | translate | capitalize }}" color="warn"
					>warning</mat-icon
				>
				<mat-icon class="hover-icon"><span class="material-symbols-outlined"> left_click </span></mat-icon>
			</mat-cell>
		</ng-container>
		<!-- Crew (Non summary) -->
		<ng-container matColumnDef="crew">
			<mat-header-cell *matHeaderCellDef matSortDisabled>{{ 'FLIGHTS.ground_crew' | translate | capitalize }} </mat-header-cell>
			<mat-cell
				*matCellDef="let flight"
				(click)="displayDetails(flight, false); $event.stopPropagation()"
				[ngStyle]="{ cursor: showBlurry(flight) ? 'pointer' : 'default' }"
			>
				<ng-container *ngIf="!showBlurry(flight)">
					<div *ngFor="let crew of flight.groundCrews">
						{{ crew.data?.name }}
					</div>
				</ng-container>
				<div class="blurry" *ngIf="showBlurry(flight)">&nbsp;</div>
			</mat-cell>
		</ng-container>
		<!-- Bookings (Non summary) -->
		<ng-container matColumnDef="bookings">
			<mat-header-cell
				[ngStyle]="
					isSmall ? { 'max-width': '70px', 'margin-left': '10px' } : !isMobile ? { 'min-width': '110px', 'margin-right': '15px' } : {}
				"
				*matHeaderCellDef
				matSortDisabled
				>{{ 'BOOKINGS.bookings' | translate | capitalize }}</mat-header-cell
			>
			<mat-cell
				*matCellDef="let flight"
				[satPopoverAnchor]="p"
				(click)="p.toggle(); $event.stopPropagation()"
				(click)="displayDetails(flight, true); $event.stopPropagation()"
				[ngStyle]="{
					cursor: showBlurry(flight) ? 'pointer' : 'default',
					'min-width': !isMobile ? '110px' : {},
					'max-width': isSmall ? '70px' : {},
					'margin-left': isSmall ? '10px' : {},
					'margin-right': !isMobile ? '15px' : {}
				}"
			>
				<sat-popover #p hasBackdrop xAlign="start" yAlign="start">
					<app-flight-bookings
						*ngIf="flight?.bookingsData?.count > 0"
						[bookings]="flight.bookingsData?.list"
						[filter]="filterValue"
					></app-flight-bookings>
				</sat-popover>
				<ng-container *ngIf="!showBlurry(flight)">
					<mat-icon *ngIf="flight?.bookingsData?.count > 0" color="primary">open_in_browser</mat-icon>
					<div *ngIf="!isMobile" fxLayout="column">
						<div *ngFor="let booking of flight?.bookingsData?.list" [ngStyle]="findFilter(booking) ? { 'background-color': 'yellow' } : {}">
							<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="5px" *ngIf="booking.status != 'CONF'">
								<span>({{ booking.passengers?.length }}) {{ booking.contactName }}</span>
								<mat-icon [color]="booking.iconColor">{{ booking.icon }}</mat-icon>
							</div>
							<span *ngIf="booking.status === 'CONF'">({{ booking.passengers?.length }}) {{ booking.contactName }} </span>
						</div>
					</div>
					<div *ngIf="flight?.bookingsData?.count < 1" style="color: darkgray; font-style: italic">
						{{ 'COMMUNICATIONS.none' | translate | capitalize }}
					</div>
				</ng-container>
				<div class="blurry" *ngIf="showBlurry(flight)">&nbsp;</div>
			</mat-cell>
		</ng-container>
		<!-- Flight Comments (Non summary) -->
		<ng-container matColumnDef="comments">
			<mat-header-cell [ngStyle]="!isMobile ? { 'min-width': '105px', 'margin-right': '15px' } : {}" *matHeaderCellDef matSortDisabled
				>{{ 'BOOKINGS.comments' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				*matCellDef="let flight"
				[satPopoverAnchor]="t"
				(click)="t.toggle(); $event.stopPropagation()"
				[ngStyle]="{
					cursor: showBlurry(flight) ? 'pointer' : 'default',
					'min-width': !isMobile ? '105px' : {},
					'margin-right': !isMobile ? '15px' : {}
				}"
				class="hover-cell"
			>
				<div *ngIf="!showBlurry(flight)" (click)="t.toggle(); $event.stopPropagation()">
					<div
						fxLayout="column"
						fxLayoutAlign="space-between start"
						*ngIf="(flight.tagsData && flight.tagsData.length > 0) || flight.comment"
					>
						<div *ngIf="flight.tagsData && flight.tagsData.length > 0" style="padding: 3px 0">
							<div fxLayout="row">
								<div *ngFor="let tag of flight.tagsData" (click)="t.toggle(); $event.stopPropagation()">
									<mat-icon [matTooltip]="tag.tag" [ngStyle]="tag.backgroundColor ? { color: tag.backgroundColor } : {}">bookmark</mat-icon>
								</div>
							</div>
						</div>
						<div>
							{{ flight.comment }}
						</div>
					</div>
				</div>
				<mat-icon class="hover-icon"><span class="material-symbols-outlined"> left_click </span></mat-icon>

				<sat-popover #t hasBackdrop xAlign="start" yAlign="start" (closed)="updateComments(flight, $event)">
					<div class="sat">
						<app-flight-comments [flight]="flight"></app-flight-comments>
					</div>
				</sat-popover>
				<div class="blurry" *ngIf="showBlurry(flight)">&nbsp;</div>
			</mat-cell>
		</ng-container>
		<!-- Passengers (Summary) -->
		<ng-container matColumnDef="passengers">
			<mat-header-cell style="max-width: 60px" *matHeaderCellDef matSortDisabled>{{
				'FLIGHTS.passengers' | translate | capitalize
			}}</mat-header-cell>
			<mat-cell
				*matCellDef="let flight"
				[ngStyle]="{ cursor: showBlurry(flight) ? 'pointer' : 'default', 'max-width': '60px' }"
				[satPopoverAnchor]="pass"
				(click)="handlePassengersClick($event, pass)"
			>
				<sat-popover #pass hasBackdrop xAlign="start" yAlign="start">
					<app-flight-bookings
						*ngIf="flight?.bookingsData?.count > 0"
						[bookings]="flight.bookingsData?.list"
						[filter]="filterValue"
					></app-flight-bookings>
				</sat-popover>
				<div (click)="handlePassengersClick($event, pass)">
					<div fxLayout="column">
						<span style="margin-right: 5px">{{ flight?.passengersData?.passengersCount }}/{{ flight.balloon?.capacity }} </span>
						<mat-progress-bar
							style="width: 50px"
							*ngIf="flight?.passengersData?.occupancy > 75 && flight?.passengersData?.occupancy <= 100"
							type="determinate"
							color="accent"
							[value]="flight?.passengersData?.occupancy"
						></mat-progress-bar>
						<mat-progress-bar
							style="width: 50px"
							*ngIf="flight?.passengersData?.occupancy <= 75"
							type="determinate"
							color="primary"
							[value]="flight?.passengersData?.occupancy"
						></mat-progress-bar>
						<mat-progress-bar
							style="width: 50px"
							*ngIf="flight?.passengersData?.occupancy > 100"
							type="determinate"
							color="warn"
							[value]="flight?.passengersData?.occupancy"
						>
						</mat-progress-bar>
					</div>
				</div>
			</mat-cell>
		</ng-container>
		<!-- Total weight (Non summary) -->
		<ng-container matColumnDef="passengersWeight">
			<mat-header-cell [ngStyle]="!isMobile ? { 'max-width': '85px' } : {}" *matHeaderCellDef matSortDisabled
				>{{ 'BOOKINGS.weight' | translate | capitalize }}
			</mat-header-cell>
			<mat-cell
				*matCellDef="let flight"
				(click)="displayDetails(flight, false); $event.stopPropagation()"
				[ngStyle]="{
					cursor: showBlurry(flight) ? 'pointer' : 'default',
					'max-width': !isMobile ? '85px' : {}
				}"
			>
				<span *ngIf="!showBlurry(flight)"> {{ flight.passengersData?.passengersWeight }} kg </span>
				<div class="blurry" *ngIf="showBlurry(flight)">&nbsp;</div>
			</mat-cell>
		</ng-container>
		<!-- Action (summary) -->
		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef [ngStyle]="isSmall ? { 'padding-right': '10px', 'margin-left': '10px' } : {}">{{
				'APPLICATION.action' | translate | capitalize
			}}</mat-header-cell>
			<mat-cell
				*matCellDef="let flight"
				(click)="$event.stopPropagation()"
				[ngStyle]="isSmall ? { 'padding-right': '10px', 'margin-left': '10px' } : {}"
			>
				<ng-container *ngIf="flight.visibility && type === 'upcoming'">
					<button
						mat-icon-button
						(click)="toggleVisibility(flight)"
						matTooltip="{{ 'FLIGHTS.toggle_visibility' | translate | capitalize }}"
					>
						<mat-icon *ngIf="flight.visibility === 'PUB'" color="primary">visibility</mat-icon>
						<mat-icon *ngIf="flight.visibility === 'PRI'" color="warn">visibility_off</mat-icon>
					</button>
				</ng-container>
				<ng-container *ngIf="type === 'historical'">
					<button
						*ngIf="flight.status && flight.status === 'CAN'"
						mat-icon-button
						color="warn"
						matTooltip="{{ 'FLIGHTS.delete_flight' | translate | capitalize }}"
						(click)="deleteFlight(flight); $event.stopPropagation()"
					>
						<mat-icon>delete</mat-icon>
					</button>
				</ng-container>
				<ng-container *ngIf="type === 'upcoming' && flight?.bookingsData?.count === 0">
					<button
						*ngIf="flight.status && (flight.status === 'CAN' || flight.status === 'SCH')"
						mat-icon-button
						color="warn"
						matTooltip="{{ 'FLIGHTS.delete_flight' | translate | capitalize }}"
						(click)="deleteFlight(flight); $event.stopPropagation()"
					>
						<mat-icon>delete</mat-icon>
					</button>
				</ng-container>
				<ng-container *ngIf="type === 'booking'">
					<button *ngIf="!isXSmall" mat-raised-button color="accent" (click)="addToFlight(flight); $event.stopPropagation()">
						{{ 'FLIGHTS.add' | translate | capitalize }}
					</button>
					<button *ngIf="isXSmall" mat-mini-fab color="accent" (click)="addToFlight(flight); $event.stopPropagation()">
						<mat-icon>add</mat-icon>
					</button>
				</ng-container>
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: displayedColumns"
			[ngClass]="{ highlighted: row.weekend }"
			(click)="flightClicked(row)"
		></mat-row>
	</mat-table>
</div>
