<form [formGroup]="formBookingComments" autocomplete="off">
	<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
		<mat-form-field style="width: 380px">
			<textarea
				matTextareaAutosize
				matInput
				matAutosizeMinRows="1"
				placeholder="{{ 'BOOKINGS.comments' | translate | capitalize }}"
				formControlName="comments"
			></textarea>
		</mat-form-field>
	</div>
	<app-tag-autocomplete
		[tagType]="'booking'"
		[alreadyAssignedTags]="booking.tags"
		(selectedTagsEvent)="setTags($event)"
	></app-tag-autocomplete>
	<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
		<button mat-raised-button color="primary" (click)="save(formBookingComments.value)">
			{{ 'APPLICATION.save' | translate | capitalize }}
		</button>
		<button mat-raised-button (click)="cancel(formBookingComments.value)">{{ 'APPLICATION.cancel' | translate | capitalize }}</button>
	</div>
</form>
