import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { FlightPeriod } from 'src/app/core/graphql/generated/gen-types';
import { SunriseSunsetService } from 'src/app/core/services/sunriseSunset/sunrise-sunset.service';
import { SnackBar } from 'src/app/core/utility/snackBar';

@Component({
	selector: 'app-dialog-offset',
	templateUrl: './dialog-offset.component.html',
	styleUrls: ['./dialog-offset.component.scss'],
})
export class DialogOffsetComponent implements OnInit {
	offsetForm: FormGroup;
	hourSunrise = '';
	hourSunset = '';

	constructor(
		public dialogRef: MatDialogRef<DialogOffsetComponent>,
		private snackBar: SnackBar,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.offsetForm = this.fb.group({
			sunrise: this.data.viewState.sunrise_sunset.offsetSunrise,
			sunset: this.data.viewState.sunrise_sunset.offsetSunset,
		});

		this.getHourSunrise();
		this.getHourSunset();

		this.offsetForm.get('sunrise').valueChanges.subscribe(
			() => this.getHourSunrise(),
			(err) => this.snackBar.openSnackBarError(err.message)
		);
		this.offsetForm.get('sunset').valueChanges.subscribe(
			() => this.getHourSunset(),
			(err) => this.snackBar.openSnackBarError(err.message)
		);
	}

	save() {
		this.data.viewState.sunrise_sunset.offsetSunrise = this.offsetForm.get('sunrise').value;
		this.data.viewState.sunrise_sunset.offsetSunset = this.offsetForm.get('sunset').value;
		this.dialogRef.close({ status: 'save', viewState: this.data.viewState });
	}

	cancel() {
		this.dialogRef.close({ status: 'cancel' });
	}

	getHourSunrise() {
		// this.sunriseSunsetService.getHour(
		//   '2021-01-01 05:42',
		//   this.offsetForm.get('sunrise').value,
		//   FlightPeriod.Mor
		// ).valueChanges.subscribe((result) => this.hourSunrise = result.data.hour);
	}

	getHourSunset() {
		// this.sunriseSunsetService.getHour(
		//   '2021-01-01 21:08',
		//   this.offsetForm.get('sunset').value,
		//   FlightPeriod.Eve
		// ).valueChanges.subscribe((result) => this.hourSunset = result.data.hour);
	}

	getAbs(number) {
		return Math.abs(number);
	}
}
