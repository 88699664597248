<mat-card>
	<mat-card-header>
		<mat-card-title>{{ 'FLIGHTS.traject.custom_flight_profile' | translate | capitalize }}</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<mat-list role="list">
			<mat-list-item style="height: 120px">
				<div style="display: flex; flex-direction: row; gap: 2px; align-items: flex-end; justify-content: start">
					<div
						*ngFor="let profile of flightProfile; let i = index"
						[ngStyle]="{
							width: (profile.duration / 60) * 200 + 'px',
							height: getHeight(profile.level) + 'px',
							'background-color': getColor(profile.level)
						}"
					></div>
				</div>
			</mat-list-item>
			<mat-list-item *ngFor="let profile of flightProfile; let i = index">
				<button mat-icon-button color="warn" (click)="onDelete(i)">
					<mat-icon>delete</mat-icon>
				</button>
				{{ profile.duration }}min @ {{ profile.level }}hPa ({{ getAlt(profile.level) }})
			</mat-list-item>
			<mat-list-item style="margin-top: 40px">
				<form
					[formGroup]="profileDetailForm"
					(ngSubmit)="onSubmit()"
					style="display: flex; flex-direction: row; gap: 20px; margin-top: 20px"
				>
					<mat-form-field style="width: 80px">
						<mat-label>{{ 'FLIGHTS.traject.duration' | translate | capitalize }}</mat-label>
						<input matInput formControlName="duration" />
						<span matSuffix>min</span>

						<mat-error *ngIf="profileDetailForm.controls['duration'].hasError('required')">
							{{ 'FLIGHTS.traject.duration_is_required' | translate | capitalize }}
						</mat-error>
					</mat-form-field>
					<mat-form-field>
						<mat-label>{{ 'FLIGHTS.traject.level' | translate | capitalize }}</mat-label>
						<mat-select formControlName="level">
							<mat-option *ngFor="let level of levels" [value]="level.hPa"> {{ level.hPa }} ({{ level.alt }}) </mat-option>
						</mat-select>
						<mat-error *ngIf="profileDetailForm.controls['level'].hasError('required')">{{ 'FLIGHTS.traject.level_is_required' | translate | capitalize }}</mat-error>
					</mat-form-field>
					<button mat-icon-button color="primary" type="submit"><mat-icon>add</mat-icon></button>
				</form>
			</mat-list-item>
		</mat-list>
	</mat-card-content>
	<mat-card-actions>
		<button mat-raised-button color="primary" (click)="updateTrajectoryCustom()">{{ 'FLIGHTS.traject.save' | translate | capitalize }}</button>
	</mat-card-actions>
</mat-card>
