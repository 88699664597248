import { trigger, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { Flight, UserRole } from 'src/app/core/graphql/generated/gen-types';
import { ResponsiveService } from 'src/app/core/services/admin/responsive.service';
import { FlightService } from 'src/app/core/services/flight/flight.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-date-card',
	templateUrl: './date-card.component.html',
	styleUrls: ['./date-card.component.scss'],
	animations: [
		trigger('fadeSlideInOut', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(-20px)' }),
				animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
			]),
			transition(':leave', [animate('250ms', style({ opacity: 0, transform: 'translateY(-20px)' }))]),
		]),
	],
})
export class DateCardComponent implements OnInit {
	@Input() operator: boolean;
	@Input() shareBookings: boolean;
	@Input() userRole: UserRole;
	@Input() flights: Flight[];
	@Input() date: string;

	// component guards
	guards = environment.componentGuards.dateCard;

	event: string;
	morningFlights: any[];
	eveningFlights: any[];
	today: boolean;
	showFlights = false;
	flightCount = 0;
	state: string = 'default';
	isXSmall = false;

	flightSubs: Subscription[] = [];

	constructor(private router: Router, private responsiveService: ResponsiveService, private flightService: FlightService) {}

	ngOnInit(): void {
		this.responsiveService.XSmallChanged.subscribe((isXSmall) => (this.isXSmall = isXSmall));
		this.today = moment(this.date).isSame(moment(), 'day');
		this.flightCount = this.flights.length;
		this.morningFlights = this.flights.filter((f) => f.period === 'MOR');
		this.eveningFlights = this.flights.filter((f) => f.period === 'EVE');
	}

	ngAfterViewInit(): void {	
		this.flights.forEach((flight) => {
			this.flightService.getDashboard(flight.id).subscribe();
		});
	}

	ngOnDestroy(): void {
		this.flightSubs.forEach((sub) => sub.unsubscribe());
	};

	ngOnChanges(): void {
		this.flightCount = this.flights.length;
		this.morningFlights = this.flights.filter((f) => f.period === 'MOR');
		this.eveningFlights = this.flights.filter((f) => f.period === 'EVE');
	}

	toggleFlights() {
		if (this.flightCount > 0) {
			this.showFlights = !this.showFlights;
			if (this.state === 'default') {
				this.state = 'disabled';
			} else {
				this.state = 'default';
			}
		}
	}

	plan() {
		this.router.navigate([`/calendar/${this.date}/EVE`]);
	}

	getCardClass() {
		if (moment(this.date).weekday() >= 5) {
			return !this.isXSmall ? 'weekendday large' : 'weekendday handset';
		} else {
			return !this.isXSmall ? 'weekday large' : 'weekday handset';
		}
	}
}
