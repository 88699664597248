<h1 mat-dialog-title>{{ 'FLIGHTS.edit_sms' | translate | capitalize}}
    <mat-chip-list *ngIf="getNumberOfLanguages() > 1">
      <mat-chip *ngIf="languages.nl > 0" [selected]="langSelected === 'nl'" (click)="updateLang('nl')">nl ({{ languages.nl }})</mat-chip>
      <mat-chip *ngIf="languages.fr > 0" [selected]="langSelected === 'fr'" (click)="updateLang('fr')">fr ({{ languages.fr }})</mat-chip>
      <mat-chip *ngIf="languages.en > 0" [selected]="langSelected === 'en'" (click)="updateLang('en')">en ({{ languages.en }})</mat-chip>
      <mat-chip *ngIf="languages.de > 0" [selected]="langSelected === 'de'" (click)="updateLang('de')">de ({{ languages.de }})</mat-chip>
    </mat-chip-list>
  </h1>
  
  <div mat-dialog-content>
    <!-- SMS Form -->
    <form [formGroup]="smsDetailForm">
      <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="10px">
        <div fxLayout="row">
          <mat-form-field *ngIf="langSelected === 'nl'" style="min-width: 400px">
            <textarea matTextareaAutosize minRows="4" matInput placeholder="SMS" formControlName="smsNL"></textarea>
            <mat-hint style="font-size: 11;">{{ characterCount(smsDetailForm.get('smsNL').value, 'nl')}} chars left - {{ smsCount(smsDetailForm.get('smsNL').value, 'nl')}} sms</mat-hint>
          </mat-form-field>
          <mat-form-field *ngIf="langSelected === 'fr'" style="min-width: 400px">
            <textarea matTextareaAutosize minRows="4" matInput placeholder="SMS" formControlName="smsFR"></textarea>
            <mat-hint style="font-size: 11;">{{ characterCount(smsDetailForm.get('smsFR').value, 'fr')}} chars left - {{ smsCount(smsDetailForm.get('smsFR').value, 'fr')}} sms</mat-hint>
          </mat-form-field>
          <mat-form-field *ngIf="langSelected === 'en'" style="min-width: 400px">
            <textarea matTextareaAutosize minRows="4" matInput placeholder="SMS" formControlName="smsEN"></textarea>
            <mat-hint style="font-size: 11;">{{ characterCount(smsDetailForm.get('smsEN').value, 'en')}} chars left - {{ smsCount(smsDetailForm.get('smsEN').value, 'en')}} sms</mat-hint>
          </mat-form-field>
          <mat-form-field *ngIf="langSelected === 'de'" style="min-width: 400px">
            <textarea matTextareaAutosize minRows="4" matInput placeholder="SMS" formControlName="smsDE"></textarea>
            <mat-hint style="font-size: 11;">{{ characterCount(smsDetailForm.get('smsDE').value, 'de')}} chars left - {{ smsCount(smsDetailForm.get('smsDE').value, 'de')}} sms</mat-hint>
          </mat-form-field>
        </div>
        <mat-chip-list>
          <mat-chip *ngFor="let chip of chipListSelected" selected="true" (click)="chipClick(chip, langSelected)">{{ formatChip(chip) }}</mat-chip>
        </mat-chip-list>
        <!-- only show toggle when up is enabled and it is not a locationContactSms -->
        <mat-slide-toggle *ngIf="upEnabled && data.smsTemplate != 'locationContactSms'" formControlName="up">{{ 'FLIGHTS.send_up_url_with_sms' | translate | capitalize}}</mat-slide-toggle>
      </div>
    </form>
  
    <!-- Example -->
    <h1 mat-dialog-title style="margin: 20px 0px">{{ 'FLIGHTS.preview_sms' | translate | capitalize}}</h1>
    <div class="mat-elevation-z8 sms-message">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column"><strong>Contact</strong></div>
        <div fxLayout="column" style="font-size: 11px">{{ formatToday() }}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row">
          <span style="font-style: italic"><strong>Jij:&nbsp;</strong></span>
          {{ parseTemplate(getSMSSelected(langSelected), data.flight, langSelected)}} {{ smsDetailForm.get('up').value ? exampleUp : '' }} {{ smsFooter ? smsFooter[langSelected] : '' }}
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon color="warn">sms_failed</mat-icon>
        </div>
      </div>
    </div>
  
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="confirm(smsDetailForm.value)" color="warn">{{ 'FLIGHTS.' + sendTo | translate | capitalize}}</button>
    <button *ngIf="smsReminder || smsCancel" mat-raised-button (click)="confirmToAll(smsDetailForm.value)"
      color="warn">{{ 'FLIGHTS.send_to_pax_and_crew' | translate | capitalize}}</button>
    <button mat-raised-button (click)="cancel()">{{ 'APPLICATION.cancel' | translate | capitalize}}</button>
  </div>