import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import * as uuid from 'uuid';
import { TenantSettingService } from '../tenantSetting/tenant-setting.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  basePath: string;
  constructor(
    private tenantSettingService: TenantSettingService,
    private storage: AngularFireStorage
  ) {
    this.tenantSettingService.getTenantSetting().valueChanges.subscribe((tenantSettingResult) => {
      this.basePath = 'attachments/' + tenantSettingResult.data.tenantSetting.tenantId;
    });
  }

  uploadFile() {
    const filename = uuid.v4() + '.pdf';
    return this.storage.ref(this.basePath).child(filename);
  }

  deleteFile(reference: string) {
    const file = this.storage.ref('').child(reference);
    file.delete();
  }
}
