<mat-card class="mat-elevation-z8" data-tour="card-booking">
    <mat-card-content>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start start" >
            <div fxLayout="column" fxLayoutGap="5px" style="width: 100%">
                <div fxLayout="row" fxLayoutAlign="space-between start"  (click)="edit()" >
                    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start start">
                        <div fxLayout="row" fxLayoutGap="0px" fxLayoutAlign="start start">
                            <mat-icon [color]="booking?.iconColor">{{ booking?.icon }}</mat-icon>
                        </div>
                        <div fxLayout="column" fxLayoutGap="0px" fxLayoutAlign="start start">
                            <span style="font-size: medium; font-weight: bold;">{{ booking?.contactName }}</span>
                            <span style="font-size: small;">{{ booking?.contactPhone }} {{ booking?.city ? '-' : '' }} {{ booking?.city }}</span>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <span style="font-size: medium; font-weight: bold;">{{ booking?.passengerCounters.passengerCount }} ({{ booking?.passengerCounters.totalWeight }} kg)</span>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" style="font-size: small">
                    {{ booking?.comments }}
                </div>
            </div>
    
            <div fxLayout="column" fxLayoutAlign="space-between end" style="width: 40px;">
                <mat-icon *ngIf="draggable" color="primary">zoom_out_map</mat-icon>
<!-- 
                <button  mat-menu-item 
                [matMenuTriggerFor]="dropdownMenu"
                [matMenuTriggerData]="{booking: booking}">
                    <mat-icon>more_vertical</mat-icon>
                </button> -->
            </div>
        </div>
    </mat-card-content>
</mat-card>
 
<mat-menu #dropdownMenu xPosition="before" >
    <ng-template matMenuContent let-booking="booking">
        <!-- <button mat-menu-item
            (click)="checkin()" 
            [disabled]="booking?.status !== 'REA'">
            <mat-icon [color]="flight.status !== 'REA' ? '' : flight.passengersCheckedIn ? 'accent' : 'warn'" >desktop_windows</mat-icon>
            <span>{{ 'FLIGHTS.checkin' | translate | capitalize}}</span>
        </button>
        <button mat-menu-item 
            (click)="edit()" 
            [disabled]="!operator">
            <mat-icon color="primary">edit</mat-icon>
            <span>{{ 'FLIGHTS.update' | translate | capitalize}}</span>
        </button> -->
    </ng-template>
</mat-menu>